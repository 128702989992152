import React, { Component, useEffect, useState } from 'react';
import { CCG, practiceProfile } from '../../interfaces';
import { APIResp, fetchAPI } from '../../utils';
import { NavLink, Prompt } from 'react-router-dom';
import { EditCookiePreferencesBtn, SavePrivacyOptionsBtn } from '../Buttons';
import { PrivacyProps } from './PrivacyOptions';
import { AppState } from '../../App';
import { cloneDeep, flatten, values } from 'lodash';
import Loading from '../Loading';

interface indivPractice {
    profile: practiceProfile;
    reveal: number;
}

interface State {
    loading: boolean;
    changesMade: boolean;
    reveal: { [boroughID: number]: number };
    individuals: { [boroughID: number]: indivPractice[] };
}

class DoctorPrivacyOptions extends Component<PrivacyProps, State> {
    constructor(props: PrivacyProps) {
        super(props);
        this.state = {
            loading: true,
            changesMade: false,
            reveal: {},
            individuals: {},
        };

        this.fetchData();
    }

    componentDidUpdate = () => {
        if (this.state.changesMade) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = () => {
            };
        }
    };

    fetchData = () => {
        fetchAPI({ url: '/profile/privacy/' }, this.updateState, this.props.appState);
    };

    updateState = (resp: APIResp) => {
        this.setState({ loading: false });
        if (resp.data.ok) {
            let data = resp.data.data.individuals;
            for (let boroughID in data) {
                let individuals = data[boroughID];
                individuals = individuals.sort(function(a: indivPractice, b: indivPractice) {
                    let aName = a.profile.name.toLowerCase();
                    let bName = b.profile.name.toLowerCase();
                    if (aName > bName) return 1;
                    if (aName < bName) return -1;
                    return 0;
                });
                data[boroughID] = individuals;
            }

            this.setState({
                reveal: resp.data.data.reveal,
                individuals: data,
            });
        }
    };

    updateDefaultBoroughReveal = (borough_id: number, value: string) => {
        let newReveal = cloneDeep(this.state.reveal);
        newReveal[borough_id] = parseInt(value);
        this.setState({ reveal: newReveal, changesMade: true });
    };

    updateReveal = (borough: number, i: number, e: React.ChangeEvent<HTMLSelectElement>) => {
        let individuals = cloneDeep(this.state.individuals);
        individuals[borough][i].reveal = parseInt(e.target.value);
        this.setState({ individuals: individuals, changesMade: true });
    };

    updateAllReveal = (borough: number, e: React.ChangeEvent<HTMLSelectElement>) => {
        let allValue = e.target.value;
        if (allValue !== '-') {
            let individuals = cloneDeep(this.state.individuals);
            for (let i = 0; i < individuals[borough].length; i++) {
                individuals[borough][i].reveal = parseInt(allValue);
            }
            this.setState({ individuals: individuals, changesMade: true });
        }
    };

    savePrivacyOptions = (e: any) => {
        e.preventDefault();
        this.setState({ changesMade: false });

        const individuals = flatten(values(this.state.individuals)).map(x => {
            return { reveal: x.reveal, profile_id: x.profile.id };
        });

        fetchAPI(
            {
                url: '/profile/privacy',
                method: 'post',
                payload: {
                    reveal: this.state.reveal,
                    individuals: individuals,
                },
            },
            () => {
                this.props.setSuccessMessage('Your privacy preferences have been updated.');
            },
            this.props.appState,
        );
    };

    render() {
        return (
            <Loading loading={this.state.loading}><>
                <Prompt
                    when={this.state.changesMade}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <p>
                    You can choose to:
                    <ol style={{ listStyle: 'lower-alpha' }}>
                        <li>
                            Show your account{' '}
                            <i>
                                (the selected practice can see your availabilities and you can see
                                the practice’s sessions)
                            </i>
                        </li>
                        <li>
                            Hide your availabilities{' '}
                            <i>
                                (the selected practice cannot see your availabilities but you can
                                see that practice’s sessions)
                            </i>
                        </li>
                        <li>
                            Hide both your profile and availabilities (i.e. your entire profile)
                            from a practice{' '}
                            <i>
                                (the selected practice cannot see you at all AND you cannot see
                                locum sessions from that practice)
                            </i>
                        </li>
                    </ol>
                </p>
                <p>
                    Please scroll down and click on Save Privacy Preferences before you leave the
                    page.
                </p>
                {this.props.appState.ccgs.map((ccg) => (
                    <CCGPrivacyOptions
                        reveal={this.state.reveal}
                        individuals={this.state.individuals}
                        ccg={ccg}
                        appState={this.props.appState}
                        updateDefaultBoroughReveal={this.updateDefaultBoroughReveal}
                        updateReveal={this.updateReveal}
                        updateAllReveal={this.updateAllReveal}
                    />
                ))}
                <br />
                <SavePrivacyOptionsBtn onClick={this.savePrivacyOptions}>
                    &nbsp;&nbsp;Save Privacy Preferences
                </SavePrivacyOptionsBtn>
                <hr />
                <EditCookiePreferencesBtn
                    appState={this.props.appState}
                    event={null}
                    onSuccess={null}
                />
            </>
            </Loading>
        );
    }
}

const CCGPrivacyOptions = (props: {
    ccg: CCG;
    appState: AppState;
    updateDefaultBoroughReveal(borough_id: number, value: string): void;
    updateReveal(borough: number, i: number, e: React.ChangeEvent<HTMLSelectElement>): void;
    updateAllReveal(borough: number, e: React.ChangeEvent<HTMLSelectElement>): void;
    reveal: { [boroughID: number]: number };
    individuals: { [boroughID: number]: indivPractice[] };
}) => {
    return (
        <div>
            <h2>{props.ccg.name}</h2>
            {props.ccg.boroughs.map((borough_id) => (
                <BoroughPrivacyOptions
                    key={borough_id}
                    updateDefaultBoroughReveal={(e) =>
                        props.updateDefaultBoroughReveal(borough_id, e.target.value)
                    }
                    reveal={props.reveal[borough_id]}
                    individuals={props.individuals[borough_id]}
                    borough_id={borough_id}
                    appState={props.appState}
                    updateReveal={props.updateReveal}
                    updateAllReveal={props.updateAllReveal}
                />
            ))}
        </div>
    );
};

const BoroughPrivacyOptions = (props: {
    reveal: number;
    individuals: indivPractice[];
    borough_id: number;
    appState: AppState;
    updateDefaultBoroughReveal(e: React.ChangeEvent<HTMLSelectElement>): void;
    updateReveal(borough: number, i: number, e: React.ChangeEvent<HTMLSelectElement>): void;
    updateAllReveal(borough: number, e: React.ChangeEvent<HTMLSelectElement>): void;
}) => {
    const borough_name = props.appState.boroughs[props.borough_id];
    const [allValue, setAllValue] = useState('-');

    useEffect(() => {
        if (props.individuals && props.individuals.length > 0) {
            let firstValue = props.individuals[0].reveal.toString();
            for (let i = 1; i < props.individuals.length; i++) {
                if (props.individuals[i].reveal.toString() !== firstValue) {
                    firstValue = '-';
                    break;
                }
            }
            setAllValue(firstValue.toString());
        }
    }, [props.individuals]);

    return (
        <div className='borough-privacy'>
            <h3>{borough_name}</h3>
            <div className='borough-privacy-default'>
                <select value={props.reveal} onChange={props.updateDefaultBoroughReveal}>
                    <option value={0}>Show myself to</option>
                    <option value={1}>Hide my availabilities from</option>
                    <option value={2}>Hide my profile &amp; availabilities from</option>
                </select>{' '}
                all newly registered practices in {borough_name}.
            </div>
            {props.individuals && props.individuals.length > 0 && (
                <div className='borough-privacy-individuals'>
                    <div className='list-item-name practice-row' style={{ marginBottom: 24 }}>
                        <select
                            onChange={(e) => props.updateAllReveal(props.borough_id, e)}
                            value={allValue}
                        >
                            <option value='-'>-</option>
                            <option value='0'>Show myself to</option>
                            <option value='1'>Hide availabilities from</option>
                            <option value='2'>Hide my profile & availabilities from</option>
                        </select>
                        <b>all current practices in {borough_name}</b>
                    </div>
                    {props.individuals.map((profile, i) => (
                        <PracticeRow
                            key={profile.profile.id}
                            {...profile}
                            onChange={(e: any) => props.updateReveal(props.borough_id, i, e)}
                        />
                    ))}
                </div>
            )}
            {(!props.individuals || props.individuals.length === 0) && (
                <div style={{ opacity: 0.5 }}>
                    **There are currently no registered practices in {borough_name}**
                </div>
            )}
        </div>
    );
};

const PracticeRow = (props: indivPractice & { onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void }) => {
    return (
        <div className='list-item-name practice-row'>
            <select value={props.reveal} onChange={props.onChange}>
                <option value='0'>Show myself to</option>
                <option value='1'>Hide availabilities from</option>
                <option value='2'>Hide my profile & availabilities from</option>
            </select>
            <NavLink to={'/view-profile/' + props.profile.hash}>{props.profile.name}</NavLink>
        </div>
    );
};

export default DoctorPrivacyOptions;
