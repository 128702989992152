import React, { Component } from "react";
import "../styles/LoginForm.scss";
import BackBtn from "./BackBtn";
import { WarningAlert, StatusAlert, SuccessAlert } from "../Alert";
import Cookies from "universal-cookie";
import { NavLink } from "react-router-dom";
import CookieModal from "../components/Modals/CookieModal";
import { AppState } from "../App";

import axios from 'axios';
const cookies = new Cookies();

interface LoginFormProps {
    changeStatus(status: string, type: string, title: string): void;
    title: string;
    userType: string;
    doCheck: any;
    appState: AppState;
}

interface LoginFormState {
    email: string;
    password: string;
    rmbMe: boolean;
    errorMessage: string;
    statusMessage: string;
    successMessage: string;
}

class LoginForm extends Component<LoginFormProps, LoginFormState> {
    constructor(props: LoginFormProps) {
        super(props);
        this.state = {
            email: "",
            password: "",
            rmbMe: false,
            errorMessage: "",
            statusMessage: "",
            successMessage: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    updateEmail = (e: any) => {
        this.setState({ email: e.target.value });
    };

    updatePassword = (e: any) => {
        this.setState({ password: e.target.value });
    };

    updateRmbMe = () => {
        this.setState({ rmbMe: !this.state.rmbMe });
    };

    handleSubmit(e: any) {
        e.preventDefault();
        if (this.state.email.length === 0) {
            this.setState({ errorMessage: "Please key in your email." });
            return;
        }
        if (this.state.password.length === 0) {
            this.setState({ errorMessage: "Please key in your password." });
            return;
        }
        this.setState({
            errorMessage: "",
            statusMessage: "Logging you in...",
            successMessage: ""
        });
        let payload = {
            email: this.state.email,
            password: this.state.password,
            userType: this.props.userType,
            rmbMe: this.state.rmbMe
        };
        axios.post("/api/auth/login", payload).then((response: any) => {
            this.setState({ statusMessage: "" });
            if (response.data.status !== "ok") {
                // An error occurred
                this.setState({ errorMessage: response.data.status });
            } else {
                // Successfully logged in
                // Create cookie to store the hash
                let expiry = new Date();
                expiry.setDate(expiry.getDate() + 1);

                cookies.set("token", response.data.hash, {
                    path: "/",
                    expires: expiry
                });
                // cookies.set('type', this.props.userType, { path: '/', expires: expiry })
                this.setState({ successMessage: "Successfully logged in." });
                setTimeout(() => {
                    this.props.doCheck();
                }, 500);
            }
        });
    }

    render() {
        let showRmbMe =
            this.props.appState.cookiePermissions != null &&
            this.props.appState.cookiePermissions.rmbMeCookies;
        return (
            <div className="container">
                <BackBtn changeStatus={this.props.changeStatus} />

                <WarningAlert content={this.state.errorMessage} />
                <StatusAlert content={this.state.statusMessage} />
                <SuccessAlert content={this.state.successMessage} />

                <form onSubmit={this.handleSubmit}>
                    <h2>{this.props.title}</h2>

                    <div className="form-group">
                        <label>Email:</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="example@domain.com"
                            onChange={this.updateEmail}
                        />
                    </div>

                    <div className="form-group">
                        <label>Password:</label>
                        <input
                            type="password"
                            className="form-control"
                            onChange={this.updatePassword}
                        />
                    </div>

                    <div className="form-group">
                        <NavLink to={"/forgot-password/" + this.props.userType}>
                            Forgot your password?
                        </NavLink>
                    </div>

                    {showRmbMe && (
                        <div className="form-group">
                            <input
                                type="checkbox"
                                id="rmb_me"
                                checked={this.state.rmbMe}
                                onChange={this.updateRmbMe}
                            />
                            <label htmlFor="rmb_me">Remember Me</label>
                        </div>
                    )}

                    <input
                        type="submit"
                        className="btn btn-primary submit-btn"
                        value="Login"
                    />
                </form>
                <CookieModal
                    changeStatus={this.props.changeStatus}
                    appState={this.props.appState}
                />
            </div>
        );
    }
}

export default LoginForm;
