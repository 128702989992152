import React, { Component } from 'react';
import { parsePhoneNumber, checkPasswordStrength, fetchAPI, APIResp } from '../utils';
import { RouteComponentProps } from 'react-router';
import { WarningAlert, StatusAlert, SuccessAlert } from '../Alert';
import Cookies from 'universal-cookie';
import '../styles/SignUpForm.scss';
import { AppState } from '../App';
import CookieModal from '../components/Modals/CookieModal';
import { NavLink } from 'react-router-dom';

import axios from 'axios';
const cookies = new Cookies();

interface ChangeStatus {
    changeStatus?(status: string, type: string, title: string): void;
    doCheck: any;
}

interface SignupFormProps extends RouteComponentProps<{ token: string }>, ChangeStatus {
    appState: AppState;
    setErrorMessage(msg: string): void;
}

interface GenericSignupFormProps {
    changeStatus?(status: string, type: string, title: string): void;
    title: string;
    type: string;
    name1Text: string;
    name2Text: string;
    token: string;
    doCheck: any;

    appState: AppState;
    setErrorMessage(msg: string): void;
}

interface SignupFormState {
    // Actual form content
    name1: string; // First name for doctors, practice name for practices
    name2: string; // Last name for doctors, contact name for practices
    email: string;
    phone1: string;
    phone2: string;
    password1: string;
    password2: string;

    /// Used for status messages / error messages
    name1Message: string;
    name2Message: string;
    passwordMessage: string;
    passwordStrength: string;
    passwordRepeatMessage: string;
    phoneMessage: string;
    phoneRepeatMessage: string;
    emailMessage: string;

    statusMessage: string;
    errorMessage: string;
    successMessage: string;

    boroughId: string;
    boroughs: { [id: number]: string };
}

class SignupForm extends Component<GenericSignupFormProps, SignupFormState> {
    zxcvbn: any;

    constructor(props: GenericSignupFormProps) {
        super(props);
        this.state = {
            name1: '',
            name2: '',
            email: '',
            phone1: '',
            phone2: '',
            password1: '',
            password2: '',

            name1Message: '',
            name2Message: '',
            passwordMessage: '',
            passwordStrength: '',
            passwordRepeatMessage: '',
            phoneMessage: '',
            phoneRepeatMessage: '',
            emailMessage: '',

            statusMessage: '',
            errorMessage: '',
            successMessage: '',

            boroughId: '1',
            boroughs: {},
        };

        this.checkValidity();
    }

    loadZxcvbn = () => {
        // @ts-ignore
        this.zxcvbn = window.zxcvbn;
        if (this.zxcvbn == null) {
            // We keep fetching until we get it
            setTimeout(this.loadZxcvbn, 200);
        }
    };

    checkValidity = () => {
        fetchAPI(
            {
                url: '/users/check-token-validity/' + this.props.type + '/' + this.props.token,
            },
            this.updateState,
            this.props.appState,
        );
    };

    updateState = (resp: APIResp) => {
        if (resp.data.ok) {
            if (resp.data.data.valid) {
                // Update email
                this.setState({ email: resp.data.data.email });
            } else {
                // Invalid!!
                this.props.setErrorMessage(
                    'An account has already been registered with this invite token. Please try logging in.',
                );
            }
        } else {
            this.props.setErrorMessage(
                'Sorry, the token is either invalid or has expired. Please check if the URL is correct, or request for a new sign-up link.',
            );
        }
    };

    componentDidMount() {
        this.loadZxcvbn();

        axios
            .get('/api/boroughs')
            .then((resp: any) => {
                console.log(resp.data);
                this.setState({ boroughs: resp.data.boroughs });
            })
            .catch(this.props.appState.checkRespAuth);
    }

    updateForm = (field: string, value: string) => {
        switch (field) {
            case 'name1':
                this.setState({ name1: value }, this.checkName1);
                break;
            case 'name2':
                this.setState({ name2: value }, this.checkName2);
                break;
            // case "email":
            //     this.setState({ email: value });
            //     this.checkEmail(value);
            //     break;
            case 'phone1':
                this.setState({ phone1: parsePhoneNumber(value) }, this.checkPhoneRepeat);
                break;
            case 'phone2':
                this.setState({ phone2: parsePhoneNumber(value) }, this.checkPhoneRepeat);
                break;
            case 'password1':
                this.setState({ password1: value }, this.checkPasswordStrength);
                break;
            case 'password2':
                this.setState({ password2: value }, this.checkPasswordRepeat);
                break;
        }
    };

    submitForm = (e: any) => {
        e.preventDefault();
        this.checkName1();
        this.checkName2();
        // this.checkEmail(this.state.email);
        this.checkPhoneRepeat();
        this.checkPasswordStrength(); // calls checkPasswordRepeat
        // we need to setTimeout because state takes a while to propagate

        this.setState({ statusMessage: '', errorMessage: '' }, () => {
            if (
                // this.state.emailMessage.length > 0 ||
                this.state.name1Message.length > 0 ||
                this.state.name2Message.length > 0 ||
                this.state.phoneMessage.length > 0 ||
                this.state.phoneRepeatMessage.length > 0 ||
                this.state.passwordMessage.length > 0 ||
                this.state.passwordRepeatMessage.length > 0
            ) {
                return;
            }

            let payload = {
                email: this.state.email,
                password: this.state.password1,
                name1: this.state.name1,
                name2: this.state.name2,
                phone: this.state.phone1,
                borough: parseInt(this.state.boroughId),
                userType: this.props.type,
                token: this.props.token,
            };

            this.setState({ statusMessage: 'Creating your account...' });

            axios.post('/api/auth/signup', payload).then((response: any) => {
                this.setState({ statusMessage: '' });
                if (response.data.status !== 'ok') {
                    // An error occurred
                    this.setState({ errorMessage: response.data.status });
                } else {
                    // Successfully signed up & logged in
                    // Create cookie to store the hash
                    let expiry = new Date();
                    expiry.setDate(expiry.getDate() + 1);

                    // cookies.set("token", response.data.hash, {
                    //     path: "/",
                    //     expires: expiry
                    // });
                    cookies.set('type', this.props.type, {
                        path: '/',
                        expires: expiry,
                    });
                    this.setState({
                        successMessage: 'Account created. Logging you in.',
                    });
                    setTimeout(() => {
                        this.props.doCheck();
                    }, 500);
                }
            });
        });
    };

    checkName1 = () => {
        if (this.state.name1.length === 0) {
            this.setState({
                name1Message: 'Please input a ' + this.props.name1Text.toLowerCase() + ' name.',
            });
        } else {
            this.setState({ name1Message: '' });
        }
    };

    checkName2 = () => {
        if (this.state.name2.length === 0) {
            this.setState({
                name2Message: 'Please input a ' + this.props.name2Text.toLowerCase() + ' name.',
            });
        } else {
            this.setState({ name2Message: '' });
        }
    };

    // checkEmail = debounce((email: string) => {
    //     checkEmailValidity(email, this.props.type).then(message => {
    //         this.setState({
    //             emailMessage: message
    //         });
    //     });
    // }, 200);

    checkPasswordStrength = () => {
        this.setState(checkPasswordStrength(this.state.password1));
        this.checkPasswordRepeat();
    };

    checkPasswordRepeat = () => {
        if (this.state.password1 !== this.state.password2) {
            this.setState({ passwordRepeatMessage: 'Passwords do not match.' });
        } else {
            this.setState({ passwordRepeatMessage: '' });
        }
    };

    checkPhoneRepeat = () => {
        if (this.state.phone1.length === 0) {
            this.setState({ phoneMessage: 'Please key in your phone number.' });
        } else {
            this.setState({ phoneMessage: '' });
        }
        if (this.state.phone1 !== this.state.phone2) {
            this.setState({
                phoneRepeatMessage: 'Phone numbers do not match.',
            });
        } else {
            this.setState({ phoneRepeatMessage: '' });
        }
    };

    render() {
        let title = (
            <tr>
                <td colSpan={2}>
                    <h2>{this.props.title}</h2>
                </td>
            </tr>
        );

        let names = [
            <tr key="name1">
                <td>{this.props.name1Text} Name:</td>
                <td>
                    <WarningAlert content={this.state.name1Message} />
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.name1}
                        onChange={(e) => {
                            this.updateForm('name1', e.target.value);
                        }}
                    />
                </td>
            </tr>,
            <tr key="name2">
                <td>{this.props.name2Text} Name:</td>
                <td>
                    <WarningAlert content={this.state.name2Message} />
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.name2}
                        onChange={(e) => {
                            this.updateForm('name2', e.target.value);
                        }}
                    />
                </td>
            </tr>,
        ];

        let email = (
            <tr>
                <td>Email:</td>
                <td>
                    <WarningAlert content={this.state.emailMessage} />
                    <input
                        disabled
                        type="email"
                        className="form-control"
                        value={this.state.email}
                        // onChange={e => {
                        //     this.updateForm("email", e.target.value);
                        // }}
                    />
                </td>
            </tr>
        );

        let phoneNumber = [
            <tr key="phonenumber">
                <td>Phone number:</td>
                <td>
                    <WarningAlert content={this.state.phoneMessage} />
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.phone1}
                        onChange={(e) => {
                            this.updateForm('phone1', e.target.value);
                        }}
                    />
                </td>
            </tr>,
            <tr key="repeatonce">
                <td>Repeat phone number:</td>
                <td>
                    <WarningAlert content={this.state.phoneRepeatMessage} />
                    <input
                        type="text"
                        className="form-control"
                        value={this.state.phone2}
                        onChange={(e) => {
                            this.updateForm('phone2', e.target.value);
                        }}
                    />
                </td>
            </tr>,
        ];

        let password = [
            <tr key="password">
                <td>Password:</td>
                <td>
                    <StatusAlert content={this.state.passwordStrength} />
                    <WarningAlert content={this.state.passwordMessage} />
                    <input
                        type="password"
                        className="form-control"
                        value={this.state.password1}
                        onChange={(e) => {
                            this.updateForm('password1', e.target.value);
                        }}
                    />
                </td>
            </tr>,
            <tr key="repeatonce">
                <td>Repeat password:</td>
                <td>
                    <WarningAlert content={this.state.passwordRepeatMessage} />
                    <input
                        type="password"
                        className="form-control"
                        value={this.state.password2}
                        onChange={(e) => {
                            this.updateForm('password2', e.target.value);
                        }}
                    />
                </td>
            </tr>,
        ];

        const boroughs = (
            <tr>
                <td>Borough:</td>
                <td>
                    <select
                        className="form-control"
                        style={{ marginTop: '10px' }}
                        value={this.state.boroughId}
                        onChange={(e) => {
                            this.setState({ boroughId: e.target.value });
                        }}
                    >
                        {Object.keys(this.state.boroughs).map((borough_id) => (
                            <option key={borough_id} value={borough_id.toString()}>
                                {this.state.boroughs[parseInt(borough_id)]}
                            </option>
                        ))}
                    </select>
                </td>
            </tr>
        );

        return (
            <div id="signup-form">
                <WarningAlert content={this.state.errorMessage} />
                <StatusAlert content={this.state.statusMessage} />
                <SuccessAlert content={this.state.successMessage} />
                {title}
                <NavLink to="/" className="btn btn-tertiary full-btn">
                    Already have an account? Log in here.
                </NavLink>
                <form onSubmit={this.submitForm}>
                    <table>
                        <tbody>
                            {names}
                            {email}
                            {phoneNumber}
                            {password}
                            {this.props.type === 'practice' && boroughs}
                            <tr>
                                <td colSpan={2}>
                                    <input
                                        type="submit"
                                        className="btn btn-primary submit-btn"
                                        value="Sign Up"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
                <CookieModal
                    changeStatus={this.props.changeStatus}
                    appState={this.props.appState}
                />
            </div>
        );
    }
}

class DoctorSignUpForm extends Component<SignupFormProps, {}> {
    render() {
        return (
            <SignupForm
                title="Locum GP Sign Up"
                changeStatus={this.props.changeStatus}
                type="doctor"
                name1Text="First"
                name2Text="Last"
                token={this.props.match.params.token}
                doCheck={this.props.doCheck}
                appState={this.props.appState}
                setErrorMessage={this.props.setErrorMessage}
            />
        );
    }
}

class PracticeSignUpForm extends Component<SignupFormProps, {}> {
    render() {
        return (
            <SignupForm
                title="General Practice Sign Up"
                changeStatus={this.props.changeStatus}
                type="practice"
                name1Text="Practice"
                name2Text="Contact"
                token={this.props.match.params.token}
                doCheck={this.props.doCheck}
                appState={this.props.appState}
                setErrorMessage={this.props.setErrorMessage}
            />
        );
    }
}

export { DoctorSignUpForm, PracticeSignUpForm };
