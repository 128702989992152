import React, { Component } from 'react';
import { AppState } from '../../App';
import { APIResp, fetchAPI } from '../../utils';
import Loading from './../Loading';
import { ChangePasswordBtn, EditProfileFieldBtn, LogOutEverywhereBtn } from '../Buttons';
import { DisplayPracticeBorough } from './DisplayPracticeBorough';

interface FieldProps {
    name: string;
    dataKey: string;
    profile: any;
    appState: AppState;
    onSuccess: any;
    options?: string[];
}

const DataField = (props: FieldProps) => {
    let value = props.profile[props.dataKey];
    let valueField;

    if (value === '') {
        valueField = (
            <td className='field-value field-value-not-set'>
                {' '}
                — not set —{' '}
            </td>
        );
    } else {
        valueField = <td className='field-value'>{value}</td>;
    }
    return (
        <tr className='data-field'>
            <td className='field-name'>{props.name}</td>
            {valueField}
            <td className='field-btn'>
                <EditProfileFieldBtn
                    appState={props.appState}
                    event={{
                        name: props.name,
                        dataKey: props.dataKey,
                        value: value,
                    }}
                    onSuccess={props.onSuccess}
                    options={props.options}
                />
            </td>
        </tr>
    );
};

interface DetailsState {
    profile: any;
    fetchingData: boolean;
}

class Details extends Component<
    { appState: AppState; setSuccessMessage: any },
    DetailsState
> {
    constructor(props: { appState: AppState; setSuccessMessage: any }) {
        super(props);
        this.state = {
            fetchingData: true,
            profile: {},
        };
        this.fetchData();
    }

    updateState = (resp: APIResp) => {
        if (resp.data.ok) {
            this.setState({
                profile: resp.data.data,
                fetchingData: false,
            });
            console.log(resp.data.data);
            this.props.appState.updateUICounts(resp.data);
        }
    };

    fetchData = () => {
        fetchAPI({ url: '/profile/' }, this.updateState, this.props.appState);
    };

    onSuccess = (message: any) => {
        this.props.setSuccessMessage(message);
        this.fetchData();
    };

    render() {
        let fieldProps = {
            profile: this.state.profile,
            appState: this.props.appState,
            onSuccess: this.onSuccess,
        };

        let names;
        if (this.props.appState.userType === 'doctor') {
            names = [
                <DataField
                    key={'first-name'}
                    name='First Name'
                    dataKey='firstName'
                    {...fieldProps}
                />,
                <DataField
                    key={'last-name'}
                    name='Last Name'
                    dataKey='lastName'
                    {...fieldProps}
                />,
                <DataField
                    key={'gender'} name={'Gender'} dataKey={'gender'} {...fieldProps}
                    options={['Male', 'Female', 'Not Specified']}
                />,
            ];
        } else if (this.props.appState.userType === 'practice') {
            names = [
                <DataField
                    key={'practice-name'}
                    name='Practice Name'
                    dataKey='name'
                    {...fieldProps}
                />,
                <DataField
                    key={'contact-name'}
                    name='Contact Person'
                    dataKey='contactName'
                    {...fieldProps}
                />,
            ];
        }

        return (
            <Loading loading={this.state.fetchingData}><>
                <table>
                    <tbody>
                    {names}
                    <DataField
                        name='Email'
                        dataKey='email'
                        {...fieldProps}
                    />
                    <DataField
                        name='Phone'
                        dataKey='phone'
                        {...fieldProps}
                    />
                    {this.props.appState.userType === 'practice' && (
                        <DataField
                            name='Website'
                            dataKey='website'
                            {...fieldProps}
                        />
                    )}
                    {this.props.appState.userType === 'practice' && (
                        <DataField
                            name='Essential Practice Information'
                            dataKey='bio'
                            {...fieldProps}
                        />
                    )}
                    {this.props.appState.userType === 'doctor' && (
                        <DataField
                            name='Bio'
                            dataKey='bio'
                            {...fieldProps}
                        />
                    )}
                    {this.props.appState.userType === 'practice' && (
                        <DisplayPracticeBorough appState={this.props.appState} borough={this.state.profile.borough} />
                    )}

                    </tbody>
                </table>
                <br />
                <ChangePasswordBtn
                    appState={this.props.appState}
                    event={{}}
                    onSuccess={this.onSuccess}
                >
                    &nbsp;&nbsp;Change Password
                </ChangePasswordBtn>
                &nbsp;
                <LogOutEverywhereBtn
                    appState={this.props.appState}
                    event={{}}
                    onSuccess={this.onSuccess}
                >
                    &nbsp;&nbsp;Logout From All Devices
                </LogOutEverywhereBtn>
            </>
            </Loading>
        );
    }
}

export default Details;
