import React, { Component } from 'react';
import { CompProps } from '../Main';
import Calendar from '../components/Calendar';

import '../styles/Dashboard.scss';
import { calObj } from '../interfaces';
import { requestHeader } from '../utils';
import { Moment } from 'moment';
import { IconName } from '@fortawesome/pro-solid-svg-icons';
import { AppState } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import DashboardNotif, { dashboardNotif } from '../components/DashboardNotif';
import Noticeboard from './Noticeboard';

import axios from 'axios';

var moment = require('moment');
moment().format();
moment().locale('en');

interface InfoCellProps {
    header: string;
    value: number;
    icon: IconName;
    link: string;
}

class InfoCell extends Component<InfoCellProps> {
    render() {
        return (
            <NavLink to={this.props.link} className={'info-cell'}>
                <div className="info-cell-header">{this.props.header}</div>
                <div className="info-cell-body">
                    <FontAwesomeIcon
                        icon={['far', this.props.icon]}
                        className="info-cell-icon"
                        fixedWidth
                    />
                    <div className="info-cell-value">{this.props.value}</div>
                </div>
            </NavLink>
        );
    }
}

interface InfoCellsProps {
    appState: AppState;
}

class InfoCells extends Component<InfoCellsProps & CompState> {
    render() {
        let applicationsName = 'Applications';
        let offersName = 'Offers';
        let applicationsIcon: IconName;
        let offersIcon: IconName;

        if (this.props.appState.userType === 'doctor') {
            applicationsName = 'Pending Applications';
            applicationsIcon = 'clock';
            offersName = 'Incoming Offers';
            offersIcon = 'inbox-in';
        } else if (this.props.appState.userType === 'practice') {
            applicationsName = 'Incoming Applications';
            applicationsIcon = 'inbox-in';
            offersName = 'Pending Offers';
            offersIcon = 'clock';
        } else {
            return <div />;
        }

        return (
            <div id="info-cells">
                <InfoCell
                    header={applicationsName}
                    value={this.props.numApplications}
                    icon={applicationsIcon}
                    link="/applications"
                />
                <InfoCell
                    header={offersName}
                    value={this.props.numOffers}
                    icon={offersIcon}
                    link="/offers"
                />
                <InfoCell
                    header="Upcoming Bookings"
                    value={this.props.numUpcoming}
                    icon="check-square"
                    link="/bookings/upcoming"
                />
                <InfoCell
                    header="Jobs Not Invoiced"
                    value={this.props.numNotInvoiced}
                    icon="clock"
                    link="/bookings/invoice-not-yet-sent"
                />
                <InfoCell
                    header="Unpaid Invoices"
                    value={this.props.numPendingPayment}
                    icon="money-bill-wave"
                    link="/invoices/pending-payment"
                />
            </div>
        );
    }
}

interface CompState {
    fetchingData: boolean;
    numOffers: number;
    numApplications: number;
    numNotInvoiced: number;
    numPendingPayment: number;
    numUpcoming: number;
    currentDay: Moment;
    agendaEvents: calObj[];
    dashboardNotifs: dashboardNotif[];
}

class Dashboard extends Component<CompProps, CompState> {
    constructor(props: CompProps) {
        super(props);
        this.props.setTitle('Dashboard');

        this.state = {
            fetchingData: true,
            numOffers: 0,
            numApplications: 0,
            numNotInvoiced: 0,
            numPendingPayment: 0,
            numUpcoming: 0,
            currentDay: moment(),
            agendaEvents: [],
            dashboardNotifs: [],
        };

        this.fetchData();
    }

    fetchData = () => {
        axios
            .get('/api/dashboard', requestHeader())
            .then((resp: any) => {
                this.setState({ fetchingData: false });
                if (resp.status !== 200) {
                    // Handle error
                    console.log('http error');
                } else {
                    if (resp.data.ok) {
                        this.setState(resp.data.data);
                        this.props.appState.updateUICounts(resp.data);
                        this.fetchAgendaEvents();
                    } else {
                        // Handle error
                        console.log(resp.data.error);
                    }
                }
            })
            .catch(this.props.appState.checkRespAuth);
    };

    fetchAgendaEvents = () => {
        let dateStr = this.state.currentDay.format('YYYY-MM-DD');
        axios
            .get('/api/calendar/' + dateStr, requestHeader())
            .then((resp: any) => {
                // Parse the input
                if (resp.data.ok) {
                    this.setState({ agendaEvents: resp.data.data });
                    this.props.appState.updateUICounts(resp.data);
                } else {
                    // Display error
                }
            })
            .catch(this.props.appState.checkRespAuth);
    };

    onUpdate = (message: string) => {
        if (message != null && message.length > 0) {
            this.props.setSuccessMessage(message);
        }
        this.fetchData();
    };

    setDay = (day: Moment) => {
        this.setState({ currentDay: day }, this.fetchAgendaEvents);
    };

    render() {
        let notifs = this.state.dashboardNotifs.map((notif) => (
            <DashboardNotif {...notif} appState={this.props.appState} />
        ));
        return (
            <div id="dashboard">
                <div className="row">
                    <InfoCells appState={this.props.appState} {...this.state} />
                    {notifs}
                    <h2>Noticeboard</h2>
                    <Noticeboard {...this.props} dontSetTitle />
                    <h2>Calendar</h2>
                    <Calendar {...this.props} onDashboard />
                </div>
            </div>
        );
    }
}

export default Dashboard;
