import React, { Component } from 'react';
import BackBtn from '../landing-page/BackBtn';
import { LandingPageProps, LandingPageState, ChangeStatus } from '../landing-page/LandingPage';
import { RouteComponentProps } from 'react-router';
import { SuccessAlert } from '../Alert';

import axios from 'axios';

interface ForgotPasswordProps extends RouteComponentProps<{ userType: string }>, LandingPageProps {}

class ForgotPassword extends Component<
    ForgotPasswordProps & LandingPageState & ChangeStatus,
    { email: string; successMessage: string }
> {
    constructor(props: ForgotPasswordProps & LandingPageState & ChangeStatus) {
        super(props);
        this.state = {
            email: '',
            successMessage: '',
        };
    }

    updateEmail = (e: any) => {
        this.setState({ email: e.target.value });
    };

    resetPassword = (e: any) => {
        e.preventDefault();
        axios
            .post('/api/auth/send-reset-request', {
                email: this.state.email,
                userType: this.props.match.params.userType,
            })
            .then(() => {
                this.setState({
                    successMessage:
                        "If you have an account with that email, you should receive an email from us within the next few minutes. If it doesn't work, please contact us at <a href='mailto:gillian.kyei@nhs.net'>gillian.kyei@nhs.net</a> to let us know. ",
                });
            });
    };

    render() {
        return (
            <div>
                <BackBtn changeStatus={this.props.changeStatus} />
                <h2>Reset Password</h2>
                <SuccessAlert content={this.state.successMessage} />
                <form onSubmit={this.resetPassword}>
                    Please key in your email address:
                    <input
                        type="email"
                        name="email"
                        className="form-control"
                        value={this.state.email}
                        onChange={this.updateEmail}
                    />
                    <input className="btn btn-primary" type="submit" value="Reset Password" />
                </form>
            </div>
        );
    }
}

export default ForgotPassword;
