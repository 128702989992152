import React, { Component, Fragment, useEffect, useState } from 'react';
import { CompProps } from '../Main';
import { APIResp, fetchAPI } from '../utils';
import '../styles/Profile.scss';
import { IconName } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppState } from '../App';
import { NavLink } from 'react-router-dom';
import Loading from './Loading';
import ExternalLink from './ExternalLink';
import EmptyState from './EmptyState';

interface RowProps {
    keyValue: string;
    icon: IconName;
    value: string;
}

export class ProfileBodyRow extends Component<RowProps & { appState: AppState }> {
    render() {
        let content;
        if (this.props.value == null || this.props.value === '') {
            return <div />;
        }
        if (this.props.keyValue === 'website') {
            let link = this.props.value;
            if (!link.startsWith('http')) {
                link = 'http://' + link;
            }
            content = <ExternalLink link={link}>{this.props.value}</ExternalLink>;
        } else if (this.props.keyValue === 'email') {
            content = (
                <ExternalLink link={'mailto:' + this.props.value}>{this.props.value}</ExternalLink>
            );
        } else if (this.props.keyValue === 'borough') {
            content = <span>{this.props.appState.boroughs[parseInt(this.props.value)]}</span>;
        } else {
            content = <span>{this.props.value}</span>;
        }
        return (
            <div className={'row-' + this.props.keyValue}>
                <FontAwesomeIcon
                    className='profiles-icon'
                    icon={['far', this.props.icon]}
                    fixedWidth
                />{' '}
                &nbsp;
                {content}
            </div>
        );
    }
}

interface ProfileProps {
    appState: AppState;
    profile: any;
}

class ProfileCard extends Component<ProfileProps> {
    render() {
        let body = [];
        let keys: RowProps[] = [
            { keyValue: 'borough', icon: 'map-signs', value: '' },
            { keyValue: 'address', icon: 'map-marker-alt', value: '' },
            { keyValue: 'phone', icon: 'phone', value: '' },
            { keyValue: 'email', icon: 'envelope', value: '' },
            { keyValue: 'website', icon: 'globe', value: '' },
        ];

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i].keyValue;
            let icon = keys[i].icon;
            if (key in this.props.profile && this.props.profile[key] !== '') {
                body.push(
                    <ProfileBodyRow
                        keyValue={key}
                        key={key}
                        icon={icon}
                        value={this.props.profile[key]}
                        appState={this.props.appState}
                    />,
                );
            }
        }

        return (
            <NavLink to={'/view-profile/' + this.props.profile.hash} className='profiles-card'>
                <div className='profiles-profile-body'>
                    <div className='profiles-name'>{this.props.profile.name}</div>
                    <div className='profiles-body'>{body}</div>
                </div>
            </NavLink>
        );
    }
}

const DoctorProfiles = (props: CompProps) => {
    const [loading, setLoading] = useState(true);
    const [profiles, setProfiles] = useState<any[]>([]);

    useEffect(() => {
        props.setTitle('Locum Profiles');
        fetchAPI({ url: '/profiles' }, updateState, props.appState);
        // eslint-disable-next-line
    }, []);

    const updateState = (resp: APIResp) => {
        setLoading(false);
        if (resp.data.ok) {
            setProfiles(resp.data.data);
            props.appState.updateUICounts(resp.data);
        }
    };

    return (
        <Loading loading={loading}>
            <div id='profiles'>
                {profiles.length > 0 && <div className='profiles'>{profiles.map((profile) => (
                    <ProfileCard
                        key={profile.hash}
                        profile={profile}
                        appState={props.appState}
                    />
                ))}</div>}
                {profiles.length === 0 && (
                    <EmptyState icon='user-slash'>
                        No locums found. Please check back at a later date!
                    </EmptyState>
                )}
            </div>
        </Loading>
    );
};

const PracticeProfiles = (props: CompProps) => {
    const [loading, setLoading] = useState(true);
    const [resp, setResp] = useState<APIResp>();
    const [profiles, setProfiles] = useState<JSX.Element[]>([<Fragment />]);

    useEffect(() => {
        props.setTitle('Practice Profiles');
        fetchAPI({ url: '/profiles' }, setResp, props.appState);
        // eslint-disable-next-line
    }, []);

    const updateState = () => {
        if (resp == null) return;

        setLoading(false);
        if (resp.data.ok) {
            let newProfiles = [];
            for (const ccg_id in resp.data.data) {
                newProfiles.push(
                    <PracticeCCG
                        key={ccg_id}
                        {...props}
                        ccg_id={parseInt(ccg_id)}
                        profiles={resp.data.data[ccg_id]}
                    />,
                );
            }
            setProfiles(newProfiles);
            props.appState.updateUICounts(resp.data);
        }
    };

    useEffect(() => {
        updateState();
        // eslint-disable-next-line
    }, [resp, props.appState.boroughs]);

    return (
        <Loading loading={loading}>
            <div id='profiles'>
                {profiles.length > 0 && profiles}
                {profiles.length === 0 && (
                    <EmptyState icon='user-slash'>
                        No practices found. Please check back at a later date!
                    </EmptyState>
                )}
            </div>
        </Loading>
    );
};

const PracticeCCG = (
    props: CompProps & { ccg_id: number; profiles: { [borough_id: number]: any[] } },
) => {
    let ccg_name = '<undefined>';
    for (let i = 0; i < props.appState.ccgs.length; i++) {
        if (props.appState.ccgs[i].id === props.ccg_id) {
            ccg_name = props.appState.ccgs[i].name;
            break;
        }
    }

    return (
        <div className='practice-ccg'>
            <h2>{ccg_name}</h2>
            {Object.keys(props.profiles).map((borough_id) => (
                <PracticeBorough
                    {...props}
                    key={borough_id}
                    borough={props.appState.boroughs[parseInt(borough_id)]}
                    profiles={props.profiles[parseInt(borough_id)]}
                />
            ))}
        </div>
    );
};

const PracticeBorough = (props: CompProps & { borough: string; profiles: any[] }) => {
    return (
        <div className='practice-borough'>
            <h3>{props.borough}</h3>
            <div className='profiles'>
                {props.profiles.map((profile) => (
                    <ProfileCard key={profile.hash} profile={profile} appState={props.appState} />
                ))}
            </div>
        </div>
    );
};

const Profiles = (props: CompProps) => {
    if (props.appState.userType === 'doctor') {
        return <PracticeProfiles {...props} />;
    } else {
        return <DoctorProfiles {...props} />;
    }
};

export default Profiles;
