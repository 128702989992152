import React, { Component } from 'react';
import { checkEmailValidity, checkWebsiteValidity } from '../../utils';
import { GenericModalProps } from './Modals';
import GenericModal from './GenericModal';
import { WarningAlert } from '../../Alert';
import { AppState } from '../../App';

const debounce = require('lodash.debounce');

interface FieldState {
    value: string;
    warningMessage: string;
}

function BioInput(props: { appState: AppState, event: any, onChange: (e: any) => void, value: string }) {
    return <div>
        {props.appState.userType === 'practice' && (
            <div>
                Please include any information you would like
                your locums to know about your practice. Please
                also consider the following:
                <ul>
                    <li>how to get to us/where to park</li>
                    <li>
                        useful extension numbers, eg reception,
                        secretaries, practice nurse, other GPs
                        on-site
                    </li>
                    <li>process for making referrals</li>
                    <li>
                        how to request ECGs and BP monitoring
                    </li>
                    <li>
                        any in-house services such as minor
                        surgery/contraceptive
                        services/phlebotomy
                    </li>
                    <li>
                        location of equipment including
                        defibrillator and emergency equipment{' '}
                    </li>
                    <li>
                        contact details for Consultant Connect
                    </li>
                </ul>
            </div>
        )}
        <textarea
            className='form-control form-control-bio'
            name={props.event.dataKey}
            onChange={props.onChange}
            value={props.value}
        />
    </div>;
}

function ModalContent(props: { event: any, options: string[] | undefined, onChange: (e: any) => void, value: string, appState: AppState }) {
    if (props.event.dataKey === 'bio') {
        return <BioInput
            appState={props.appState} event={props.event} onChange={props.onChange}
            value={props.value}
        />;
    }

    if (props.options && props.options.length > 0) {
        return <div>
            {props.options.map(o => {
                const id = props.event.dataKey + o;
                return <div key={o}>
                    <input
                        name={props.event.dataKey} id={id} type='radio'
                        value={o} checked={props.value === o}
                        onChange={props.onChange}
                    />
                    <label htmlFor={id}>&nbsp;{o}&nbsp;&nbsp;</label>
                </div>;
            })}
        </div>;
    }

    return <input
        className='form-control'
        name={props.event.dataKey}
        onChange={props.onChange}
        value={props.value}
    />;
}

class EditProfileFieldModal extends Component<GenericModalProps & { options?: string[] }, FieldState> {
    checkEmail = debounce((email: string) => {
        checkEmailValidity(email, this.props.appState.userType).then(
            message => {
                this.setState({
                    warningMessage: message,
                });
            },
        );
    }, 200);

    constructor(props: GenericModalProps) {
        super(props);
        this.state = {
            value: this.props.event.value,
            warningMessage: '',
        };
    }

    checkWebsite = (website: string) => {
        if (checkWebsiteValidity(website)) {
            this.setState({ warningMessage: '' });
        } else {
            this.setState({ warningMessage: 'Please key in a valid website.' });
        }
    };

    updateValue = (e: any) => {
        let value = e.target.value;
        if (
            this.props.event.dataKey === 'email' &&
            value !== this.props.event.value
        ) {
            // We check value, to see if it's the old email address
            // Do email validation
            this.checkEmail(value);
        }
        if (this.props.event.dataKey === 'website') {
            this.checkWebsite(value);
        }
        this.setState({ value: value });
    };

    checkOkSubmit = () => {
        return this.state.warningMessage.length <= 0;
    };

    render() {
        let requestParams = {
            url: '/profile/',
            method: 'post',
            payload: {
                field: this.props.event.dataKey,
                value: this.state.value,
            },
        };

        return (
            <GenericModal
                {...this.props}
                title={'Edit ' + this.props.event.name}
                yesBtn='Update'
                noBtn='Cancel'
                checkOkSubmit={this.checkOkSubmit}
                requestParams={requestParams}
                successMessage='Profile updated.'
                size={this.props.event.dataKey === 'bio' ? 'lg' : 'sm'}
            >
                <WarningAlert content={this.state.warningMessage} />
                <ModalContent event={this.props.event} options={this.props.options} onChange={this.updateValue}
                              value={this.state.value} appState={this.props.appState} />
            </GenericModal>
        );
    }
}

export default EditProfileFieldModal;
