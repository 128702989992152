import React, { Component } from "react";
import AuthenticationBoxes from "./AuthenticationBoxes";
import logo from "../imgs/logo.png";

import "../styles/LandingPage.scss";
import LoginForm from "./LoginForm";
import { DoctorSignUpForm, PracticeSignUpForm } from "./SignUpForm";
import { Switch, Route } from "react-router";
import ForgotPassword from "../components/ForgotPassword";
import ResetPassword from "../components/ResetPassword";
import { AppState } from "../App";

export interface LandingPageProps {
    doCheck: any;
    appState: AppState;
    setErrorMessage(msg: string): void;
}

export interface LandingPageState {
    status: string;
    type: string;
    title: string;
}

export interface ChangeStatus {
    changeStatus: any;
}

class AuthBoxes extends Component<
    LandingPageProps & LandingPageState & ChangeStatus
> {
    render() {
        return (
            <div>
                {this.props.status === "" && (
                    <AuthenticationBoxes
                        changeStatus={this.props.changeStatus}
                    />
                )}
                {this.props.status === "login" && (
                    <LoginForm
                        doCheck={this.props.doCheck}
                        changeStatus={this.props.changeStatus}
                        title={this.props.title}
                        userType={this.props.type}
                        appState={this.props.appState}
                    />
                )}
                {/* {this.state.status === 'signup' && ((
                    this.state.type === 'doctor' && 
                        <DoctorSignUpForm changeStatus={this.changeStatus} />
                ) || (this.state.type === 'practice' && 
                        <PracticeSignUpForm changeStatus={this.changeStatus} />
                ))} */}
            </div>
        );
    }
}

class LandingPage extends Component<LandingPageProps, LandingPageState> {
    constructor(props: LandingPageProps) {
        super(props);
        this.state = {
            status: "",
            type: "",
            title: ""
        };
    }

    changeStatus = (newStatus: string, newType: string, newTitle: string) => {
        this.setState({
            status: newStatus,
            type: newType,
            title: newTitle
        });
        if (newStatus === "" && window.location.pathname !== "/") {
            window.location.pathname = "/";
        }
    };

    render() {
        return (
            <div id="landing-page">
                <div id="landing-page-header">
                    <img src={logo} alt="logo" id="logo" />
                    <h1>Versatile GP</h1>
                </div>
                <Switch>
                    <Route
                        path="/forgot-password/:userType"
                        render={match => (
                            <ForgotPassword
                                {...match}
                                {...this.props}
                                {...this.state}
                                changeStatus={this.changeStatus}
                            />
                        )}
                    />
                    <Route
                        path="/reset-password/:uuid/:token"
                        render={match => <ResetPassword {...match} />}
                    />
                    <Route
                        path="/signup/doctor/:token"
                        render={match => (
                            <DoctorSignUpForm
                                {...match}
                                {...this.props}
                                {...this.state}
                                changeStatus={this.changeStatus}
                            />
                        )}
                    />
                    <Route
                        path="/signup/practice/:token"
                        render={match => (
                            <PracticeSignUpForm
                                {...match}
                                {...this.props}
                                {...this.state}
                                changeStatus={this.changeStatus}
                            />
                        )}
                    />
                    <Route
                        render={() => (
                            <AuthBoxes
                                {...this.props}
                                {...this.state}
                                changeStatus={this.changeStatus}
                            />
                        )}
                    />
                </Switch>
                {/* <div className="swatches">
					<table>
						<tr>
							<td className="primary-1">&nbsp;</td>
							<td className="primary-2">&nbsp;</td>
							<td className="primary-3">&nbsp;</td>
							<td className="primary-4">&nbsp;</td>
							<td className="primary-5">&nbsp;</td>
							<td className="primary-6">&nbsp;</td>
							<td className="primary-7">&nbsp;</td>
							<td className="primary-8">&nbsp;</td>
							<td className="primary-9">&nbsp;</td>
						</tr>
					</table>
					<table>
						<tr>
							<td className="grey-1">&nbsp;</td>
							<td className="grey-2">&nbsp;</td>
							<td className="grey-3">&nbsp;</td>
							<td className="grey-4">&nbsp;</td>
							<td className="grey-5">&nbsp;</td>
							<td className="grey-6">&nbsp;</td>
							<td className="grey-7">&nbsp;</td>
							<td className="grey-8">&nbsp;</td>
							<td className="grey-9">&nbsp;</td>
						</tr>
					</table>
				</div> */}
            </div>
        );
    }
}

export default LandingPage;
