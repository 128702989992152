import React, { Component } from "react";
import { AppState } from "../App";
import { APIResp, fetchAPI } from "../utils";
import Loading from "../components/Loading";
import JobListItems from "../components/ListItems/JobListItems";

interface JobNegosProps {
    appState: AppState;
    type: string;
    setTitle: any;
    setSuccessMessage: any;
}

interface JobNegosState {
    events: any;
    currType: string;
    fetchingData: boolean;
}

export class JobNegos extends Component<JobNegosProps, JobNegosState> {
    constructor(props: JobNegosProps) {
        super(props);

        this.state = {
            events: [],
            currType: "",
            fetchingData: false
        };
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.type !== prevState.currType && !prevState.fetchingData) {
            return { fetchingData: true, events: [] };
        }
        return null;
    }

    updateTitle = () => {
        let title = "";

        if (this.props.appState.userType === "doctor") {
            if (this.props.type === "offers") {
                title = "Incoming Offers";
            } else if (this.props.type === "applications") {
                title = "Pending Applications";
            }
        } else if (this.props.appState.userType === "practice") {
            if (this.props.type === "offers") {
                title = "Outgoing Offers";
            } else if (this.props.type === "applications") {
                title = "Pending Applications";
            }
        }

        this.props.setTitle(title);
    };

    componentDidMount() {
        this.updateTitle();
    }

    componentDidUpdate() {
        if (
            this.state.fetchingData &&
            this.state.currType !== this.props.type
        ) {
            this.fetchEvents();
            this.updateTitle();
            this.setState({ currType: this.props.type });
        }
    }

    onUpdate = (message: string) => {
        if (message != null && message.length > 0) {
            this.props.setSuccessMessage(message);
        }
        this.fetchEvents();
    };

    updateState = (resp: APIResp) => {
        this.setState({ fetchingData: false });
        if (resp.data.ok) {
            this.setState({ events: resp.data.data });
            this.props.appState.updateUICounts(resp.data);
        } else {
            // Handle error
            console.log(resp.data.error);
        }
    };

    fetchEvents = () => {
        fetchAPI(
            { url: "/" + this.props.type },
            this.updateState,
            this.props.appState
        );
    };

    render() {
        let eventType = "";

        if (this.props.appState.userType === "doctor") {
            if (this.props.type === "offers") {
                eventType = "incoming";
            } else if (this.props.type === "applications") {
                eventType = "outgoing";
            }
        } else if (this.props.appState.userType === "practice") {
            if (this.props.type === "offers") {
                eventType = "outgoing";
            } else if (this.props.type === "applications") {
                eventType = "incoming";
            }
        }

        eventType += " " + this.props.type;

        return (
            <div>
                <Loading loading={this.state.fetchingData}>
                    <JobListItems
                        appState={this.props.appState}
                        onUpdate={this.onUpdate}
                        data={this.state.events}
                        eventType={eventType}
                    />
                </Loading>
            </div>
        );
    }
}
