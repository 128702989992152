import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faLongArrowLeft,
    faEye,
    faEyeSlash,
    faExclamationCircle,
    faCheckCircle,
    faCircle,
    faPlus,
    faSpinnerThird,
    faPencilAlt,
    faTrashAlt,
    faCheck,
    faTimes,
    faComments as fasFaComments,
    faBan,
    faChevronLeft,
    faChevronRight,
    faCalendarPlus as fasCalendarPlus,
    faFileInvoice as fasFileInvoice,
    faMoneyBillWave,
    faChevronCircleRight,
    faExclamation,
    faBell as fasBell,
    faComments as fasComments,
    faSignOut as fasSignOut,
    faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faBell,
    faSignOut,
    faCalendarDay,
    faComments as farFaComments,
    faCalendarPlus,
    faUserCircle,
    faCalendarCheck,
    faFileInvoice,
    faUsers,
    faInboxIn,
    faInboxOut,
    faClock,
    faCheckSquare,
    faUndo,
    faPlusSquare,
    faExclamationSquare,
    faChartNetwork,
    faCommentAltEdit,
    faCheck as farCheck,
    faTimes as farTimes,
    faTrashAlt as farTrashAlt,
    faPencilAlt as farPencilAlt,
    faBan as farBan,
    faPlus as farPlus,
    faMapMarkerAlt,
    faEmptySet,
    faEnvelope,
    faPhone,
    faGlobe,
    faCalendarTimes,
    faMoneyBillWave as farMoneyBillWave,
    faSave,
    faUserSlash,
    faCommentSlash,
    faThumbtack,
    faMapSigns,
    faFile
} from '@fortawesome/pro-regular-svg-icons';

import { faDoorOpen } from '@fortawesome/pro-light-svg-icons';
import WebFont from 'webfontloader';

library.add(
    faLongArrowLeft,
    faEye,
    faEyeSlash,
    faExclamationCircle,
    faCheckCircle,
    faCircle,
    faCalendarDay,
    faEmptySet,
    fasFaComments,
    faExclamation,
    farFaComments,
    faBell,
    faUserCircle,
    faCommentSlash,
    faSignOut,
    faCalendarPlus,
    faCalendarCheck,
    faFileInvoice,
    faUsers,
    faInboxIn,
    faInboxOut,
    faClock,
    faCheckSquare,
    faPlusSquare,
    faExclamationSquare,
    faChartNetwork,
    faPlus,
    faSpinnerThird,
    faPencilAlt,
    faTrashAlt,
    faCheck,
    faCommentAltEdit,
    faInfoCircle,
    faTimes,
    faBan,
    faChevronLeft,
    faChevronRight,
    fasCalendarPlus,
    fasFileInvoice,
    farCheck,
    farTimes,
    farTrashAlt,
    farPencilAlt,
    farBan,
    farPlus,
    faMoneyBillWave,
    faMapMarkerAlt,
    faEnvelope,
    faPhone,
    faGlobe,
    faUndo,
    faChevronCircleRight,
    fasBell,
    fasComments,
    fasSignOut,
    farMoneyBillWave,
    faCalendarTimes,
    faSave,
    faDoorOpen,
    faThumbtack,
    faUserSlash,
    faMapSigns,
    faFile
);

WebFont.load({
    google: {
        families: ['Karla:400,700', 'Merriweather', 'Open Sans'],
    },
});
