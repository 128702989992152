import React, { Component } from "react";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";

class RejectInvoiceModal extends Component<GenericModalProps> {
    render() {
        let name = this.props.event.doctor.name;
        let requestParams = {
            url: '/invoices/id/' + this.props.event.id + '/delete',
            method: 'post'
        }

        return (
            <GenericModal
                {...this.props}
                title={"Rejecting the invoice"}
                yesBtn="Reject"
                noBtn="No"
                requestParams={requestParams}
                successMessage={name + "'s invoice has been rejected."}
            >
                Are you sure you want to reject {name}'s invoice?
            </GenericModal>
        );
    }
}

export default RejectInvoiceModal;
