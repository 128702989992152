import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {default as sanitizeHTML} from "sanitize-html";

interface GenericAlertProps {
    type: string;
    content: string;
    show?: boolean;
    icon: IconProp;
}

interface AlertProps {
    content: string;
    show?: boolean;
}

class Alert extends Component<GenericAlertProps> {
    render() {
        // let show = (this.props.content.length > 0)
        let show = false;
        let exist = true;
        if (this.props.show != null) {
            show = this.props.show;
            exist = this.props.content != null && this.props.content.length > 0;
        } else {
            show = this.props.content != null && this.props.content.length > 0;
        }

        if (exist) {
            const clean = sanitizeHTML(this.props.content)
            return (
                <div
                    className={
                        "alert alert-" +
                        this.props.type +
                        (show ? " alert-visible" : " alert-hidden")
                    }
                    role="alert"
                >
                    <FontAwesomeIcon icon={this.props.icon} />
                    &nbsp;&nbsp;<span dangerouslySetInnerHTML={{__html:clean}} />
                </div>
            );
        } else {
            return <div />
        }
    }
}

export class WarningAlert extends Component<AlertProps> {
    render() {
        return (
            <Alert
                type="danger"
                icon="exclamation-circle"
                {...this.props}
            />
        );
    }
}

export class StatusAlert extends Component<AlertProps> {
    render() {
        return (
            <Alert
                type="secondary"
                icon="circle"
                {...this.props}
            />
        );
    }
}

export class SuccessAlert extends Component<AlertProps> {
    render() {
        return (
            <Alert
                type="success"
                icon="check-circle"
                {...this.props}
            />
        );
    }
}
