import React, { Component } from 'react';
import { CompProps } from '../Main';
import { fetchAPI, APIResp, formatMessageTimestamp } from '../utils';
import Loading from '../components/Loading';
import EmptyState from '../components/EmptyState';

import '../styles/Noticeboard.scss';
import { AppState } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

interface Notice {
    id: number;
    header: string;
    body: string;
    author: string;
    created: Date;
}

interface State {
    loading: boolean;
    notices: Notice[];
    admin: boolean;
}

class Noticeboard extends Component<CompProps & { dontSetTitle?: boolean }, State> {
    constructor(props: CompProps & { dontSetTitle?: boolean }) {
        super(props);
        console.log(this.props.dontSetTitle === undefined);
        if (this.props.dontSetTitle === undefined) this.props.setTitle('Noticeboard');

        this.state = {
            loading: true,
            notices: [],
            admin: false,
        };

        this.fetchData();
    }

    fetchData = () => {
        fetchAPI({ url: '/noticeboard/' }, this.updateState, this.props.appState);
    };

    updateState = (resp: APIResp) => {
        if (resp.data.ok) {
            this.setState(resp.data.data);
            this.setState({ loading: false });
        } else {
            this.props.setErrorMessage(
                'There was an error fetching the data from the server. Please try again later. ',
            );
        }
    };

    deleteNotice = (id: number) => {
        if (
            window.confirm(
                'Are you sure you want to delete this notice? This action cannot be reversed.',
            )
        ) {
            fetchAPI(
                { url: '/noticeboard/' + id, method: 'post' },
                this.fetchData,
                this.props.appState,
            );
        }
    };

    render() {
        let content = [];
        let notices = this.state.notices.map((notice) => (
            <CSSTransition key={notice.id} timeout={500} classNames="transition">
                <NoticeComponent
                    {...notice}
                    isAdmin={this.state.admin}
                    deleteNotice={this.deleteNotice}
                />
            </CSSTransition>
        ));
        if (this.state.admin) {
            content.push(
                <NoticeInputForm
                    key={1}
                    appState={this.props.appState}
                    setErrorMessage={this.props.setErrorMessage}
                    fetchData={this.fetchData}
                />,
            );
        }
        if (!this.state.loading && this.state.notices.length === 0) {
            if (this.state.admin) {
                // Show admin empty state
                content.push(
                    <EmptyState icon="pencil-alt" heading="No notices posted yet" key={2}>
                        You can post a notice using the form above.
                    </EmptyState>,
                );
            } else {
                // Show normal user admin state
                content.push(
                    <EmptyState icon="empty-set" heading="No notices posted yet" key={2} />,
                );
            }
        } else {
            content.push(
                <Loading loading={this.state.loading} key={3}>
                    <TransitionGroup id="noticeboard-notices">{notices}</TransitionGroup>
                </Loading>,
            );
        }
        return <div id="noticeboard">{content}</div>;
    }
}

interface NoticeInputFormProps {
    appState: AppState;
    fetchData(): void;
    setErrorMessage(msg: string): void;
}

class NoticeInputForm extends Component<
    NoticeInputFormProps,
    { header: string; body: string; author: string }
> {
    constructor(props: NoticeInputFormProps) {
        super(props);
        this.state = {
            header: '',
            body: '',
            author: '',
        };
    }

    updateHeader = (e: any) => {
        this.setState({ header: e.target.value });
    };

    updateBody = (e: any) => {
        this.setState({ body: e.target.value });
    };

    updateAuthor = (e: any) => {
        this.setState({ author: e.target.value });
    };

    submitForm = () => {
        if (this.state.body === '') {
            this.props.setErrorMessage('You need to key in some content for the notice.');
        }
        fetchAPI(
            { url: '/noticeboard/', method: 'post', payload: this.state },
            this.props.fetchData,
            this.props.appState,
        );
        this.setState({
            header: '',
            body: '',
            author: '',
        });
    };

    render() {
        return (
            <div className="notice-input-form">
                <input
                    type="text"
                    className="notice-input-form-header"
                    placeholder="Header"
                    value={this.state.header}
                    onChange={this.updateHeader}
                />
                <textarea
                    placeholder="Content"
                    value={this.state.body}
                    onChange={this.updateBody}
                />
                <input
                    type="text"
                    className="notice-input-form-author"
                    placeholder="Author Name"
                    value={this.state.author}
                    onChange={this.updateAuthor}
                />
                <button className="btn btn-primary" onClick={this.submitForm}>
                    Submit
                </button>
            </div>
        );
    }
}

const NoticeComponent: React.FC<Notice & { isAdmin: boolean; deleteNotice(id: number): void }> = (
    props,
) => {
    let postedBy = <span />;
    if (props.author !== '') {
        postedBy = <span>Posted by {props.author}, </span>;
    }
    return (
        <div className="notice">
            <div className="notice-header">{props.header}</div>
            <div className="notice-body">{props.body}</div>
            <div className="notice-footer">
                {postedBy}
                {formatMessageTimestamp(props.created.toString())}
            </div>
            {props.isAdmin && (
                <span className="notice-delete" onClick={() => props.deleteNotice(props.id)}>
                    <FontAwesomeIcon icon="times" />
                </span>
            )}
        </div>
    );
};

export default Noticeboard;
