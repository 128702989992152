import React, { Component } from "react";
import { printRoles, formatMoney, calcAmount } from "../../utils";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";
import { calObj } from "../../interfaces";
import { AppState } from "../../App";
import List from "../List";
import ListItem from "../ListItems/ListItem";

interface ItemProps {
    item: any;
    appState: AppState;
    onUpdate: any;
    state: SendInvoiceState;
}

interface invoiceItemState {
    selected: boolean;
    additionalHours: number;
}

class InvoiceItem extends Component<ItemProps> {
    render() {
        return (
            <ListItem
                event={this.props.item}
                btns={[]}
                eventType={"invoice-request"}
                appState={this.props.appState}
                onUpdate={this.props.onUpdate}
                state={this.props.state[this.props.item.id]}
            >
                <div>{printRoles(this.props.item, this.props.appState)}</div>
            </ListItem>
        );
    }
}

interface ItemsProps {
    appState: AppState;
    onUpdate: any;
    data: calObj[];
    state: SendInvoiceState;
}

class InvoiceItems extends Component<ItemsProps> {
    render() {
        return (
            <List
                {...this.props}
                hideTimeMarkers
                itemComponent={InvoiceItem}
                eventType="invoice-request"
                state={this.props.state}
                noEntries={<div />}
            />
        );
    }
}

export interface SendInvoiceProps {
    event: calObj;
}

interface SendInvoiceState {
    [id: number]: invoiceItemState;
}

class SendInvoiceModal extends Component<
    GenericModalProps & SendInvoiceProps,
    SendInvoiceState
> {
    constructor(props: GenericModalProps & SendInvoiceProps) {
        super(props);

        let invoiceState: SendInvoiceState = {};
        for (let i = 0; i < this.props.appState.events.length; i++) {
            let event = this.props.appState.events[i];
            // console.log(event);
            // console.log(event.statusID == 2);
            if (
                event.statusID === 2 &&
                event.practice.hash === this.props.event.practice.hash
            ) {
                let selected = event.id === this.props.event.id;
                invoiceState[event.id] = {
                    selected: selected,
                    additionalHours: 0
                };
            }
        }

        this.state = invoiceState;
    }

    updateInvoiceState = (id: string, state: invoiceItemState) => {
        this.setState({ [id]: state });
    };

    render() {
        let name = this.props.event.practice.name;

        // Only show jobs that are with the same practice as the one selected
        let jobs = [];
        let totalAmount = 0;

        for (let i = 0; i < this.props.appState.events.length; i++) {
            let event = this.props.appState.events[i];
            if (
                event.statusID === 2 &&
                event.practice.hash === this.props.event.practice.hash
            ) {
                jobs.push(event);
                // console.log(this.state[event.id]);
                if (
                    this.state[event.id] != null &&
                    this.state[event.id].selected
                ) {
                    let additionalHours = parseFloat(
                        this.state[event.id].additionalHours.toString()
                    );
                    if (isNaN(additionalHours)) {
                        additionalHours = 0;
                    }
                    event.additionalTime = additionalHours;
                    let amount = calcAmount(event);
                    totalAmount += amount;
                }
            }
        }

        let details = [];
        for (let id in this.state) {
            let job = this.state[id];
            if (job.selected) {
                details.push({
                    id: parseInt(id),
                    additionalHours: job.additionalHours
                });
            }
        }
        let requestParams = {
            url: "/invoices/",
            payload: {
                details: details
            },
            method: "post"
        };

        return (
            <GenericModal
                {...this.props}
                title={"Send Invoice to " + name}
                yesBtn="Send"
                noBtn="Cancel"
                requestParams={requestParams}
                successMessage={"Invoice sent to " + name + "."}
                size="lg"
            >
                Select which jobs you would like to invoice together:
                <br />
                <InvoiceItems
                    data={jobs}
                    appState={this.props.appState}
                    onUpdate={this.updateInvoiceState}
                    state={this.state}
                />
                <br />
                Total amount: <b>{formatMoney(totalAmount)}</b>
            </GenericModal>
        );
    }
}

export default SendInvoiceModal;
