import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class BackBtn extends Component<{changeStatus(status: string, type: string, title: string): void}> {
    render() {
        var changeStatus = this.props.changeStatus;
        return (
            <button className='btn btn-light' id='back-btn' onClick={() => changeStatus('', '', '')}>
                <FontAwesomeIcon icon="long-arrow-left" /> &nbsp; Back
            </button>
        )
    }
}

export default BackBtn;

