import React, { Component } from "react";
import { CompProps } from "../Main";
import { fetchAPI, APIResp } from "../utils";
import EmptyState from "./EmptyState";

interface State {
    answerDoBetter: string;
    answerLike: string;
    answerComments: string;
    sendingFeedback: boolean;
    feedbackSent: boolean;
}

class FeedbackQuestion extends Component<
    { sendingFeedback: boolean; callback(val: string): void; children?: any; },
    { answer: string }
> {
    constructor(props: {
        sendingFeedback: boolean;
        callback(val: string): void;
    }) {
        super(props);
        this.state = {
            answer: ""
        };
    }

    updateAnswer = (e: any) => {
        this.setState({ answer: e.target.value });
        this.props.callback(e.target.value);
    };

    render() {
        return (
            <div>
                <div>{this.props.children}</div>
                <textarea
                    disabled={this.props.sendingFeedback}
                    className="form-control"
                    style={{ marginTop: "8px", marginBottom: "20px" }}
                    value={this.state.answer}
                    onChange={this.updateAnswer}
                />
            </div>
        );
    }
}

export class Feedback extends Component<CompProps, State> {
    constructor(props: CompProps) {
        super(props);
        this.props.setTitle("Feedback");
        this.state = {
            answerDoBetter: "",
            answerLike: "",
            answerComments: "",
            sendingFeedback: false,
            feedbackSent: false
        };
    }

    updateState = (
        key: "answerDoBetter" | "answerLike" | "answerComments",
        value: string
    ) => {
        switch (key) {
            case "answerDoBetter":
                this.setState({ answerDoBetter: value });
                break;
            case "answerLike":
                this.setState({ answerLike: value });
                break;
            case "answerComments":
                this.setState({ answerComments: value });
                break;
        }
    };

    sendForm = () => {
        this.setState({ sendingFeedback: true });
        fetchAPI(
            { url: "/feedback", method: "post", payload: this.state },
            (resp: APIResp) => {
                if (resp.data.ok) {
                    this.setState({
                        sendingFeedback: false,
                        feedbackSent: true
                    });
                } else {
                    // Handle error
                    this.props.setErrorMessage(resp.data.error);
                }
            },
            this.props.appState
        );
    };

    render() {
        if (this.state.feedbackSent) {
            return (
                <div>
                    <EmptyState
                        icon="envelope"
                        heading="Thank you for your feedback. Your feedback has been submitted."
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <FeedbackQuestion
                        sendingFeedback={this.state.sendingFeedback}
                        callback={val =>
                            this.updateState("answerDoBetter", val)
                        }
                    >
                        1. Is there something we could do better on the
                        platform?
                    </FeedbackQuestion>
                    <FeedbackQuestion
                        sendingFeedback={this.state.sendingFeedback}
                        callback={val => this.updateState("answerLike", val)}
                    >
                        2. Is there something that you particularly like about
                        the platform?
                    </FeedbackQuestion>
                    <FeedbackQuestion
                        sendingFeedback={this.state.sendingFeedback}
                        callback={val =>
                            this.updateState("answerComments", val)
                        }
                    >
                        3. Any other comments?
                    </FeedbackQuestion>
                    <button className="btn btn-primary" onClick={this.sendForm}>
                        Send Feedback
                    </button>
                </div>
            );
        }
    }
}
