import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/pro-solid-svg-icons";

const EmptyState = (props: { icon: IconName; heading?: string; children?: any }) => {
    return (
        <div className="no-entries-found">
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={"circle"} size="4x" />
                <FontAwesomeIcon
                    icon={["far", props.icon]}
                    size={"2x"}
                    className="fa-inverse"
                />
            </span>
            <div className="no-entries-blurb">
                <div className="no-entries-header">{props.heading}</div>
                {props.children}
            </div>
        </div>
    );
};

export const NoUserFound = () => {
    return (
        <EmptyState icon="user-slash">
            Sorry, we could not find the user requested.
        </EmptyState>
    );
};

export default EmptyState;
