import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

import '../../styles/CookieModal.scss';
import { NavLink } from 'react-router-dom';

import Cookies from 'universal-cookie';
import BackBtn from '../../landing-page/BackBtn';
import { AppState } from '../../App';
const cookies = new Cookies();

interface CookieModalProps {
	changeStatus: any;
	show?: boolean;
	closeModal?(): void;
	appState: AppState;
}

enum cookieTypes {
	essential = 'essentialCookies',
	rmbMe = 'rmbMeCookies',
	analytics = 'analyticsCookies',
	acceptableUse = 'acceptableUse',
	termsOfUse = 'termsOfUse',
	privacy = 'privacy'
}

export interface CookieModalState {
	[key: string]: boolean;
	show: boolean;
}

const ModalRow = (props: {
	name: string;
	cookieKey: cookieTypes;
	cookieState: CookieModalState;
	updateState(key: cookieTypes): void;
	children: any;
}) => {
	return (
		<tr>
			<td>
				<input
					type="checkbox"
					id={props.cookieKey}
					checked={props.cookieState[props.cookieKey]}
					onChange={() => props.updateState(props.cookieKey)}
				/>
			</td>
			<td>
				<label htmlFor={props.cookieKey}>
					<b>{props.name}</b>
					<br />
					{props.children}
				</label>
			</td>
		</tr>
	);
};

class CookieModal extends Component<CookieModalProps, CookieModalState> {
	constructor(props: CookieModalProps) {
		super(props);
		let permissions = cookies.get('permissions');
		let state = {
			essentialCookies: false,
			rmbMeCookies: false,
			analyticsCookies: false,
			show: permissions == null, // display if permissions not saved before
			acceptableUse: false,
			termsOfUse: false,
			privacy: false
		};
		if (permissions != null) {
			state.essentialCookies = permissions.essentialCookies;
			state.rmbMeCookies = permissions.rmbMeCookies;
			state.analyticsCookies = permissions.analyticsCookies;
		}
		this.state = state;
	}

	updateState = (key: cookieTypes) => {
		this.setState({ [key]: !this.state[key] });
	};

	saveCookieDetails = (cookiesSet: CookieModalState) => {
		// setState to hide
		// save the cookies
		this.setState({ show: false });
		if (this.props.closeModal != null) {
			this.props.closeModal();
		}
		this.props.appState.setCookiePermissions(cookiesSet);
	};

	render() {
		const cookiePolicy = <NavLink to="/cookies">Cookie Policy</NavLink>;
		let btnDisabled = !this.state.essentialCookies;
		if (this.props.show == null) {
			if (!(this.state.acceptableUse && this.state.termsOfUse && this.state.privacy)) {
				btnDisabled = true;
			}
		}
		return (
			<Modal show={this.state.show || this.props.show} className="cookie-modal">
				<Modal.Header>Set Your Cookies Preferences</Modal.Header>
				<Modal.Body>
					This site uses cookies. Please select or deselect below which cookies you allow us to store on your
					browser. More information on the cookies can be found at our {cookiePolicy}.
					<table id="cookie-modal-table">
						<tbody>
							<ModalRow
								name="Essential"
								cookieKey={cookieTypes.essential}
								cookieState={this.state}
								updateState={this.updateState}
							>
								These are used to keep track of your login session, and must be selected for you to use
								the site.
							</ModalRow>
							<ModalRow
								name="&quot;Remember Me&quot; cookies"
								cookieKey={cookieTypes.rmbMe}
								cookieState={this.state}
								updateState={this.updateState}
							>
								These are created when you select 'Remember Me', and are used to identify you.
							</ModalRow>
							<ModalRow
								name="Analytics cookies"
								cookieKey={cookieTypes.analytics}
								cookieState={this.state}
								updateState={this.updateState}
							>
								These are created by Google Analytics, and used to collate aggregated usage behaviour of
								the site and allow us to improve the user experience.
							</ModalRow>
						</tbody>
					</table>
					{this.props.show == null && <div>
						<p>
							Please also note that you must read and accept the following documents in order to use the
							site:
						</p>
						<table>
							<tbody>
								<tr>
									<td>
										<input
											type="checkbox"
											checked={this.state.acceptableUse}
											onChange={() => this.updateState(cookieTypes.acceptableUse)}
										/>
									</td>
									<td>
										<NavLink to="/acceptable-use">Acceptable Use Policy</NavLink>
									</td>
								</tr>
								<tr>
									<td>
										<input
											type="checkbox"
											checked={this.state.termsOfUse}
											onChange={() => this.updateState(cookieTypes.termsOfUse)}
										/>
									</td>
									<td>
										<NavLink to="/terms-of-use">Terms of Use</NavLink>
									</td>
								</tr>
								<tr>
									<td>
										<input
											type="checkbox"
											checked={this.state.privacy}
											onChange={() => this.updateState(cookieTypes.privacy)}
										/>
									</td>
									<td>
										<NavLink to="/privacy">Privacy Policy</NavLink>
									</td>
								</tr>
							</tbody>
						</table>
					</div>}
				</Modal.Body>
				<Modal.Footer>
					{/* We only show the BackBtn if it is not 'shown' (that is, it is a forced modal) */}
					{!this.props.show && <BackBtn changeStatus={this.props.changeStatus} />}
					{this.props.show && (
						<button className="btn btn-secondary" onClick={this.props.closeModal}>
							Cancel
						</button>
					)}
					<button
						disabled={btnDisabled}
						type="button"
						className="btn btn-primary"
						onClick={() => {
							this.saveCookieDetails(this.state);
						}}
					>
						Save
					</button>
				</Modal.Footer>
			</Modal>
		);
	}
}

export default CookieModal;
