import React from "react";
import "../../styles/LegalDoc.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps, withRouter } from "react-router";

const LegalDoc = (props: { title: string; children: any; } & RouteComponentProps<{}>) => {

    let goBack = () => {
        props.history.goBack();
    };

    return (
        <div className="legal-doc">
            <button
                id="back-btn"
                className="btn btn-light"
                onClick={goBack}
            >
                <FontAwesomeIcon icon="long-arrow-left" fixedWidth />&nbsp;&nbsp;
                Back
            </button>
            <div>
                <h1>{props.title}</h1>
                {props.children}
            </div>
        </div>
    );
};

export default withRouter(LegalDoc);
