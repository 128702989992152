import { Modal } from "react-bootstrap";

import React, { Component } from "react";
import { AppState } from "../../App";

import "../../styles/MessageModal.scss";
import {
    formatJobTimestamp,
    formatMessageTimestamp,
    getOtherType,
    fetchAPI,
    APIResp
} from "../../utils";
import { WarningAlert, StatusAlert } from "../../Alert";
import { ModalProps } from "./Modals";
import { calObj } from "../../interfaces";

interface MessageModalState {
    errorMessage: string;
    statusMessage: string;
    message: string;
}

function messageContext(
    event: calObj,
    eventType: string,
    appState: AppState
): string {
    let context = "";

    // Salutation
    if (appState.userType === "doctor") {
        context += "Dear " + event.practice.contactName + ", ";
    } else if (appState.userType === "practice") {
        context += "Dear " + event.doctor.name + ", ";
    }

    context += "regarding " + eventType;

    if (eventType === "the invoice") {
        context +=
            " sent on " + formatMessageTimestamp(event.date.toString()) + ",";
    } else {
        context += " for " + formatJobTimestamp(event) + ",";
    }

    return context;
}

class MessageModal extends Component<ModalProps, MessageModalState> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
            errorMessage: "",
            statusMessage: "",
            message: ""
        };
    }

    hideModal = () => {
        this.setState({ message: "", errorMessage: "", statusMessage: "" });
        this.props.closeModal();
    };

    updateState = (resp: APIResp) => {
        this.setState({ statusMessage: "" });
        if (resp.data.ok) {
            // dismiss modal
            // show success alert
            this.hideModal();
            this.props.onSuccess("Your message has been sent.");
            this.props.appState.updateUICounts(resp.data);
        } else {
            this.setState({ errorMessage: resp.data.error });
        }
    };

    sendMessage = (e: any) => {
        e.preventDefault();
        this.setState({ errorMessage: "", statusMessage: "" });

        if (this.state.message.length === 0) {
            this.setState({ errorMessage: "Message content cannot be empty." });
            return;
        }
        this.setState({ statusMessage: "Sending message..." });

        let otherType = getOtherType(this.props.appState.userType);
        let recipientHash = this.props.event[otherType].hash;

        let payload = {
            recipientHash: recipientHash,
            context: messageContext(
                this.props.event,
                this.props.eventType,
                this.props.appState
            ),
            content: this.state.message
        };

        fetchAPI(
            { url: "/messages/", payload: payload, method: "post" },
            this.updateState,
            this.props.appState
        );
    };

    updateMessage = (e: any) => {
        this.setState({ message: e.target.value });
    };

    render() {
        let name = this.props.event[getOtherType(this.props.appState.userType)]
            .name;
        if (this.props.event !== null) {
            return (
                <Modal show={this.props.show} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        Send message to {name}
                    </Modal.Header>
                    <form onSubmit={this.sendMessage}>
                        <Modal.Body>
                            <WarningAlert content={this.state.errorMessage} />
                            <StatusAlert content={this.state.statusMessage} />
                            <div className="message-context">
                                {messageContext(
                                    this.props.event,
                                    this.props.eventType,
                                    this.props.appState
                                )}
                            </div>
                            <div>
                                <textarea
                                    className="form-control"
                                    value={this.state.message}
                                    onChange={this.updateMessage}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.hideModal}
                            >
                                Cancel
                            </button>
                            <input
                                type="submit"
                                className="btn btn-primary"
                                value="Send Message"
                                onClick={this.sendMessage}
                            />
                        </Modal.Footer>
                    </form>
                </Modal>
            );
        } else {
            return <div />;
        }
    }
}

export default MessageModal;
