import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../styles/InfoButton.scss";

interface Props {
    text: string;
}

interface State {
    showing: boolean;
}

class InfoButton extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showing: false
        };
    }

    toggleShowing = () => {
        this.setState({ showing: !this.state.showing });
    };

    render() {
        return (
            <span className="info-button">
                <span onClick={this.toggleShowing}>
                    <FontAwesomeIcon icon={["fas", "info-circle"]} />
                </span>
                <div
                    className={
                        "info-button-text" +
                        (this.state.showing ? " info-button-text-showing" : "")
                    }
                >
                    {this.props.text}
                </div>
            </span>
        );
    }
}

export default InfoButton;
