import React, { Component } from "react";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";

class RetractInvoiceModal extends Component<GenericModalProps> {

    render() {
        let name = this.props.event.practice.name;
        let requestParams = {
            url: "/invoices/id/" + this.props.event.id + '/delete',
            method: 'post'
        }

        return (
            <GenericModal
                {...this.props}
                title={"Retracting your invoice"}
                yesBtn="Retract"
                noBtn="No"
                requestParams={requestParams}
                successMessage={
                    "Your invoice to " + name + " has been retracted."
                }
            >
                Are you sure you want to retract your invoice to {name}?
            </GenericModal>
        );
    }
}

export default RetractInvoiceModal;
