/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { Component } from 'react';
import { Navbar, Sidebar } from './Navigation';

import './styles/Main.scss';
import { Switch, Route, RouteComponentProps } from 'react-router';

import { AppState } from './App';
import Dashboard from './pages/Dashboard';
import { MyAvailabilities, MyAvailableSessions } from './pages/Availabilities';
import { JobNegos } from './pages/JobNegos';
import { SuccessAlert, WarningAlert } from './Alert';
import Calendar from './components/Calendar';
import Bookings from './pages/Bookings';
import Profiles from './components/Profiles';
import ViewProfile from './components/ViewProfile';
import Messages from './components/Messages';
import ViewMessages from './components/ViewMessages';
import Invoices from './components/Invoices';
import EditProfile from './components/EditProfile';
import { NavLink } from 'react-router-dom';
import { APIResp, fetchAPI } from './utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvailableSessions } from './components/AvailableSessions';
import Noticeboard from './pages/Noticeboard';
import { Feedback } from './components/Feedback';

interface MainState {
	title: string;
	showBackBtn: boolean | null | undefined;
	successMessage: string;
	successMessageTimeout: any;
	successMessageShow: boolean;
	errorMessage: string;
	errorMessageTimeout: any;
	errorMessageShow: boolean;
}

export interface CompProps {
	appState: AppState;
	setTitle: any;
	setSuccessMessage: any;
	setErrorMessage: any;
}

interface MainProps extends RouteComponentProps<{}> {
	appState: AppState;
}

class Main extends Component<MainProps, MainState> {
	constructor(props: MainProps) {
		super(props);
		this.state = {
			title: '',
			successMessage: '',
			successMessageTimeout: null,
			successMessageShow: false,
			errorMessage: '',
			errorMessageTimeout: null,
			errorMessageShow: false,
			showBackBtn: false
		};
	}

	setTitle = (title: string, showBackBtn?: boolean) => {
		this.setState({ title: title, showBackBtn: showBackBtn });
		document.title = title + ' - Versatile GP';
	};

	hideSuccessMessage = () => {
		this.setState({ successMessageShow: false });
	};

	setSuccessMessage = (message: string) => {
		clearTimeout(this.state.successMessageTimeout);
		setTimeout(() => {
			this.setState({
				successMessage: message,
				successMessageShow: true,
				successMessageTimeout: setTimeout(this.hideSuccessMessage, 4000)
			});
		}, 600);
	};

	setErrorMessage = (message: string) => {
		clearTimeout(this.state.errorMessageTimeout);
		setTimeout(() => {
			this.setState({
				errorMessage: message,
				errorMessageShow: true,
				errorMessageTimeout: setTimeout(() => this.setState({errorMessageShow: false}), 4000)
			});
		}, 600);
	};

	resendVerificationUpdate = (resp: APIResp) => {
		if (resp.data.ok) {
			this.setSuccessMessage('Verification email has been resent.');
		} else {
			this.setState({
				errorMessage: 'Sorry, there was a problem with the system. Please try again later.'
			});
		}
	};

	resendVerification = () => {
		fetchAPI(
			{ url: '/auth/resend-verify-email', method: 'post' },
			this.resendVerificationUpdate,
			this.props.appState
		);
	};

	goBack = () => {
		this.props.history.goBack();
	};

	render() {
		if (!this.props.appState.userVerified && !window.location.pathname.startsWith('/edit-profile')) {
			// Show request to verify account
			let privacySettingsBlurb = '';
			if (this.props.appState.userType === 'doctor') {
				privacySettingsBlurb =
					'which practices are able to view your profile and your posted availabilities. Please note that if you hide your profile (and availabilities) from a particular practice, you will not be able to see their requested sessions as well.';
			} else if (this.props.appState.userType === 'practice') {
				privacySettingsBlurb = 'which locums are able to view your available sessions.';
			}
			return (
				<div>
					<div id="main-content">
						<Navbar appState={this.props.appState} />
						<WarningAlert content={this.state.errorMessage} />

						<div className="system-alert">
							<SuccessAlert content={this.state.successMessage} show={this.state.successMessageShow} />
						</div>
						<div className="not-verified">
							<FontAwesomeIcon icon={[ 'fal', 'door-open' ]} size="6x" />
							<h1>Welcome to Versatile GP!</h1>
							<p>Thank you for registering with Versatile GP. </p>
							<p>
								Please take a moment to review your{' '}
								<b>
									<NavLink to="/edit-profile/privacy">privacy settings</NavLink>
								</b>, as it will affect {privacySettingsBlurb}
							</p>
							<p>
								We have also sent you a <b>verification email</b> and you need to click on the link in
								the email to complete the sign up process. It may take a few minutes for the email to
								reach your inbox. You may also want to check your spam folder.
							</p>
							<p>
								If you have not received it yet, we can{' '}
								<a onClick={this.resendVerification} href="#">
									send you another email
								</a>
								. Please note that this will invalid the link in the previous email. You may also want
								to{' '}
								<NavLink to="/edit-profile/details">
									double-check if you keyed in your email correctly
								</NavLink>
								.
							</p>
						</div>
					</div>
				</div>
			);
		}

		let componentProps = {
			appState: this.props.appState,
			setTitle: this.setTitle,
			setSuccessMessage: this.setSuccessMessage, 
			setErrorMessage: this.setErrorMessage, 
		};

		return (
			<div>
				{this.props.appState.userVerified && <Sidebar appState={this.props.appState} />}
				<div id="main-content">
					<Navbar appState={this.props.appState} />

					{this.state.showBackBtn && (
						<button id="back-btn" className="btn btn-light" onClick={this.goBack}>
							<FontAwesomeIcon icon="long-arrow-left" fixedWidth />
						</button>
					)}

					<h1>{this.state.title}</h1>
					<div className="system-alert">
						<SuccessAlert content={this.state.successMessage} show={this.state.successMessageShow} />
						<WarningAlert content={this.state.errorMessage} show={this.state.errorMessageShow} />
					</div>
					<Switch>
						<Route path="/dashboard" render={() => <Dashboard {...componentProps} />} />
						<Route path="/calendar" render={() => <Calendar {...componentProps} />} />
						<Route path="/my-availabilities" render={() => <MyAvailabilities {...componentProps} />} />
						<Route
							path="/my-available-sessions"
							render={() => <MyAvailableSessions {...componentProps} />}
						/>
						<Route
							path="/applications"
							render={() => <JobNegos {...componentProps} type="applications" />}
						/>
						<Route path="/offers" render={() => <JobNegos {...componentProps} type="offers" />} />
						<Route path="/bookings" render={() => <Bookings {...componentProps} />} />
						<Route path="/invoices" render={() => <Invoices {...componentProps} />} />
						<Route path="/gp-profiles" render={() => <Profiles {...componentProps} />} />
						<Route path="/locum-profiles" render={() => <Profiles {...componentProps} />} />
						<Route path="/available-sessions" render={() => <AvailableSessions {...componentProps} />} />
						<Route path="/listed-availabilities" render={() => <AvailableSessions {...componentProps} />} />
						<Route path="/noticeboard" render={() => <Noticeboard {...componentProps} />} />
						<Route path="/feedback" render={() => <Feedback {...componentProps} />} />

						<Route
							path="/view-profile/:userHash"
							render={(match) => <ViewProfile {...match} {...componentProps} />}
						/>

						<Route path="/messages" exact render={() => <Messages {...componentProps} />} />

						<Route
							path="/messages/:userHash"
							render={(match) => <ViewMessages {...match} {...componentProps} />}
						/>

						<Route path="/edit-profile" render={() => <EditProfile {...componentProps} />} />
					</Switch>
				</div>
			</div>
		);
	}
}

export default Main;
