import React, { Component } from "react";
import ListItem from "./ListItem";
import { AppState } from "../../App";

import List from "../List";
import {
    MessageBtn,
    ViewInvoiceBtn,
    MarkPaidBtn,
    RejectInvoiceBtn,
    RetractInvoiceBtn
} from "../Buttons";
import EmptyState from "../EmptyState";
import { NavLink } from "react-router-dom";

interface InvoiceListItemProps {
    item: any;
    appState: AppState;
    onUpdate: any;
}

class InvoiceListItem extends Component<InvoiceListItemProps> {
    render() {
        let btns = [];

        let btnProps = {
            appState: this.props.appState,
            event: this.props.item,
            onSuccess: this.props.onUpdate
        };

        if (this.props.item.status === 0) {
            if (this.props.appState.userType === "practice") {
                btns.push([
                    <MarkPaidBtn {...btnProps} key={"mark-complete"} />,
                    <RejectInvoiceBtn {...btnProps} key={"reject"} />
                ]);
            } else if (this.props.appState.userType === "doctor") {
                btns.push([
                    <MarkPaidBtn {...btnProps} key={"mark-complete"} />,
                    <RetractInvoiceBtn {...btnProps} key={"retract"} />
                ]);
            }
        }

        btns.push([
            <ViewInvoiceBtn
                appState={this.props.appState}
                event={this.props.item}
                onSuccess={() => {
                    this.props.onUpdate(
                        "Invoice has been fetched. If it didn't work, please disable any adblocker you may have."
                    );
                }}
                key={"view-invoice"}
            />,
            <MessageBtn
                appState={this.props.appState}
                event={this.props.item}
                onSuccess={() => {
                    this.props.onUpdate("Your message has been sent.");
                }}
                key={"message"}
                eventType={"the invoice"}
            />
        ]);

        let numEntries = this.props.item.entries.length;

        let matchesText = "";
        if (numEntries === 1) {
            matchesText = "1 entry";
        } else {
            matchesText = numEntries.toString() + " entries";
        }
        matchesText += " (click to expand)";
        let jobs = <div className="matches-found-text">{matchesText}</div>;

        return (
            <ListItem
                event={this.props.item}
                btns={btns}
                eventType={"invoice"}
                matches={this.props.item.entries}
                appState={this.props.appState}
                onUpdate={this.props.onUpdate}
            >
                {this.props.item.statusStr}
                {jobs}
            </ListItem>
        );
    }
}

interface InvoiceListItemsProps {
    data: any;
    appState: AppState;
    onUpdate: any;
}

const NoInvoicesFound: React.FC<{
    userType: string;
}> = props => {
    let header = "No invoices found";
    let noEntriesText = <span />;
    if (props.userType === "doctor") {
        noEntriesText = (
            <span>
                You can send invoices by going to{" "}
                <NavLink to="/bookings/invoice-not-yet-sent">
                    “Invoice not yet sent” tab on the “Confirmed Bookings” page
                </NavLink>
                .
            </span>
        );
    }
    return (
        <EmptyState icon="empty-set" heading={header}>
            {noEntriesText}
        </EmptyState>
    );
};

class InvoiceListItems extends Component<InvoiceListItemsProps> {
    render() {
        return (
            <List
                {...this.props}
                itemComponent={InvoiceListItem}
                eventType={"invoice"}
                noEntries={
                    <NoInvoicesFound userType={this.props.appState.userType} />
                }
            />
        );
    }
}

export default InvoiceListItems;
