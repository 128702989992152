import React, { Component } from "react";
import ListItem from "./ListItem";
import { EditBtn, DeleteBtn } from "../Buttons";
import { AppState } from "../../App";
import { printRoles } from "../../utils";

import List from "../List";
import EmptyState from "../EmptyState";

interface AvailListItemsProps {
    data: any;
    appState: AppState;
    onUpdate: any;
}

interface AvailListItemProps {
    item: any;
    appState: AppState;
    onUpdate: any;
    inAgenda?: boolean;
}

export class AvailabilityListItem extends Component<AvailListItemProps> {
    render() {
        let btns = [
            <EditBtn
                appState={this.props.appState}
                onSuccess={this.props.onUpdate}
                event={this.props.item.availability}
                key={"edit-btn"}
            />,
            <DeleteBtn
                appState={this.props.appState}
                onSuccess={this.props.onUpdate}
                event={this.props.item.availability}
                key={"delete-btn"}
            />
        ];

        let matches = <div />;
        let matchesList;

        if (this.props.item.offers != null) {
            let numMatches = this.props.item.offers.length;

            if (numMatches === 0) {
                matches = (
                    <div className="matches-found-text no-matches-found">
                        No matches found
                    </div>
                );
            } else {
                let matchesText = "";
                if (numMatches === 1) {
                    matchesText = "1 match found";
                } else {
                    matchesText = numMatches.toString() + " matches found";
                }
                matchesText += " (click to view)";
                matches = (
                    <div className="matches-found-text">{matchesText}</div>
                );
                matchesList = this.props.item.offers;
            }
        }

        let eventType = "availability";
        if (this.props.inAgenda) {
            eventType = "agenda-availability";
        }

        return (
            <ListItem
                event={this.props.item.availability}
                btns={btns}
                eventType={eventType}
                matches={matchesList}
                appState={this.props.appState}
                onUpdate={this.props.onUpdate}
            >
                <div>
                    {printRoles(
                        this.props.item.availability,
                        this.props.appState
                    )}
                </div>
                {matches}
            </ListItem>
        );
    }
}

const NoAvailabilities: React.FunctionComponent<{}> = () => {
    return (
        <EmptyState icon="calendar-plus" heading="No availabilities found">
            You can create a new availability by clicking on the button above.
        </EmptyState>
    );
};

const NoAvailableSessions: React.FunctionComponent<{}> = () => {
    return (
        <EmptyState icon="calendar-plus" heading="No available sessions found">
            You can add an available session by clicking on the button
            above.
        </EmptyState>
    );
};

class AvailabilityListItems extends Component<AvailListItemsProps> {
    render() {
        let noEntries = <div />;
        if (this.props.appState.userType === "doctor") {
            noEntries = <NoAvailabilities />;
        } else if (this.props.appState.userType === "practice") {
            noEntries = <NoAvailableSessions />;
        }
        return (
            <List
                {...this.props}
                itemComponent={AvailabilityListItem}
                eventType={"availability"}
                noEntries={noEntries}
            />
        );
    }
}

export default AvailabilityListItems;
