import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { WarningAlert, StatusAlert } from '../Alert';
import DatePicker from 'react-datepicker';
import StartEndTimeSelect from './JobEntryForm/TimeSelect';
import RateField from './JobEntryForm/RateField';
import { RolesInput } from './Roles';
import { AppState } from '../App';
import { APIParameters, fetchAPI, APIResp, formatJobTimestampList } from '../utils';
import { calObj } from '../interfaces';
import { isWeekend, format } from 'date-fns';
import RateCalculations from './JobEntryForm/RateCalculations';

interface FormProps {
    appState: AppState;
    show: boolean;
    title: string;
    job: calObj;
    closeModal: any;
    onSuccess: any;
    api: string;

    proposeEdit?: boolean;
    children?: any;
}

interface FormState {
    job: calObj;
    errorMessage: string;
    statusMessage: string;
    checkingClash: boolean;
    haveClash: boolean;
}

class JobEntryForm extends Component<FormProps, FormState> {
    constructor(props: FormProps) {
        super(props);
        let job = this.props.job;
        // We have to convert string to date object if need be
        if (typeof job.date == 'string') {
            job.date = new Date(job.date);
        }
        this.state = {
            job: job,
            errorMessage: '',
            statusMessage: '',
            checkingClash: this.props.appState.userType === 'practice', // only practices need to asynchronoously check for clashes
            haveClash: false,
        };
    }

    componentDidMount() {
        if (this.props.appState.userType === 'practice') {
            this.checkForClashes();
        }
    }

    updateJob = (key: string, value: any) => {
        let job = this.state.job;
        let checkForClash = false;
        switch (key) {
            case 'date':
                job.date = value;
                checkForClash = true;
                break;
            case 'startTime':
                job.startTime = value;
                checkForClash = true;
                break;
            case 'endTime':
                job.endTime = value;
                checkForClash = true;
                break;
            case 'rate':
                job.rate = value;
                break;
            case 'roles':
                job.roles = value;
                break;
            case 'fixedRate':
                job.fixedRate = value;
                break;
        }
        this.setState({ job: job }, () => {
            if (checkForClash) {
                this.checkForClashes();
            }
        });
    };

    hideModal = () => {
        this.setState({ errorMessage: '', statusMessage: '' });
        this.props.closeModal();
    };

    isWeekday(date: Date): boolean {
        return !isWeekend(date);
    }

    updateDate = (date: Date) => {
        this.updateJob('date', new Date(format(date, 'yyyy-MM-dd')));
    };

    updateTimes = (startTime: number, endTime: number) => {
        this.updateJob('startTime', startTime);
        this.updateJob('endTime', endTime);
    };

    updateRate = (e: any) => {
        this.updateJob('rate', parseFloat(e.target.value));
    };

    updateRateType = (e: any) => {
        const fixedRate = e.target.value === 'fixed';
        this.updateJob('fixedRate', fixedRate);
        const duration = (this.state.job.endTime - this.state.job.startTime) / 60;
        let newRate = this.state.job.rate;
        if (fixedRate) {
            newRate *= duration;
        } else {
            newRate /= duration;
        }
        this.updateJob('rate', Math.round(newRate * 100) / 100);
    };

    updateRoles = (roles: number[]) => {
        this.updateJob('roles', roles);
    };

    checkForClashes = () => {
        this.setState({
            haveClash: true, // we set to true, so that the practice cannot send an offer until we have confirmed that there are no clashes
        });

        fetchAPI(
            {
                url: '/bookings/check-clash',
                method: 'post',
                payload: this.state.job,
            },
            (resp: APIResp) => {
                let errorMessage = resp.data.error;
                let haveClash = false;

                console.log(resp.data);

                if (resp.data.ok) {
                    let clashes = resp.data.data;
                    let isDoctor = this.props.appState.userType === 'doctor';

                    if (clashes != null && clashes.length > 0) {
                        // Format the error message (list out all the clashes)
                        let numJobs: string;
                        let calObjName: string;
                        if (clashes.length === 1) {
                            numJobs = 'a confirmed job';
                        } else {
                            numJobs = 'confirmed jobs';
                        }

                        if (isDoctor) {
                            errorMessage = 'You already have ' + numJobs + ' on ';
                            calObjName = 'availability';
                        } else {
                            errorMessage =
                                'Sorry, ' +
                                this.state.job.doctor.name +
                                ' already has ' +
                                numJobs +
                                ' with you on ';
                            calObjName = 'offer';
                        }

                        errorMessage +=
                            formatJobTimestampList(clashes, isDoctor) +
                            '. Please either alter the hours of your ' +
                            calObjName +
                            ' below, or cancel your confirmed job';
                        if (clashes.length > 1) {
                            errorMessage += 's';
                        }
                        errorMessage += '.';

                        haveClash = true;
                    }
                }

                this.setState({
                    checkingClash: false,
                    errorMessage: errorMessage,
                    haveClash: haveClash,
                });
            },
            this.props.appState,
        );
    };

    sendRequest = (e: any) => {
        e.preventDefault();

        if (this.state.job.date == null) {
            this.setState({ errorMessage: 'Please select a valid date.' });
            return;
        }

        if (!(this.state.job.rate > 0)) {
            // Cannot
            this.setState({ errorMessage: 'Please key in the rate.' });
            return;
        }
        if (this.state.job.roles.length === 0) {
            // Cannot
            this.setState({
                errorMessage: 'Please select at least one job role.',
            });
            return;
        }

        this.setState({ errorMessage: '' });
        // let job = Object.assign({}, this.state.job);
        let job = JSON.parse(JSON.stringify(this.state.job));

        if (this.state.job.eventType != null && this.state.job.eventType === 'match') {
            job.id = 0;
        }

        let url = this.props.api;
        if (url.slice(-1) !== '/') {
            url = url + '/';
        }

        if (job.id === 0 || url === '/offers/') {
            // we need to specify this because offers may be sent in response to availabilities. then there will be an id.
            // New job
            url += 'create/';
        } else {
            // Edit job
            url += 'edit/';
        }

        let params: APIParameters = { url: url, payload: job, method: 'post' };

        fetchAPI(params, this.handlePostSubmission, this.props.appState);
    };

    handlePostSubmission = (resp: APIResp) => {
        if (resp.data.ok) {
            this.hideModal();
            this.props.onSuccess();
            this.setState({ job: this.props.job });
            this.props.appState.updateUICounts(resp.data);
        } else {
            let errorMessage = 'Could not update the server. ';
            if (this.props.proposeEdit) {
                errorMessage += 'Did you forget to change the booking details before submitting?';
            }
            this.setState({ errorMessage: errorMessage });
        }
    };

    render() {
        let date = this.state.job.date;
        if (typeof date == 'string') {
            date = new Date(date); // for some strange bug where date becomes a string
        }

        return (
            <Modal show={this.props.show} onHide={this.hideModal}>
                <Modal.Header closeButton>{this.props.title}</Modal.Header>
                <form onSubmit={this.sendRequest}>
                    <Modal.Body>
                        <StatusAlert content={this.state.statusMessage} />
                        <WarningAlert content={this.state.errorMessage} />
                        {/* {this.props.children} */}
                        <div className="form-group">
                            <label>Date:</label>
                            <DatePicker
                                dateFormat="E, MMM dd, yyyy"
                                minDate={new Date()}
                                selected={date}
                                onChange={this.updateDate}
                                // filterDate={this.isWeekday}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group">
                            <label>Time:</label>
                            <StartEndTimeSelect
                                onChange={this.updateTimes}
                                startTime={this.state.job.startTime}
                                endTime={this.state.job.endTime}
                            />
                        </div>
                        {!this.props.proposeEdit && (
                            <div>
                                <div className="form-group">
                                    <label>Rate Type:</label>
                                    <select
                                        className="form-control"
                                        value={this.state.job.fixedRate ? 'fixed' : 'hourly'}
                                        onChange={this.updateRateType}
                                    >
                                        <option value="hourly">Hourly</option>
                                        <option value="fixed">
                                            Fixed (total rate for the job)
                                        </option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>
                                        {this.state.job.fixedRate ? 'Fixed rate' : 'Hourly rate'}:
                                    </label>
                                    <RateField
                                        value={this.state.job.rate}
                                        onChange={this.updateRate}
                                        fixedRate={this.state.job.fixedRate}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Scope:</label>
                                    <RolesInput
                                        roles={this.props.appState.roles}
                                        value={this.state.job.roles}
                                        onChange={this.updateRoles}
                                    />
                                </div>
                            </div>
                        )}
                        <RateCalculations
                            hours={(this.state.job.endTime - this.state.job.startTime) / 60}
                            rate={this.state.job.rate}
                            userType={this.props.appState.userType}
                            fixedRate={this.state.job.fixedRate}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.hideModal}
                        >
                            Close
                        </button>
                        {!this.state.haveClash && (
                            <input type="submit" className="btn btn-primary" value="Submit" />
                        )}
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default JobEntryForm;
