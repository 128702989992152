/* eslint no-useless-escape: 0 */

import React from 'react';
import Cookies from 'universal-cookie';
import { calObj } from './interfaces';
import { AppState, Role, APIRespData } from './App';
import { format, isToday, isThisYear } from 'date-fns';

import axios from 'axios';
const cookies = new Cookies();

/**
 * Returns the 'partner' user type of a given type.
 * @param userType current user's type (doctor or practice)
 */
export function getOtherType(userType: string): string {
    if (userType === 'doctor') {
        return 'practice';
    } else if (userType === 'practice') {
        return 'doctor';
    }
    return '';
}

/**
 * Cleans phone numbers by removing non-numeric characters.
 * @param phoneNumber raw input string
 */
export function parsePhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/[^0-9 ()+]/, '');
}

/**
 * Checks strength of a password, and displays warnings as well.
 * @param password
 */
export function checkPasswordStrength(password: string) {
    // @ts-ignore
    let pwCheck = window.zxcvbn(password);
    let feedback = pwCheck.feedback;

    let warningText = '';

    if (pwCheck.score < 3) {
        warningText += 'Please use a longer password and/or include a range of characters. ';
        if (password.length > 0) {
            warningText +=
                'Your password may be cracked in ' +
                pwCheck.crack_times_display.online_no_throttling_10_per_second +
                ' by a determined hacker.';
        }
    }

    let strengthText = '';
    if (feedback.warning.length > 0) {
        strengthText += feedback.warning + '. ';
    }

    for (let i = 0; i < feedback.suggestions.length; i++) {
        let suggestion = feedback.suggestions[i];
        if (!suggestion.endsWith('.')) {
            suggestion = suggestion + '.';
        }
        strengthText += suggestion + ' ';
    }

    if (strengthText.length === 0) {
        strengthText =
            'Your password may be cracked in ' +
            pwCheck.crack_times_display.online_no_throttling_10_per_second +
            ' by a determined hacker.';
    }

    return {
        passwordMessage: warningText,
        passwordStrength: strengthText,
    };
}

/**
 * Regex to test if an input string is a valid email.
 * @param email
 */
function validEmail(email: string): boolean {
    var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

/**
 * Checks if a user can use a given email.
 * Checks if 1. the email is valid, 2. if the email is taken.
 * @param email user input
 * @param userType "doctor" or "practice"
 */
export function checkEmailValidity(email: string, userType: string): Promise<any> {
    if (!validEmail(email)) {
        return new Promise((resolve) => {
            resolve('Please key in a valid email address.');
        });
    }

    return axios
        .post('/api/users/check-email-taken', {
            email: email,
            userType: userType,
            userID: 0,
        })
        .then((response: any) => {
            if (response.data.ok && response.data.taken) {
                return 'Email already taken';
            }
            return '';
        });
}

/**
 * Checks if a website is a valid one (using regex).
 * @param website
 */
export function checkWebsiteValidity(website: string) {
    var expression =
        /[-a-zA-Z0-9@:%_+.~#?&/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?/gi;
    var regex = new RegExp(expression);
    return website.match(regex);
}

/**
 * Prepares the Authorization Bearer header for API requests.
 */
export function requestHeader(requestContentType?: string) {
    return {
        headers: {
            Authorization: 'Bearer ' + cookies.get('token'),
            Pragma: 'no-cache',
            'Content-Type': requestContentType
        },
    };
}

/**
 * Returns a dictionary object of a role requested by its ID.
 * @param roleID ID (int) of role wanted
 * @param roles list of roles, as fetched from the server
 */
function roleLookup(roleID: number, roles: Role[]): Role {
    for (let i = 0; i < roles.length; i++) {
        if (roles[i].id === roleID) {
            return roles[i];
        }
    }
    return { id: 0, name: '', enabled: false };
}

/**
 * Nicely prints out (with tooltip highlighting) roles of a given calObj
 * @param x calObj that is being requested
 * @param appState AppState, should contain the roles
 */
export function printRoles(x: calObj, appState: AppState) {
    let result = [];

    // if (x.roles != null) {
    for (let i = 0; i < x.roles.length; i++) {
        if (i > 0) {
            result.push(<span key={',' + i}>, </span>);
        }

        let role = roleLookup(x.roles[i], appState.roles);

        result.push(
            // <span className="role" data-toggle="tooltip" title={role.description} key={i}>
            <span className="role" key={i}>
                {role.name}
            </span>,
        );
    }
    // }
    return result;
}

/**
 * Returns the time in 24 hour format (as a string)
 * @param x time in minutes (integer)
 */
export function formatTime(x: number): string {
    let hour = Math.floor(x / 60);
    let minute = x - hour * 60;
    let hourStr = hour.toString();
    let minuteStr = minute.toString();
    while (hourStr.length < 2) {
        hourStr = '0' + hourStr;
    }
    while (minuteStr.length < 2) {
        minuteStr = '0' + minuteStr;
    }
    return hourStr + minuteStr;
}

/**
 * Returns the time in minutes (integer)
 * @param x the time in 24 hour format (as a string)
 */
export function parseTime(x: string): number {
    let hour = parseInt(x.substring(0, 2));
    let minute = parseInt(x.substring(2));
    return hour * 60 + minute;
}

export function formatMoney(x: number): string {
    return '£' + x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * Calculates the amount due to a calObj
 * (including additional time & pension, if applicable)
 * @param calObj
 */
export function calcAmount(calObj: calObj): number {
    let total = calObj.rate;

    if (!calObj.fixedRate) {
        total *= (calObj.endTime - calObj.startTime) / 60 + calObj.additionalTime;
    }

    return total * (1 + calObj.pensionRate / 100);
}

export function calcEditAmount(calObj: calObj): number {
    return (
        ((calObj.proposedEdit.endTime - calObj.proposedEdit.startTime) / 60 +
            calObj.additionalTime) *
        calObj.rate *
        (1 + calObj.pensionRate / 100)
    );
}

export function formatJobTimestampList(jobs: calObj[], namePractice?: boolean): string {
    let result = '';

    if (jobs.length === 1) {
        result = formatJobTimestamp(jobs[0], namePractice);
    } else {
        for (let i = 0; i < jobs.length - 1; i++) {
            if (i > 0) {
                result += ', ';
            }
            result += formatJobTimestamp(jobs[i], namePractice);
        }
        result += ' and ' + formatJobTimestamp(jobs[jobs.length - 1], namePractice);
    }

    return result;
}

export function formatJobTimestamp(job: calObj, namePractice?: boolean): string {
    let date = new Date(job.date);
    let result =
        format(date, 'eee, MMM d, yyyy') +
        ', ' +
        formatTime(job.startTime) +
        '–' +
        formatTime(job.endTime) +
        ' hrs';

    if (namePractice && job.practice != null && job.practice.name !== '') {
        result += ' with ' + job.practice.name;
    }

    return result;
}

export function formatMessageTimestamp(timestampInput: string): string {
    let timestampObj = new Date(timestampInput);
    let timestamp: string;
    if (isToday(timestampObj)) {
        // show the time
        timestamp = format(timestampObj, 'hh:mmaaa');
    } else {
        // show the date
        if (isThisYear(timestampObj)) {
            // show just month and day
            timestamp = format(timestampObj, 'hh:mmaaa, d MMM');
        } else {
            timestamp = format(timestampObj, "hh:mmaaa, d MMM 'YY");
        }
    }

    return timestamp;
}

export interface APIParameters {
    method?: string;
    url: string;
    payload?: object;
    requestContentType?: string;
    responseType?: 'blob' | 'json' | 'text' | 'stream';
}

export interface APIResp {
    data: APIRespData;
    status: number;
    statusText: string;
}

type Callback = (resp: APIResp) => void;

/**
 * Performs an axios request on an internal API
 * @param params
 * @param callback
 * @param appState
 */
export function fetchAPI(params: APIParameters, callback: Callback, appState: AppState) {
    if (params.method === undefined) {
        params.method = 'get';
    }
    if (params.payload === undefined) {
        params.payload = {};
    }

    console.log('[API] ' + params.method + ':', params.url, '');

    axios({
        method: params.method,
        url: '/api' + params.url,
        data: params.payload,
        responseType: params.responseType,
        ...requestHeader(params.requestContentType),
    })
        .then(callback)
        .catch(appState.checkRespAuth);
}
