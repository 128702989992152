import React from "react";
import { AppState } from "../../App";
import PracticePrivacyOptions from "./PracticePrivacyOptions";
import DoctorPrivacyOptions from "./DoctorPrivacyOptions";

export interface PrivacyProps {
    appState: AppState;
    setSuccessMessage: any;
}

const PrivacyOptions = (props: PrivacyProps) => {
    if (props.appState.userType === "doctor") {
        return <DoctorPrivacyOptions {...props} />;
    } else if (props.appState.userType === "practice") {
        return <PracticePrivacyOptions {...props} />;
    } else {
        return <div />;
    }
};

export default PrivacyOptions;
