import React from 'react';
import { CalState } from './Calendar';
import { calObj } from '../interfaces';
import { format, getDayOfYear, getMonth } from 'date-fns';
import { JobListItem } from './ListItems/JobListItems';
import { AvailabilityListItem } from './ListItems/AvailabilityListItems';
import { AppState } from '../App';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { NavLink } from 'react-router-dom';
import EmptyState from './EmptyState';

const AgendaDay = (props: {
    day: Date;
    events: calObj[];
    appState: AppState;
    onUpdate: any;
}) => {
    let items = [];

    for (let i = 0; i < props.events.length; i++) {
        let event = props.events[i];

        if (event.category === 'Availability') {
            let itemProps = {
                item: { availability: event },
                appState: props.appState,
                onUpdate: props.onUpdate,
            };

            items.push(
                <CSSTransition
                    key={'availability' + event.id}
                    timeout={500}
                    classNames='transition'
                >
                    <AvailabilityListItem {...itemProps} inAgenda />
                </CSSTransition>,
            );
        } else {
            let itemProps = {
                item: event,
                appState: props.appState,
                onUpdate: props.onUpdate,
            };

            items.push(
                <CSSTransition
                    key={event.category + event.id}
                    timeout={500}
                    classNames='transition'
                >
                    <JobListItem {...itemProps} eventType={'agenda'} />
                </CSSTransition>,
            );
        }
    }

    return (
        <tr>
            <td className='agenda-day-heading'>
                <div className='agenda-day-heading-dow'>
                    {format(props.day, 'eee')}
                </div>
                <div className='agenda-day-heading-date'>
                    {format(props.day, 'd MMM')}
                </div>
            </td>
            <td className='agenda-day-items'>
                <TransitionGroup className='list'>{items}</TransitionGroup>
            </td>
        </tr>
    );
};

const AgendaView = (props: CalState & { appState: AppState; onUpdate: any }) => {
    let days = [];
    let currDate = new Date(1970, 1, 1);
    let dayEvents = [];

    for (let i = 0; i < props.events.length; i++) {
        const date = new Date(props.events[i].date);

        if (getMonth(props.currentDay) !== getMonth(date)) {
            // Skip the days which are of the different month
            continue;
        }

        if (date !== currDate) {
            // New date
            if (dayEvents.length > 0) {
                days.push(
                    <AgendaDay
                        key={getDayOfYear(currDate)}
                        day={currDate}
                        events={dayEvents}
                        appState={props.appState}
                        onUpdate={props.onUpdate}
                    />,
                );
                dayEvents = [];
            }
            currDate = date;
        }
        dayEvents.push(props.events[i]);
    }

    if (dayEvents.length > 0) {
        days.push(
            <AgendaDay
                key={getDayOfYear(currDate)}
                day={currDate}
                events={dayEvents}
                appState={props.appState}
                onUpdate={props.onUpdate}
            />,
        );
    }

    if (days.length > 0) {
        return (
            <div id='agenda-view'>
                <table>
                    <tbody>{days}</tbody>
                </table>
            </div>
        );
    } else {
        let eventType: string;
        let link: string;
        if (props.appState.userType === 'doctor') {
            eventType = 'an availability';
            link = '/my-availabilities';
        } else {
            eventType = 'an available session';
            link = '/my-available-sessions';
        }
        return <AgendaEmptyState eventType={eventType} link={link} />;
    }
};

const AgendaEmptyState = (props: {
    eventType: string;
    link: string;
}) =>
    <EmptyState icon='calendar-times'>
        You have no availabilities &amp; jobs for this month. You can start
        by creating <NavLink to={props.link}>{props.eventType}</NavLink>!
    </EmptyState>;

export default AgendaView;
