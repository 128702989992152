import React, { Component } from "react";
import { getOtherType } from "../../utils";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";

class AcceptEditModal extends Component<GenericModalProps> {
    render() {
        let otherType = getOtherType(this.props.appState.userType);
        let name = this.props.event[otherType].name;

        let requestParams = {
            url: '/bookings/edit/' + this.props.event.id, 
            method: 'post'
        }

        return (
            <GenericModal
                {...this.props}
                title="Accept edit proposal"
                yesBtn="Yes"
                noBtn="No"
                requestParams={requestParams}
                successMessage="Edit proposal accepted."
            >
                Are you sure you want to accept {name}'s proposed booking changes?
            </GenericModal>
        );
    }
}

export default AcceptEditModal;
