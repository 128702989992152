import React, { Component } from "react";
import { SuccessAlert, StatusAlert, WarningAlert } from "../Alert";
import { checkPasswordStrength } from "../utils";
import { RouteComponentProps } from "react-router";

import axios from 'axios';

interface Props extends RouteComponentProps<{ uuid: string; token: string }> {}

interface State {
    showResetForm: boolean;

    pw1: string;
    pw2: string;

    successMessage: string;
    warningMessage: string;
    passwordStrength: string;
    passwordMessage: string;
    passwordRepeatMessage: string;
}

class ResetPassword extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showResetForm: true,
            pw1: "",
            pw2: "",
            successMessage: "",
            warningMessage: "",
            passwordMessage: "",
            passwordStrength: "",
            passwordRepeatMessage: ""
        };

        // check if token is allowed
        axios
            .post("/api/auth/check-reset-request", {
                tokenID: this.props.match.params.uuid,
                token: this.props.match.params.token
            })
            .then((resp: any) => {
                if (!resp.data.ok) {
                    // Hide reset form
                    this.setState({ showResetForm: false });
                }
            });
    }

    updatePw1 = (e: any) => {
        this.setState({ pw1: e.target.value }, this.checkPasswordStrength);
    };

    updatePw2 = (e: any) => {
        this.setState({ pw2: e.target.value }, this.checkPasswordRepeat);
    };

    checkPasswordStrength = () => {
        this.setState(checkPasswordStrength(this.state.pw1));
        this.checkPasswordRepeat();
    };

    checkPasswordRepeat = () => {
        if (this.state.pw1 !== this.state.pw2) {
            this.setState({ passwordRepeatMessage: "Passwords do not match." });
        } else {
            this.setState({ passwordRepeatMessage: "" });
        }
    };

    submitForm = (e: any) => {
        e.preventDefault();
        this.checkPasswordStrength();
        this.checkPasswordRepeat();
        if (
            this.state.passwordMessage.length > 0 ||
            this.state.passwordRepeatMessage.length > 0
        ) {
            return;
        }
        axios
            .post("/api/auth/reset-password", {
                tokenID: this.props.match.params.uuid,
                token: this.props.match.params.token,
                password: this.state.pw1
            })
            .then((resp: any) => {
                if (resp.data.ok) {
                    this.setState({
                        successMessage:
                            "Your password has been changed. Please log in now."
                    });
                } else {
                    this.setState({
                        warningMessage:
                            "There was a problem changing your password. Please try again later."
                    });
                }
                setTimeout(() => {
                    window.location.pathname = "/";
                }, 500);
            });
    };

    render() {
        if (this.state.showResetForm) {
            return (
                <div id="signup-form">
                    <h2>Reset Password</h2>
                    <SuccessAlert content={this.state.successMessage} />
                    <WarningAlert content={this.state.warningMessage} />
                    <form onSubmit={this.submitForm}>
                        <StatusAlert content={this.state.passwordStrength} />
                        <WarningAlert content={this.state.passwordMessage} />

                        <div className="form-group">
                            <label>Password:</label>
                            <input
                                type="password"
                                className="form-control"
                                value={this.state.pw1}
                                onChange={this.updatePw1}
                            />
                        </div>
                        <WarningAlert
                            content={this.state.passwordRepeatMessage}
                        />
                        <div className="form-group">
                            <label>Repeat password:</label>
                            <input
                                type="password"
                                className="form-control"
                                value={this.state.pw2}
                                onChange={this.updatePw2}
                            />
                        </div>
                        <input
                            className="btn btn-primary"
                            type="submit"
                            value="Reset Password"
                        />
                    </form>
                </div>
            );
        } else {
            return (
                <div>
                    <h2>Reset Password</h2>
                    <p>
                        Sorry, it seems that the link that you used is either
                        invalid or has expired.
                    </p>
                </div>
            );
        }
    }
}

export default ResetPassword;
