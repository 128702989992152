/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { Component } from 'react';
import logo from './imgs/logo_light.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './styles/Navigation.scss';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { NavLink, Link } from 'react-router-dom';
import { page, getPagesSectioned } from './constants';
import { AppState, UserType } from './App';
import NotificationsWindow, { notification } from './components/NotificationsWindow';
import { APIResp, fetchAPI } from './utils';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

interface NavbarItemProps {
    text: string;
    icon: IconName;
    link?: string;
    onClick?: any;
    topNavbar?: boolean;
    count?: number;
    refFn?: any;
}

const NavbarItem = (props: NavbarItemProps) => {
    let icon = <FontAwesomeIcon icon={['far', props.icon]} fixedWidth />;
    if (props.count != null && props.count > 0) {
        icon = <NavbarCountIcon icon={props.icon} count={props.count} />;
    }
    const btnBody = (
        <div>
            <span className={'nav-item-icon Z' + (props.topNavbar ? 'nav-item-icon-block' : '')}>
                {icon}
            </span>
            <span
                className={props.topNavbar ? 'mobile-only' : ''}
                style={{ fontWeight: props.text === 'Feedback' ? 'bold' : 'normal' }}
            >
                {props.text}
            </span>
        </div>
    );

    let className = 'nav-link';
    let activeClassName = 'nav-link-active';
    if (props.topNavbar) {
        className += '-icon';
        activeClassName = 'nav-icon-active';
    }

    if (props.link != null) {
        return (
            <li className="nav-item" ref={props.refFn}>
                <NavLink
                    to={'/' + props.link}
                    className={className}
                    activeClassName={activeClassName}
                >
                    {btnBody}
                </NavLink>
            </li>
        );
    } else if (props.onClick != null) {
        return (
            <li className="nav-item" ref={props.refFn}>
                <a href="#" className={className} onClick={props.onClick}>
                    {btnBody}
                </a>
            </li>
        );
    } else {
        return <div />;
    }
};

class Sections extends Component<{ links: page[] }> {
    render() {
        let links = [];
        for (let i = 0; i < this.props.links.length; i++) {
            let link = this.props.links[i];
            links.push(
                <NavbarItem link={link.link} icon={link.icon} text={link.name} key={link.link} />,
            );
        }
        return (
            <div className="nav-group">
                {links}
                {/* <hr /> */}
            </div>
        );
    }
}

class Links extends Component<{ userType: UserType }> {
    render() {
        let pages = getPagesSectioned(this.props.userType);
        let sections = [];
        for (let i = 0; i < pages.length; i++) {
            sections.push(<Sections links={pages[i]} key={i} />);
        }
        return <div>{sections}</div>;
    }
}

interface NavbarCountIconProps {
    icon: IconName;
    count: number;
}

class NavbarCountIcon extends Component<NavbarCountIconProps> {
    render() {
        let iconFill: IconPrefix = 'far';
        let counter = <div />;

        if (this.props.count > 0) {
            iconFill = 'fas';
            counter = <div className="navbar-counter">{this.props.count}</div>;
        }

        return (
            <div className="navbar-count-icon">
                <FontAwesomeIcon icon={[iconFill, this.props.icon]} fixedWidth />
                {counter}
            </div>
        );
    }
}

interface NavbarProps {
    appState: AppState;
}

interface NavbarState {
    showNotifs: boolean;
    loadingNotifs: boolean;
    notifications: notification[];
}

export class Navbar extends Component<NavbarProps, NavbarState> {
    notifsRef: any;
    notifsIconRef: any;

    constructor(props: NavbarProps) {
        super(props);
        this.state = {
            showNotifs: false,
            notifications: [],
            loadingNotifs: false,
        };
    }

    updateNotifsState = (resp: APIResp) => {
        this.setState({ loadingNotifs: false });
        if (resp.data.ok) {
            this.setState({
                notifications: resp.data.data,
            });
            this.props.appState.updateUICounts(resp.data);
        }
    };

    fetchNotifications = () => {
        this.setState({ loadingNotifs: true });
        fetchAPI({ url: '/notifications' }, this.updateNotifsState, this.props.appState);
    };

    hideNotifsListener = (e: any) => {
        // In case we want to check the target of the click
        // this.hideNotifs();
        // console.log(this.notifsWindow.contains(e.target))
        if (this.notifsRef != null) {
            if (this.notifsIconRef.contains(e.target)) {
                return; // ignore as the onclick will automatically close it
            }
            if (this.notifsRef.contains(e.target)) {
                // clicking on a link
                setTimeout(this.hideNotifs, 100);
                // have to set a timeout, if not it will vanish before the link works
            } else {
                this.hideNotifs();
            }
        }
    };

    hideNotifs = () => {
        this.setState({ showNotifs: false });
    };

    toggleNotifications = () => {
        if (!this.state.showNotifs) {
            this.fetchNotifications();
            this.setState({ showNotifs: true });
        } else {
            this.hideNotifs();
        }
    };

    componentWillMount() {
        document.addEventListener('mousedown', this.hideNotifsListener, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.hideNotifsListener, false);
    }

    setNotifsRef = (ref: any) => {
        this.notifsRef = ref;
    };

    setNotifsIconRef = (ref: any) => {
        this.notifsIconRef = ref;
    };

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <div className="mobile-only">
                            <Links userType={this.props.appState.userType} />
                        </div>
                    </ul>
                    <span className="navbar-name">Welcome back, {this.props.appState.name}</span>
                    <a
                        id="need-help"
                        title="Report bug, outage or request support"
                        href="mailto:gillian.kyei@nhs.net"
                    >
                        Need Help?
                    </a>
                    <ul className="navbar-nav">
                        <NavbarItem
                            text="Messages"
                            link="messages"
                            icon="comments"
                            count={this.props.appState.numMessages}
                            topNavbar
                        />
                        <NavbarItem
                            text="Notifications"
                            onClick={this.toggleNotifications}
                            icon="bell"
                            count={this.props.appState.numNotifs}
                            topNavbar
                            refFn={this.setNotifsIconRef}
                        />
                        <NavbarItem
                            text="Edit Profile"
                            link="edit-profile/details"
                            icon="user-circle"
                            topNavbar
                        />
                        <NavbarItem
                            text="Logout"
                            onClick={this.props.appState.logout}
                            icon="sign-out"
                            topNavbar
                        />
                    </ul>
                </div>
                <TransitionGroup>
                    {this.state.showNotifs && (
                        <CSSTransition key={0} timeout={500} classNames="transition">
                            <NotificationsWindow
                                loadingNotifs={this.state.loadingNotifs}
                                notifications={this.state.notifications}
                                setRef={this.setNotifsRef}
                            />
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </nav>
        );
    }
}

export class Sidebar extends Component<{ appState: AppState }> {
    render() {
        return (
            <nav className="nav flex-column desktop-only" id="sidebar">
                <Link className="navbar-brand" to="/dashboard">
                    <img src={logo} id="navbar-logo" alt="logo" />
                </Link>

                <Links userType={this.props.appState.userType} />
            </nav>
        );
    }
}
