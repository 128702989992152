import React, { Component } from "react";
import { CompProps } from "../Main";
import { RouteComponentProps } from "react-router";
import { formatMessageTimestamp, fetchAPI, APIResp } from "../utils";
import { message } from "./Message";

import "../styles/ViewMessages.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppState } from "../App";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Loading from "./Loading";
import { NoUserFound } from "./EmptyState";

interface InputProps {
    fetchData: any;
    appState: AppState;
    recipientHash: string;
}

interface InputState {
    content: string;
    errorMessage: string;
}

class MessageInput extends Component<InputProps, InputState> {
    constructor(props: InputProps) {
        super(props);
        this.state = { content: "", errorMessage: "" };
    }

    updateContent = (e: any) => {
        this.setState({ content: e.target.value });
    };

    sendMessageUpdate = (resp: APIResp) => {
        if (resp.data.ok) {
            this.props.fetchData();
            this.props.appState.updateUICounts(resp.data);
        } else {
            // Show error message
            this.setState({
                errorMessage: "There was an error sending your message."
            });
        }
    };

    sendMessage = () => {
        let payload = {
            recipientHash: this.props.recipientHash,
            context: "",
            content: this.state.content
        };
        fetchAPI(
            { url: "/messages/", method: "post", payload: payload },
            this.sendMessageUpdate,
            this.props.appState
        );
        this.setState({ content: "" });
        this.props.fetchData();
    };

    render() {
        return (
            <div id="message-input">
                <div className="input-group">
                    <textarea
                        className="form-control"
                        value={this.state.content}
                        placeholder="Type a message here..."
                        onChange={this.updateContent}
                    />
                    <div className="input-group-append">
                        <button
                            className="btn btn-primary"
                            onClick={this.sendMessage}
                        >
                            <FontAwesomeIcon
                                icon="chevron-circle-right"
                                size="lg"
                            />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

interface MessageProps {
    message: message;
}

class Message extends Component<MessageProps> {
    render() {
        let timestamp = formatMessageTimestamp(this.props.message.timestamp);
        return (
            <div className={"message message-" + this.props.message.direction}>
                {this.props.message.systemHeader.length > 0 && (
                    <div className="message-header">
                        {this.props.message.systemHeader}
                    </div>
                )}
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.message.content
                    }}
                />
                <div className="message-timestamp">{timestamp}</div>
            </div>
        );
    }
}

interface ListProps {
    messages: message[];
    loading: boolean;
}

class MessageList extends Component<ListProps> {
    messagesEnd = null;

    componentDidUpdate() {
        if (this.messagesEnd != null) {
            //@ts-ignore
            this.messagesEnd.scrollIntoView();
        }
    }

    render() {
        let messages = this.props.messages.map((message) => (
            <CSSTransition
                key={message.id}
                timeout={500}
                classNames="transition"
            >
                <Message message={message} key={message.id} />
            </CSSTransition>
        ));
        messages.push(
            <div
                style={{ float: "left", clear: "both" }}
                ref={(el: any) => {
                    this.messagesEnd = el;
                }}
                key={-1}
            />
        );
        return (
            <Loading loading={this.props.loading}><>
                {this.props.messages.length > 0 && (
                    <TransitionGroup id="message-list">
                        {messages}
                    </TransitionGroup>
                )}
                {this.props.messages.length === 0 && (
                    <div id="message-list" className="no-entries-found">
                        No messages were found.
                    </div>
                )}
            </></Loading>
        );
    }
}

interface MatchParams {
    userHash: string;
}

interface State {
    profile: any;
    messages: message[];
    userName: string;
    loading: boolean;
}

interface MessagesProps extends RouteComponentProps<MatchParams>, CompProps {}

class ViewMessages extends Component<
    MessagesProps & { subcomponent?: boolean },
    State
> {
    constructor(props: MessagesProps & { subcomponent?: boolean }) {
        super(props);
        this.state = {
            profile: {},
            messages: [],
            userName: "",
            loading: true
        };
        this.fetchData();
    }

    updateState = (resp: APIResp) => {
        if (resp.data.ok) {
            let user = resp.data.data.user;
            this.setState({
                profile: user,
                messages: resp.data.data.messages,
                userName: resp.data.data.user.name,
                loading: false
            });

            if (!this.props.subcomponent) {
                if (user.id === 0) {
                    this.props.setTitle("User not found", true);
                } else {
                    this.props.setTitle("Messages with " + user.name, true);
                }
            }

            this.props.appState.updateUICounts(resp.data);
        }
    };

    fetchData = () => {
        let userHash = this.props.match.params.userHash;
        fetchAPI(
            { url: "/messages/" + userHash },
            this.updateState,
            this.props.appState
        );
    };

    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        if (this.state.profile.id === 0) {
            return <NoUserFound />;
        } else {
            return (
                <div id="view-message">
                    <MessageList
                        messages={this.state.messages}
                        loading={this.state.loading}
                    />
                    <MessageInput
                        fetchData={this.fetchData}
                        appState={this.props.appState}
                        recipientHash={this.props.match.params.userHash}
                    />
                </div>
            );
        }
    }
}

export default ViewMessages;
