import { Modal } from "react-bootstrap";

import React, { Component } from "react";
import { AppState } from "../../App";

import { APIResp, fetchAPI, formatJobTimestampList } from "../../utils";
import { WarningAlert, StatusAlert } from "../../Alert";

interface SendApplicationModalProps {
    appState: AppState;
    show: boolean;
    closeModal: any;
    event: any;
    onSuccess: any;
}

interface SendApplicationModalState {
    errorMessage: string;
    statusMessage: string;
}

class SendApplicationModal extends Component<
    SendApplicationModalProps,
    SendApplicationModalState
> {
    constructor(props: SendApplicationModalProps) {
        super(props);
        this.state = {
            errorMessage: "",
            statusMessage: ""
        };
    }

    hideModal = () => {
        this.setState({ errorMessage: "", statusMessage: "" });
        this.props.closeModal();
    };

    updateState = (resp: APIResp) => {
        this.setState({ statusMessage: "" });
        if (resp.status !== 200) {
            this.setState({
                errorMessage:
                    "Sorry, there was an error in processing your request."
            });
        } else {
            if (resp.data.ok) {
                // dismiss modal
                // show success alert
                this.hideModal();
                this.props.onSuccess("Job application sent.");
                this.props.appState.updateUICounts(resp.data);
            } else {
                this.setState({ errorMessage: resp.data.error });
            }
        }
    };

    sendApplication = (e: any) => {
        e.preventDefault();
        this.setState({
            errorMessage: "",
            statusMessage: "Updating the system..."
        });

        fetchAPI(
            {
                url: "/applications/create/" + this.props.event.id,
                method: "post"
            },
            this.updateState,
            this.props.appState
        );
    };

    render() {
        if (this.props.event !== null) {
            let clashMessage = "";
            if (
                this.props.event.clashes != null &&
                this.props.event.clashes.length > 0
            ) {
                // Prepares the notification to alert on whether there is a clash.
                // Very long because the wording is different if it is only 1 clash, or multiple clashes.
                clashMessage = "You already have ";
                if (this.props.event.clashes.length === 1) {
                    clashMessage += "a confirmed job";
                } else {
                    clashMessage +=
                        this.props.event.clashes.length + " confirmed jobs";
                }
                clashMessage +=
                    " (on " +
                    formatJobTimestampList(this.props.event.clashes, true) +
                    ") which clash";
                if (this.props.event.clashes.length === 1) {
                    clashMessage += "es";
                }
                clashMessage +=
                    " with the hours of this job application. Please note that you will not be able to accept a job offer for this job without cancelling or amending your current confirmed job so that the hours do not clash.";
            }
            return (
                <Modal show={this.props.show} onHide={this.hideModal}>
                    <Modal.Header closeButton>
                        Send Application to {this.props.event.practice.name}
                    </Modal.Header>
                    <Modal.Body>
                        <WarningAlert content={this.state.errorMessage} />
                        <StatusAlert content={this.state.statusMessage} />
                        <div>
                            {this.props.event.clashes != null &&
                                this.props.event.clashes.length > 0 && (
                                    <p>{clashMessage}</p>
                                )}
                            <p>
                                Are you sure you want to send a job application
                                to {this.props.event.practice.name}? They will
                                be notified and will get back to you after
                                reviewing your application.
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.hideModal}
                        >
                            Cancel
                        </button>
                        <input
                            type="submit"
                            className="btn btn-primary"
                            value="Send"
                            onClick={this.sendApplication}
                        />
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return <div />;
        }
    }
}

export default SendApplicationModal;
