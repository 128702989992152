import React, { Component, useEffect, useState } from 'react';
import { CompProps } from '../Main';
import { RouteComponentProps } from 'react-router';
import { APIResp, fetchAPI, getOtherType } from '../utils';

import '../styles/ViewProfile.scss';
import Loading from './Loading';
import { calObj } from '../interfaces';
import { AppState } from '../App';
import JobListItems from './ListItems/JobListItems';
import { LoadingBtn, SendOfferBtn, ViewMessagesBtn } from './Buttons';
import { ProfileBodyRow } from './Profiles';
import { NoUserFound } from './EmptyState';
import { docs, DoctorFile } from './EditProfile/ComplianceDocs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface State {
    profile: any;
    jobs: calObj[];
    fetchingData: boolean;
}

class DetailsContainer extends Component<State & { appState: AppState; onSuccess: any }> {
    render() {
        let event = { roles: [], startTime: 0, rate: 0, endTime: 60 };
        //@ts-ignore
        event[getOtherType(this.props.appState.userType)] = this.props.profile;
        return (
            <div id='details'>
                {this.props.appState.userType === 'practice' && (
                    <SendOfferBtn
                        appState={this.props.appState}
                        event={event}
                        onSuccess={this.props.onSuccess}
                        fullSized
                    />
                )}
                <ViewMessagesBtn hash={this.props.profile.hash} />

                <div id='detail-rows'>
                    <ProfileBodyRow
                        keyValue='borough'
                        value={this.props.profile.borough}
                        icon='map-signs'
                        appState={this.props.appState}
                    />
                    <ProfileBodyRow
                        keyValue='email'
                        value={this.props.profile.email}
                        icon='envelope'
                        appState={this.props.appState}
                    />
                    <ProfileBodyRow
                        keyValue='phone'
                        value={this.props.profile.phone}
                        icon='phone'
                        appState={this.props.appState}
                    />
                    <ProfileBodyRow
                        keyValue='website'
                        value={this.props.profile.website}
                        icon='globe'
                        appState={this.props.appState}
                    />
                    <ProfileBodyRow
                        keyValue='address'
                        value={this.props.profile.address}
                        icon='map-marker-alt'
                        appState={this.props.appState}
                    />
                </div>

                {this.props.profile.bio != null && this.props.profile.bio.length > 0 && (
                    <div className='details-bio'>{this.props.profile.bio}</div>
                )}

                {this.props.appState.userType === 'practice' && (
                    <DoctorFiles appState={this.props.appState} profile={this.props.profile} />
                )}
            </div>
        );
    }
}

const DoctorFiles = (props: { appState: AppState; profile: any }) => {
    const [files, setFiles] = useState<DoctorFile[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchAPI(
            { url: '/profile/files/doctor/' + props.profile.id },
            (r) => {
                if (r.data.ok) {
                    // handle r.data.data
                    setFiles(r.data.data);
                }
                setIsLoading(false);
            },
            props.appState,
        );
    }, [props.profile, props.appState]);

    return (
        <Loading loading={isLoading}><>
            <h3>Compliance Documents</h3>
            {files.map((f) => (
                <FileBtn appState={props.appState} f={f} />
            ))}
        </>
        </Loading>
    );
};

const FileBtn = (props: { appState: AppState; f: DoctorFile }) => {
    const docName = docs.find((x) => x.id === props.f.name)?.name;
    const fileName = docName + (props.f.description ? ' - ' + props.f.description : '');
    const [isLoading, setIsLoading] = useState(false);

    const download = () => {
        setIsLoading(true);
        fetchAPI(
            { url: '/profile/files/' + props.f.id, responseType: 'blob' },
            (resp) => {
                setIsLoading(false);
                const blob = resp.data;
                const splits = props.f.link.split('.');
                const ext = splits[splits.length - 1];

                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName + '.' + ext);
                } else {
                    const fileURL = URL.createObjectURL(blob);
                    let tempLink = document.createElement('a');
                    tempLink.href = fileURL;
                    tempLink.setAttribute('download', fileName + '.' + ext);
                    tempLink.click();
                }
            },
            props.appState,
        );
    };

    return (
        <LoadingBtn className='btn full-btn btn-tertiary' isLoading={isLoading} onClick={download}>
            <FontAwesomeIcon icon={['far', 'file']} fixedWidth />
            {fileName}
        </LoadingBtn>
    );
};

interface MatchParams {
    userType: string;
    userHash: string;
}

interface Props extends RouteComponentProps<MatchParams>, CompProps {
}

class ViewProfile extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            profile: {},
            jobs: [],
            fetchingData: true,
        };
        this.fetchData();
    }

    updateState = (resp: APIResp) => {
        this.setState({ fetchingData: false });
        if (resp.data.ok) {
            let payload = resp.data.data;
            let jobs = [];

            // Add user name to the job.
            // We don't include it in the call to reduce the calls made to the database.
            let otherType = getOtherType(this.props.appState.userType);
            for (let i = 0; i < payload.jobs.length; i++) {
                let job = payload.jobs[i];
                job[otherType].name = payload.profile.name;
                jobs.push(job);
            }

            this.setState({
                profile: payload.profile,
                jobs: jobs,
            });

            this.props.appState.setEvents(jobs);

            this.props.setTitle(payload.profile.name, true);
            this.props.appState.updateUICounts(resp.data);
        } else {
            this.props.setTitle('User not found', true);
            this.setState({
                profile: null,
            });
        }
    };

    fetchData = () => {
        let userHash = this.props.match.params.userHash;
        fetchAPI({ url: '/profiles/' + userHash + '/' }, this.updateState, this.props.appState);
    };

    onUpdate = (message: string) => {
        this.props.setSuccessMessage(message);
        this.fetchData();
    };

    render() {
        if (this.state.profile == null) return <NoUserFound />;

        return (
            <div id='profile'>
                <Loading loading={this.state.fetchingData}>
                    <>
                        <DetailsContainer
                            {...this.state}
                            appState={this.props.appState}
                            onSuccess={this.onUpdate}
                        />
                        <PastJobs
                            appState={this.props.appState}
                            onUpdate={this.onUpdate}
                            fetchingData={this.state.fetchingData}
                            events={this.state.jobs}
                            profile={this.state.profile}
                        />
                    </>
                </Loading>
            </div>
        );
    }
}

class PastJobs extends Component<{
    appState: AppState;
    onUpdate(message: string): void;
    fetchingData: boolean;
    events: calObj[];
    profile: any;
}> {
    render() {
        return (
            <div id='past-jobs'>
                <h2>List of Job Actions with {this.props.profile.name}</h2>
                <p>
                    Please see here for a list of all of your job actions with{' '}
                    {this.props.profile.name}, including job applications, job offers, confirmed
                    jobs, completed jobs and cancelled jobs.
                </p>
                <Loading loading={this.props.fetchingData}>
                    <div id='job-list-max-height'>
                        <JobListItems
                            appState={this.props.appState}
                            data={this.props.events}
                            onUpdate={this.props.onUpdate}
                            eventType={'profile'}
                        />
                    </div>
                </Loading>
            </div>
        );
    }
}

export default ViewProfile;
