import { Modal } from "react-bootstrap";

import React, { Component } from "react";
import { ModalProps } from "./Modals";
import { formatJobTimestamp, formatJobTimestampList } from "../../utils";
import { CancelBookingBtn, MessageBtn, RejectBtn } from "../Buttons";

class ClashingAcceptOfferModal extends Component<ModalProps> {
    hideModal = () => {
        this.props.closeModal();
    };

    onButtonActionSuccess = (message: string) => {
        this.props.onSuccess(message);
        this.hideModal();
    };

    render() {
        if (this.props.event !== null) {
            let btns = [];
            let practices = [
                {
                    id: this.props.event.practice.id,
                    name: this.props.event.practice.name,
                    event: this.props.event,
                    eventType: "your job offer"
                }
            ];
            let jobListing = "";
            let numJobs = "";
            let numPractices = "practice";

            if (this.props.event.clashes.length === 1) {
                jobListing =
                    "one confirmed job at " +
                    formatJobTimestampList(this.props.event.clashes, true) +
                    " that clashes";
                numJobs = "confirmed job";
            } else {
                jobListing =
                    this.props.event.clashes.length +
                    " confirmed jobs (" +
                    formatJobTimestampList(this.props.event.clashes, true) +
                    ") that clash";
                numJobs = this.props.event.clashes.length + " confirmed jobs";
            }

            for (let i = 0; i < this.props.event.clashes.length; i++) {
                let found = false;
                let practice = this.props.event.clashes[i].practice;
                for (let j = 0; j < practices.length; j++) {
                    if (practice.id === practices[j].id) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    practices.push({
                        id: practice.id,
                        name: practice.name,
                        event: this.props.event.clashes[i],
                        eventType: "our booking"
                    });
                }

                btns.push(
                    <CancelBookingBtn
                        appState={this.props.appState}
                        event={this.props.event.clashes[i]}
                        onSuccess={() => {
                            this.onButtonActionSuccess("Booking cancelled.");
                        }}
                        fullSized
                        describeEvent
                    />
                );
            }

            if (practices.length > 1) {
                numPractices = practices.length + " practices";
            }

            for (let i = 0; i < practices.length; i++) {
                btns.push(
                    <MessageBtn
                        appState={this.props.appState}
                        event={practices[i].event}
                        eventType={practices[i].eventType}
                        onSuccess={() => {
                            this.onButtonActionSuccess(
                                "Your message has been sent."
                            );
                        }}
                        fullSized
                    />
                );
            }

            btns.push(
                <RejectBtn
                    appState={this.props.appState}
                    event={this.props.event}
                    onSuccess={() => {
                        this.onButtonActionSuccess(
                            this.props.event.practice.name +
                                "'s job offer has been rejected."
                        );
                    }}
                    fullSized
                />
            );

            return (
                <Modal show={this.props.show} onHide={this.hideModal}>
                    <Modal.Header closeButton>Clashing Job Offer</Modal.Header>
                    <Modal.Body>
                        <p>
                            You already have {jobListing} with this job offer (
                            {formatJobTimestamp(this.props.event)}). To proceed
                            with this job offer you must cancel your {numJobs}{" "}
                            or negotiate with the {numPractices} so that the
                            hours do not clash.
                        </p>
                        {btns}
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.hideModal}
                        >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        } else {
            return <div />;
        }
    }
}

export default ClashingAcceptOfferModal;
