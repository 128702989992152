import React, { Component } from "react";
import ListItem from "./ListItem";
import {
    MessageBtn,
    SendApplicationBtn,
    SendOfferBtn,
    ClashingSendApplicationBtn
} from "../Buttons";
import { AppState } from "../../App";
import { printRoles } from "../../utils";

import List from "../List";
import { calObj } from "../../interfaces";

export interface MatchesListItemsProps {
    data: any;
    appState: AppState;
    onUpdate: any;
    eventType: string;
}

interface MatchesListItemProps {
    item: calObj;
    appState: AppState;
    onUpdate: any;
    eventType: string;
}

class MatchesListItem extends Component<MatchesListItemProps> {
    render() {
        let messageEventType = "";
        let btns = [];

        this.props.item.eventType = "match";

        if (this.props.eventType !== "invoice") {
            if (this.props.appState.userType === "doctor") {
                // Send application
                messageEventType = "your available session";
                if (
                    this.props.item.clashes != null &&
                    this.props.item.clashes.length > 0
                ) {
                    btns.push(
                        <ClashingSendApplicationBtn
                            appState={this.props.appState}
                            onSuccess={this.props.onUpdate}
                            event={this.props.item}
                            key={"clashing-send-app-btn"}
                        />
                    );
                } else {
                    btns.push(
                        <SendApplicationBtn
                            appState={this.props.appState}
                            onSuccess={this.props.onUpdate}
                            event={this.props.item}
                            key={"send-app-btn"}
                        />
                    );
                }
            } else if (this.props.appState.userType === "practice") {
                // Send offer
                messageEventType = "your posted availability";
                btns.push(
                    <SendOfferBtn
                        appState={this.props.appState}
                        onSuccess={this.props.onUpdate}
                        event={this.props.item}
                        key={"send-offer-btn"}
                    />
                );
            }

            btns.push(
                <MessageBtn
                    appState={this.props.appState}
                    onSuccess={this.props.onUpdate}
                    event={this.props.item}
                    key={"message-btn"}
                    eventType={messageEventType}
                />
            );
        }

        return (
            <ListItem
                event={this.props.item}
                btns={btns}
                eventType={"send"}
                appState={this.props.appState}
                onUpdate={this.props.onUpdate}
            >
                <div>{printRoles(this.props.item, this.props.appState)}</div>
            </ListItem>
        );
    }
}

export class MatchesListItems extends Component<MatchesListItemsProps> {
    render() {
        return (
            <div className="list-matches">
                <List
                    {...this.props}
                    itemComponent={MatchesListItem}
                    hideTimeMarkers={true}
                    noEntries={<div />}
                />
            </div>
        );
    }
}

export default MatchesListItems;
