import React from 'react';
import { NavLink } from 'react-router-dom';


const BookingsNavItem = (props: { link: string; children?: any }) => <NavLink
    className='secondary-nav-item'
    activeClassName='secondary-nav-item-active'
    to={'/bookings/' + props.link}
>
    {props.children}
</NavLink>;

const BookingsNav = () => <div id='secondary-nav'>
    <BookingsNavItem link='upcoming'>Upcoming</BookingsNavItem>
    <BookingsNavItem link='invoice-not-yet-sent'>
        Invoice not yet sent
    </BookingsNavItem>
    <BookingsNavItem link='pending-payment'>
        Pending payment
    </BookingsNavItem>
    <BookingsNavItem link='paid'>Paid</BookingsNavItem>
    {/* <BookingsNavItem link="completed">All completed</BookingsNavItem> */}
</div>;

export default BookingsNav;
