import React from 'react';
import { NavLink } from 'react-router-dom';
import { AppState } from '../../App';

const ProfileNavItem = (props: { link: string; children: string }) => <NavLink
    className='secondary-nav-item'
    activeClassName='secondary-nav-item-active'
    to={'/edit-profile/' + props.link}
>
    {props.children}
</NavLink>;

const ProfileNav = (props: { appState: AppState }) =>
    <div id='secondary-nav'>
        <ProfileNavItem link='details'>Details</ProfileNavItem>
        <ProfileNavItem link='notifications'>Notifications</ProfileNavItem>
        <ProfileNavItem link='privacy'>Privacy</ProfileNavItem>
        {props.appState.userType === 'doctor' && (
            <ProfileNavItem link='compliance'>Compliance Documents</ProfileNavItem>
        )}
    </div>;

export default ProfileNav;
