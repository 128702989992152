import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { AppState } from "../App";
import {
    parseISO,
    isPast,
    isToday,
    isYesterday,
    isTomorrow,
    isThisWeek,
    isThisMonth,
    isSameMonth,
    addMonths,
    subMonths,
    isThisYear,
    isSameYear,
    addYears,
    subYears,
    getYear
} from "date-fns";

interface ListProps {
    data: any;
    appState: AppState;
    onUpdate: any;
    itemComponent: React.ComponentType<any>;
    eventType: string;
    hideTimeMarkers?: boolean;

    state?: any;
    noEntries: JSX.Element;
}

class List extends Component<ListProps> {
    parseTimeMarker = (datum: any): string => {
        let dateObj;
        if (datum.date != null) {
            dateObj = parseISO(datum.date);
        } else if (datum.availability.date != null) {
            dateObj = parseISO(datum.availability.date);
        } else {
            return "";
        }

        let slot = "";

        if (isToday(dateObj)) {
            return "Today";
        } else if (isYesterday(dateObj)) {
            return "Yesterday";
        } else if (isTomorrow(dateObj)) {
            return "Tomorrow";
        } else if (isThisWeek(dateObj)) {
            slot = "week";
        } else if (isThisMonth(dateObj)) {
            slot = "month";
        } else if (isSameMonth(dateObj, addMonths(new Date(), 1))) {
            return "Next month";
        } else if (isSameMonth(dateObj, subMonths(new Date(), 1))) {
            return "Last month";
        } else if (isThisYear(dateObj)) {
            slot = "year";
        } else if (isSameYear(dateObj, addYears(new Date(), 1))) {
            return "Next year";
        } else if (isSameYear(dateObj, subYears(new Date(), 1))) {
            return "Last year";
        } else {
            return getYear(dateObj).toString();
        }

        if (isPast(dateObj)) {
            slot = "This past " + slot;
        } else {
            slot = "This " + slot;
        }

        return slot;
    };

    render() {
        // hideTimeMarkers = matches list
        // can use as a proxy to know whether this list is used as a parent or a matches list
        // we don't want to show the 'no entries' text if it's in the matches list
        if (this.props.data.length > 0 || this.props.hideTimeMarkers) {
            const Item = this.props.itemComponent;
            let prevSlot = "";
            let items = [];

            for (let i = 0; i < this.props.data.length; i++) {
                if (
                    this.props.hideTimeMarkers == null ||
                    !this.props.hideTimeMarkers
                ) {
                    let slot = this.parseTimeMarker(this.props.data[i]);
                    if (slot !== prevSlot) {
                        prevSlot = slot;
                        items.push(
                            <CSSTransition
                                key={"slot" + slot}
                                classNames="transition"
                                timeout={500}
                            >
                                <div className="time-marker">{slot}</div>
                            </CSSTransition>
                        );
                    }
                }

                let id = this.props.data[i].id;
                if (id == null) {
                    id = this.props.data[i].availability.id;
                }

                items.push(
                    <CSSTransition
                        key={id}
                        timeout={500}
                        classNames="transition"
                    >
                        <Item
                            appState={this.props.appState}
                            onUpdate={this.props.onUpdate}
                            item={this.props.data[i]}
                            eventType={this.props.eventType}
                            state={this.props.state}
                        />
                    </CSSTransition>
                );
            }

            return <TransitionGroup className="list">{items}</TransitionGroup>;
        } else {
            return this.props.noEntries;
        }
    }
}

export default List;
