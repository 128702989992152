import React, { Component } from "react";
import { getOtherType } from "../../utils";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";

class RetractModal extends Component<GenericModalProps> {
    render() {
        let otherType = getOtherType(this.props.appState.userType)
        let eventType = "";
        if (this.props.appState.userType === "doctor") {
            eventType = "application";
        } else if (this.props.appState.userType === "practice") {
            eventType = "offer";
        }
        let successMessage = "Your job " + eventType + " has been retracted.";

        let requestParams = {
            url: '/' + eventType + 's/delete/' + this.props.event.id,
            method: 'post'
        }

        return (
            <GenericModal
                {...this.props}
                title={"Retracting your job " + eventType}
                yesBtn="Retract"
                noBtn="No"
                requestParams={requestParams}
                successMessage={successMessage}
            >
                Are you sure you want to retract your {eventType} to{" "}
                {this.props.event[otherType].name}?{eventType}?
            </GenericModal>
        );
    }
}

export default RetractModal;
