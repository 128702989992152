import React, { Component } from "react";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";

class LogOutEverywhereModal extends Component<GenericModalProps> {
    render() {

        let requestParams = {
            url: '/auth/logout-everywhere',
            method: 'post'
        }

        return (
            <GenericModal
                {...this.props}
                title={"Logout From All Devices"}
                yesBtn="Logout"
                noBtn="Cancel"
                requestParams={requestParams}
                successMessage=""
            >
                Are you sure you want to log out of all devices you are currently logged in to? 
                You will be logged out of this session, as well as all other devices that may 
                be currently logged in to your account. "Remember Me" will also be removed for 
                all devices. 
            </GenericModal>
        );
    }
}

export default LogOutEverywhereModal;
