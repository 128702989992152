import React from 'react';

import '../styles/ExternalLink.scss';

interface LinkProps {
    link: string;
    children: any;
}

const ExternalLink = (props: LinkProps) => {
    return (
        <span
            className='external-link'
            onClick={e => {
                e.preventDefault();
                window.open(props.link);
            }}
        >
            {props.children}
        </span>
    );
};

export default ExternalLink;
