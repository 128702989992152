import React, { Component } from "react";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";

class RejectModal extends Component<GenericModalProps> {
    render() {
        let otherType = "",
            eventType = "";
        if (this.props.appState.userType === "doctor") {
            otherType = "practice";
            eventType = "offer";
        } else if (this.props.appState.userType === "practice") {
            otherType = "doctor";
            eventType = "application";
        }

        let name = this.props.event[otherType].name;

        let successMessage = name + "'s " + eventType + " has been rejected.";

        let api: string;
        if (this.props.appState.userType === "doctor") {
            api = "/offers/delete/";
        } else if (this.props.appState.userType === "practice") {
            api = "/applications/delete/";
        } else {
            return <div />;
        }

        let requestParams = {
            url: api + this.props.event.id,
            method: "post"
        };

        return (
            <GenericModal
                {...this.props}
                title={"Rejecting the job " + eventType}
                yesBtn="Reject"
                noBtn="No"
                requestParams={requestParams}
                successMessage={successMessage}
            >
                Are you sure you want to reject {name}'s {eventType}?
            </GenericModal>
        );
    }
}

export default RejectModal;
