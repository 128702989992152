import React, { Component } from "react";
// import { Job } from "../pages/Jobabilities";
import ListItem from "./ListItem";
import {
    RejectBtn,
    MessageBtn,
    RetractBtn,
    AcceptOfferBtn,
    ProposeEditBtn,
    MarkJobCompletedBtn,
    SendInvoiceBtn,
    CancelBookingBtn,
    ViewInvoiceBtn,
    SendOfferBtn,
    RetractEditBtn,
    AcceptEditBtn,
    RejectEditBtn,
    SendApplicationBtn,
    ClashingAcceptOfferBtn
} from "../Buttons";
import { AppState } from "../../App";
import { printRoles } from "../../utils";

import List from "../List";
import EmptyState from "../EmptyState";
import { NavLink } from "react-router-dom";
import { IconName } from "@fortawesome/pro-solid-svg-icons";

interface JobListItemsProps {
    data: any;
    appState: AppState;
    onUpdate: any;
    eventType: string;
    hideTimeMarkers?: boolean;
    bookingType?: string;
}

interface JobListItemProps {
    item: any;
    appState: AppState;
    onUpdate: any;
    eventType: string;
}

function getMessageType(eventType: string): string {
    let messageEventType = "";
    switch (eventType) {
        case "incoming applications":
            messageEventType = "your job application";
            break;
        case "incoming offers":
            messageEventType = "your job offer";
            break;
        case "outgoing applications":
            messageEventType = "my job application";
            break;
        case "outgoing offers":
            messageEventType = "my job offer";
            break;
        case "booking":
            messageEventType = "our booking";
            break;
    }
    return messageEventType;
}

function getButtons(
    job: any,
    appState: AppState,
    onUpdate: any,
    showMessageBtn: boolean
) {
    let btns: any[] = [];

    let btnProps = {
        appState: appState,
        event: JSON.parse(JSON.stringify(job)), // to properly clone the object (so we can edit it if need be)
        onSuccess: onUpdate
    };

    // We set eventType for each event, depending on the status ID and the user type
    let eventType = "booking";
    if (job.statusID === 5) {
        // Doctor requested
        if (appState.userType === "doctor") {
            eventType = "outgoing applications";
        } else if (appState.userType === "practice") {
            eventType = "incoming applications";
        }
    } else if (job.statusID === 6) {
        // Practice requested
        if (appState.userType === "doctor") {
            eventType = "incoming offers";
        } else if (appState.userType === "practice") {
            eventType = "outgoing offers";
        }
    } else if (job.statusID === 0) {
        eventType = "posted-availability";
    }

    if (eventType === "booking") {
        switch (job.statusID) {
            case 1: // Upcoming
                btns.push(
                    <ProposeEditBtn {...btnProps} key={job.id + "-editbtn"} />
                );
                if (appState.userType === "doctor") {
                    // Able to mark booking as completed
                    btns.push(
                        <MarkJobCompletedBtn
                            {...btnProps}
                            key={job.id + "-markcompletebtn"}
                        />
                    );
                }
                btns.push(
                    <CancelBookingBtn
                        {...btnProps}
                        key={job.id + "-cancelbtn"}
                    />
                );
                break;
            case 2: // Confirmed, not invoiced
                if (appState.userType === "doctor") {
                    btns.push(
                        <SendInvoiceBtn
                            {...btnProps}
                            event={job}
                            key={job.id + "-sendinvoicebtn"}
                        />
                    );
                }
                break;
            case 3: // Confirmed, invoice sent
                // Add button
                btns.push(
                    <ViewInvoiceBtn
                        {...btnProps}
                        event={job.invoice}
                        key={job.id + "-viewinvoicebtn"}
                    />
                );
                break;
            case 7: // Doctor requesting edit
                btns.push(
                    <ProposeEditBtn {...btnProps} key={job.id + "-editbtn"} />
                );
                if (appState.userType === "doctor") {
                    // Cancel request
                    btns.push(
                        <RetractEditBtn
                            {...btnProps}
                            key={job.id + "-retracteditbtn"}
                        />
                    );
                } else if (appState.userType === "practice") {
                    // Accept or reject
                    btns.push([
                        <AcceptEditBtn
                            {...btnProps}
                            key={job.id + "-accepteditbtn"}
                        />,
                        <RejectEditBtn
                            {...btnProps}
                            key={job.id + "-rejecteditbtn"}
                        />
                    ]);
                }
                btns.push(
                    <CancelBookingBtn
                        {...btnProps}
                        key={job.id + "-cancelbtn"}
                    />
                );
                break;
            case 8: // Practice requesting edit
                btns.push(
                    <ProposeEditBtn {...btnProps} key={job.id + "-editbtn"} />
                );
                if (appState.userType === "doctor") {
                    // Accept or reject
                    btns.push([
                        <AcceptEditBtn
                            {...btnProps}
                            key={job.id + "-accepteditbtn"}
                        />,
                        <RejectEditBtn
                            {...btnProps}
                            key={job.id + "-rejecteditbtn"}
                        />
                    ]);
                } else if (appState.userType === "practice") {
                    // Cancel request
                    btns.push(
                        <RetractEditBtn
                            {...btnProps}
                            key={job.id + "-retracteditbtn"}
                        />
                    );
                }
                btns.push(
                    <CancelBookingBtn
                        {...btnProps}
                        key={job.id + "-cancelbtn"}
                    />
                );
                break;
        }
    } else if (eventType === "posted-availability") {
        if (appState.userType === "doctor") {
            btns = [
                <SendApplicationBtn {...btnProps} key={job.id + "-apply"} />
            ];
        } else {
            btnProps.event.id = 0;
            btns = [<SendOfferBtn {...btnProps} key={job.id + "-offer"} />];
        }
    } else if (eventType.startsWith("outgoing")) {
        btns = [<RetractBtn {...btnProps} key={job.id + "-retractbtn"} />];
    } else if (eventType.startsWith("incoming")) {
        if (eventType === "incoming applications") {
            btns.push(
                <SendOfferBtn
                    {...btnProps}
                    event={job}
                    key={job.id + "-acceptbtn"}
                />
            );
        } else if (eventType === "incoming offers") {
            if (job.clashes != null && job.clashes.length > 0) {
                btns.push(
                    <ClashingAcceptOfferBtn
                        {...btnProps}
                        key={job.id + "-clashingacceptbtn"}
                        eventType={getMessageType(eventType)}
                    />
                );
            } else {
                btns.push(
                    <AcceptOfferBtn {...btnProps} key={job.id + "-acceptbtn"} />
                );
            }
        }
        btns.push(<RejectBtn {...btnProps} key={job.id + "-rejectbtn"} />);
    }

    if (showMessageBtn) {
        btns.push(
            <MessageBtn
                {...btnProps}
                key={job.id + "-msgbtn"}
                eventType={getMessageType(eventType)}
            />
        );
    }

    return btns;
}

export class JobListItem extends Component<JobListItemProps> {
    render() {
        let job = this.props.item;
        let btns = getButtons(
            job,
            this.props.appState,
            this.props.onUpdate,
            this.props.eventType !== "profile"
        );

        let statusMessage = "";
        if (this.props.eventType === "booking") {
            if (job.status === 2) {
                statusMessage = "Invoice not yet sent";
            } else {
                // Invoice sent already. Show status
                statusMessage = job.invoice.statusStr;
            }
        }
        let status = <div className="job-status-text">{statusMessage}</div>;

        return (
            <ListItem
                event={this.props.item}
                btns={btns}
                eventType={this.props.eventType}
                appState={this.props.appState}
                onUpdate={this.props.onUpdate}
            >
                <div>{printRoles(this.props.item, this.props.appState)}</div>
                {status}
            </ListItem>
        );
    }
}

const NoJobsFound: React.FC<{
    eventType: string;
    bookingType?: string;
    userType: string;
}> = props => {
    let noEntriesText = <span />;
    let heading = "";
    let icon: IconName = "calendar-times";

    const MyAvailabilities = (
        <NavLink to="/my-availabilities">“My Availabilities” page</NavLink>
    );
    const MyAvailableSessions = (
        <NavLink to="/my-available-sessions">
            “My Available Sessions” page
        </NavLink>
    );
    const IncomingApplications = (
        <NavLink to="/applications">“Incoming Applications” page</NavLink>
    );

    switch (props.eventType) {
        case "outgoing applications":
            heading = "No pending applications found";
            noEntriesText = (
                <span>
                    You can send a job application by selecting a match in the{" "}
                    {MyAvailabilities}.
                </span>
            );
            break;
        case "outgoing offers":
            heading = "No pending offers found";
            noEntriesText = (
                <span>
                    You can send a job offer by selecting a match in the{" "}
                    {MyAvailableSessions}, by responding to any available job
                    applications in the {IncomingApplications}, or by sending a
                    bespoke job from a locum's profile.
                </span>
            );
            break;
        case "incoming applications":
            heading = "You have no job applications at the moment";
            noEntriesText = (
                <span>
                    In the meantime, you can send some job offers from any
                    matches in the {MyAvailableSessions}.
                </span>
            );
            break;
        case "incoming offers":
            heading = "You have no job offers at the moment";
            noEntriesText = (
                <span>
                    In the meantime, you can send some job applications from any
                    matches the {MyAvailabilities}.
                </span>
            );
            break;
        case "booking":
            switch (props.bookingType) {
                case "upcoming":
                    heading = "You have no upcoming bookings";
                    if (props.userType === "doctor") {
                        noEntriesText = (
                            <span>
                                You will need to accept any{" "}
                                <NavLink to="/offers">incoming offers.</NavLink>
                            </span>
                        );
                    } else {
                        noEntriesText = <span />;
                    }
                    break;

                case "invoice-not-yet-sent":
                    icon = "file-invoice";
                    heading = "You have no jobs that are yet to be invoiced";
                    if (props.userType === "doctor") {
                        noEntriesText = (
                            <span>
                                You will need to{" "}
                                <NavLink to="/bookings/upcoming">
                                    mark jobs as completed
                                </NavLink>{" "}
                                in order to invoice them.
                            </span>
                        );
                    }
                    break;
                case "pending-payment":
                    icon = "money-bill-wave";
                    heading = "You have no jobs that are pending payment";
                    if (props.userType === "doctor") {
                        noEntriesText = (
                            <span>
                                If you have any completed jobs, you can{" "}
                                <NavLink to="/bookings/invoice-not-yet-sent">
                                    send them as part of an invoice
                                </NavLink>
                                .
                            </span>
                        );
                    }
                    break;

                case "paid":
                    icon = "money-bill-wave";
                    heading = "You have no paid jobs (yet)";
                    if (props.userType === "doctor") {
                        noEntriesText = (
                            <div>
                                <p>
                                    Make sure you have marked the jobs that you
                                    have done as "completed", and sent the
                                    invoice to the practices.
                                </p>
                                <p>
                                    The practices will then be able to mark them
                                    as paid.{" "}
                                </p>
                            </div>
                        );
                    } else {
                        noEntriesText = (
                            <span>
                                If you have any invoiced jobs, please mark them
                                as paid after you have paid the locum.
                            </span>
                        );
                    }
                    break;
            }
            break;
        case "profile":
            heading = "No past or upcomings job with this user";
    }
    return (
        <EmptyState icon={icon} heading={heading}>
            {noEntriesText}
        </EmptyState>
    );
};

const JobListItems: React.FC<JobListItemsProps> = props => {
    return (
        <List
            {...props}
            itemComponent={JobListItem}
            noEntries={
                <NoJobsFound
                    eventType={props.eventType}
                    bookingType={props.bookingType}
                    userType={props.appState.userType}
                />
            }
            hideTimeMarkers={props.hideTimeMarkers}
        />
    );
};

export default JobListItems;
