import React from 'react';
import { CalState } from './Calendar';
import { addDays, addWeeks, endOfMonth, format, getDate, getMonth, startOfMonth, startOfWeek } from 'date-fns';
import { calObj } from '../interfaces';

const MonthHeader = () =>
    <thead>
    <tr>
        <th>Sun</th>
        <th>Mon</th>
        <th>Tue</th>
        <th>Wed</th>
        <th>Thu</th>
        <th>Fri</th>
        <th>Sat</th>
    </tr>
    </thead>;

const MonthBodyCell = (props: {
    date: Date;
    events: calObj[];
    fade?: boolean;
}) =>
    <td className={'cal-cell ' + (props.fade ? 'cal-fade' : '')}>
        <div className='cal-cell-date'>{getDate(props.date)}</div>
    </td>;

const MonthBodyWeek = (props: CalState & { currentWeek: Date }) => {
    let days = [];
    for (let i = 0; i < 7; i++) {
        let currDay = addDays(props.currentWeek, i);
        let key = format(currDay, 'yyyyMMdd');
        let fade = getMonth(currDay) !== getMonth(props.currentDay);
        days.push(
            <MonthBodyCell
                key={key}
                date={currDay}
                events={props.events}
                fade={fade}
            />,
        );
    }
    return <tr>{days}</tr>;
};

const MonthBody = (props: CalState) => {
    let startWeek = startOfWeek(startOfMonth(props.currentDay));
    let endWeek = startOfWeek(endOfMonth(props.currentDay));
    let weeks = [];

    for (let i = startWeek; i <= endWeek; i = addWeeks(i, 1)) {
        weeks.push(
            <MonthBodyWeek
                key={format(i, 'yyyyMMdd')}
                currentWeek={i}
                {...props}
            />,
        );
    }

    return <tbody>{weeks}</tbody>;
};

const MonthView = (props: CalState) => {
    return (
        <table id='month-view'>
            <MonthHeader />
            <MonthBody {...props} />
        </table>
    );
};

export default MonthView;
