import React from 'react';
import { NavLink } from 'react-router-dom';

const InvoicesNavItem = (props: { link: string; children?: any }) => <NavLink
    className='secondary-nav-item'
    activeClassName='secondary-nav-item-active'
    to={'/invoices/' + props.link}
>
    {props.children}
</NavLink>;

const InvoicesNav = () =>
    <div id='secondary-nav'>
        <InvoicesNavItem link='pending-payment'>Pending payment</InvoicesNavItem>
        <InvoicesNavItem link='paid'>Paid</InvoicesNavItem>
        <InvoicesNavItem link='all'>All</InvoicesNavItem>
    </div>;

export default InvoicesNav;
