import React, { Component } from 'react';
import { CompProps } from '../Main';

import '../styles/Calendar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIResp, fetchAPI } from '../utils';
import { calObj } from '../interfaces';
/*import { AppState } from "../App";*/
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { addMonths, format, startOfMonth, subMonths } from 'date-fns';
import AgendaView from './CalAgendaView';
import MonthView from './CalMonthView';
import Loading from './Loading';

var $ = require('jquery');

export interface CalState {
    fetchingData: boolean;
    currentDay: Date;
    events: calObj[];
}

interface HeaderProps {
    currentDay: Date;

    setDay(day: Date): void;
}

/*interface CalProps {
    appState: AppState;
    currentDay: Date;
    setDay: any;
    events: calObj[];
}*/

class MonthViewHeader extends Component<HeaderProps> {
    render() {
        let month = format(this.props.currentDay, 'MMMM yyyy');
        let lastMonth = subMonths(this.props.currentDay, 1);
        let nextMonth = addMonths(this.props.currentDay, 1);
        return (
            <div className='cal-header'>
                <div
                    className='cal-header-btn'
                    onClick={() => this.props.setDay(lastMonth)}
                >
                    <FontAwesomeIcon icon='chevron-left' fixedWidth />
                </div>
                <div className='cal-header-text'>
                    <h2>{month}</h2>
                </div>
                <div
                    className='cal-header-btn'
                    onClick={() => this.props.setDay(nextMonth)}
                >
                    <FontAwesomeIcon icon='chevron-right' fixedWidth />
                </div>
            </div>
        );
    }
}

class Calendar extends Component<
    CompProps & { onDashboard?: boolean } & RouteComponentProps<{}>,
    CalState
> {
    constructor(
        props: CompProps & { onDashboard?: boolean } & RouteComponentProps<{}>,
    ) {
        super(props);
        if (!this.props.onDashboard) {
            this.props.setTitle('My Calendar');
        }

        this.state = {
            currentDay: startOfMonth(new Date()),
            events: [],
            fetchingData: true,
        };

        if (this.props.location.pathname === '/calendar') {
            this.props.history.push('/calendar/agenda');
        }

        this.fetchEvents();
    }

    componentDidUpdate() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    setDay = (day: Date) => {
        this.setState({ currentDay: day }, this.fetchEvents);
    };

    updateState = (resp: APIResp) => {
        if (resp.data.ok) {
            this.setState({ fetchingData: false, events: resp.data.data });
            this.props.appState.setEvents(resp.data.data);
            this.props.appState.updateUICounts(resp.data);
        } else {
            // Display error
        }
    };

    fetchEvents = () => {
        let dateStr = format(this.state.currentDay, 'yyyy-MM-dd');
        fetchAPI(
            { url: '/calendar/' + dateStr },
            this.updateState,
            this.props.appState,
        );
    };

    onUpdate = (message: string) => {
        this.props.setSuccessMessage(message);
        this.fetchEvents();
    };

    render() {
        let agendaView = (
            <AgendaView
                {...this.state}
                appState={this.props.appState}
                onUpdate={this.onUpdate}
            />
        );
        return (
            <div id='calendar' className='row'>
                {/* {!this.props.onDashboard && <CalendarNav />} */}
                <MonthViewHeader
                    currentDay={this.state.currentDay}
                    setDay={this.setDay}
                />
                <Loading loading={this.state.fetchingData}><>
                    {!this.props.onDashboard && (
                        <Switch>
                            <Route
                                path='/calendar/agenda'
                                render={() => agendaView}
                            />
                            <Route
                                path='/calendar/month'
                                render={() => <MonthView {...this.state} />}
                            />
                        </Switch>
                    )}
                    {this.props.onDashboard && agendaView}
                </>
                </Loading>
            </div>
        );
    }
}

export default withRouter(Calendar);
