import React, { Component } from 'react';

import '../../styles/ListItem.scss';
import MatchesListItems from './MatchesListItems';
import { AppState } from '../../App';
import { formatTime, formatMoney, calcAmount, calcEditAmount } from '../../utils';
import { calObj } from '../../interfaces';
import { format, isEqual } from 'date-fns';
import { NavLink } from 'react-router-dom';

interface SelectItemProps {
    selected: boolean;
    onUpdate: any;
}

class SelectItem extends Component<SelectItemProps> {
    updateState = () => {
        this.props.onUpdate('selected', !this.props.selected);
    };

    render() {
        return (
            <div>
                <input type="checkbox" checked={this.props.selected} onChange={this.updateState} />
            </div>
        );
    }
}

class Datetime extends Component<{ event: calObj; eventType: string; userType: string }> {
    render() {
        let date = new Date(this.props.event.date);
        let dateContent = <span>{format(date, 'eee, d MMM')}</span>;
        let timeContent: JSX.Element;

        if (this.props.eventType === 'invoice') {
            timeContent = <span>{format(date, 'hh:mmaaa')}</span>;
        } else {
            timeContent = (
                <span>
                    {formatTime(this.props.event.startTime)} &ndash;{' '}
                    {formatTime(this.props.event.endTime)}
                </span>
            );

            if (this.props.event.proposedEdit.endTime > 0) {
                // endTime can only be 0 if unset by backend
                let proposedDate = new Date(this.props.event.proposedEdit.date);

                if (!isEqual(date, proposedDate)) {
                    dateContent = (
                        <span>
                            <span className="strike-off">{format(date, 'eee, d MMM')}</span>{' '}
                            <span>{format(proposedDate, 'eee, d MMM')}</span>
                        </span>
                    );
                }

                if (
                    this.props.event.startTime !== this.props.event.proposedEdit.startTime ||
                    this.props.event.endTime !== this.props.event.proposedEdit.endTime
                ) {
                    timeContent = (
                        <span>
                            <span className="strike-off">
                                {formatTime(this.props.event.startTime)} &ndash;{' '}
                                {formatTime(this.props.event.endTime)}
                            </span>{' '}
                            <span>
                                {formatTime(this.props.event.proposedEdit.startTime)} &ndash;{' '}
                                {formatTime(this.props.event.proposedEdit.endTime)}
                            </span>
                        </span>
                    );
                }
            }
        }

        return (
            <div className="list-item-datetime">
                {!this.props.eventType.startsWith('agenda') && (
                    <div className="list-item-date">{dateContent}</div>
                )}
                <div className="list-item-time">{timeContent}</div>
                {this.props.eventType.startsWith('agenda') && this.props.event.category != null && (
                    <div className="list-item-category">
                        {getTooltip(this.props.event.category, this.props.userType)}
                    </div>
                )}
            </div>
        );
    }
}

const Name = (props: {link: string; children: any}) =>
            <div className="list-item-name">
                <NavLink to={'/view-profile/' + props.link}>{props.children}</NavLink>
            </div>

const DoctorProfile = (props: { event: calObj }) => {
    if (props.event.doctor.name !== '') {
        return <Name link={props.event.doctor.hash}>{props.event.doctor.name}</Name>;
    } else {
        return <div />;
    }
};

const PracticeProfile = (props: { appState: AppState; event: calObj }) => {
    if (props.event.practice.name !== '') {
        return (
            <Name link={props.event.practice.hash}>
                {props.event.practice.name} ({props.appState.boroughs[props.event.practice.borough]}
                )
            </Name>
        );
    } else {
        return <div />;
    }
};

const ItemStatus = (props: { event: calObj }) => {
        let status = props.event.invoice.statusStr;
        switch (props.event.statusID) {
            case 1:
                status = 'Upcoming booking';
                break;
            case 2:
                status = 'Not yet invoiced';
                break;
            case 3:
                break;
            case 4:
                status = 'Cancelled';
                break;
            case 5:
                status = 'Job Application';
                break;
            case 6:
                status = 'Job Offer';
                break;
            case 7:
            case 8:
                status = 'Proposed booking edit';
                break;
            case 11:
                status = 'Rejected Offer';
                break;
            case 12:
                status = 'Rejected Application';
                break;
            default:
                status = props.event.statusID;
                break;
        }
        return <div className="list-item-name">{status}</div>;
}

const Profile = (props: { appState: AppState; event: calObj; userType: string }) => {
    if (props.userType === 'doctor') {
        return <PracticeProfile appState={props.appState} event={props.event} />;
    } else if (props.userType === 'practice') {
        return <DoctorProfile event={props.event} />;
    } else {
        return <div />;
    }
};

class Body extends Component<{
    children: any;
    onClick: any;
    clickable: boolean;
}> {
    render() {
        return (
            <div
                className={
                    'list-item-body ' + (this.props.clickable ? ' list-item-body-clickable' : '')
                }
                onClick={this.props.onClick}
            >
                {this.props.children}
            </div>
        );
    }
}

class Money extends Component<{ event: any; eventType: string }> {
    render() {
        let pensionText: JSX.Element;
        if (this.props.event.pensionRate > 0) {
            pensionText = <span className="no-wrap">plus pension</span>;
        } else {
            pensionText = <span className="no-wrap">no pension</span>;
        }

        if (this.props.eventType.endsWith('availability')) {
            return (
                <div className="list-item-money">
                    {formatMoney(this.props.event.rate)}
                    {this.props.event.fixedRate ? '' : '/hr'}
                </div>
            );
        } else if (this.props.eventType.startsWith('invoice')) {
            let amount = 0;
            if (this.props.eventType === 'invoice') {
                for (let i = 0; i < this.props.event.entries.length; i++) {
                    amount += calcAmount(this.props.event.entries[i]);
                }
            } else {
                amount = calcAmount(this.props.event);
            }

            return (
                <div className="list-item-money">
                    <div>{formatMoney(amount)}</div>
                </div>
            );
        } else {
            let amount = calcAmount(this.props.event);
            let amountElement = <span>{formatMoney(amount)}</span>;

            if (this.props.event.proposedEdit.endTime > 0 && !this.props.event.fixedRate) {
                let proposedAmount = calcEditAmount(this.props.event);
                if (amount !== proposedAmount) {
                    amountElement = (
                        <div>
                            <span className="strike-off">{formatMoney(amount)}</span>
                            <br />
                            <span>{formatMoney(proposedAmount)}</span>
                        </div>
                    );
                }
            }

            let rateInfo = 'fixed rate';
            if (!this.props.event.fixedRate) {
                rateInfo = formatMoney(this.props.event.rate) + '/hr';
            }

            return (
                <div className="list-item-money">
                    <div>{amountElement}</div>
                    <div className="list-item-money-rate">
                        ({rateInfo}
                        {!this.props.eventType.startsWith('incoming') &&
                            !this.props.eventType.startsWith('outgoing') && (
                                <span>, {pensionText}</span>
                            )}
                        )
                    </div>
                </div>
            );
        }
    }
}

class Buttons extends Component<{ btns: any }> {
    render() {
        return <div className="list-item-btns">{this.props.btns}</div>;
    }
}

interface AdditionalHoursProps {
    value: any;
    onUpdate: any;
}

class AdditionalHours extends Component<AdditionalHoursProps> {
    updateState = (e: any) => {
        this.props.onUpdate('additionalHours', e.target.value);
    };

    cleanState = () => {
        let value = parseFloat(this.props.value);
        if (isNaN(value)) {
            value = 0;
        }
        this.props.onUpdate('additionalHours', value);
    };

    render() {
        return (
            <div className="input-group list-additional-hours">
                <div className="input-group-prepend">
                    <span className="input-group-text">+</span>
                </div>
                <input
                    type="number"
                    className="form-control"
                    aria-label="Number of additional hours"
                    value={this.props.value}
                    onChange={this.updateState}
                    onBlur={this.cleanState}
                />
                <div className="input-group-append">
                    <span className="input-group-text">hrs</span>
                </div>
            </div>
        );
    }
}

function getTooltip(category: string, userType: string): string {
    let tooltip = '';
    switch (category) {
        case 'Availability':
            if (userType === 'doctor') {
                tooltip = 'Availability';
            } else if (userType === 'practice') {
                tooltip = 'Available session';
            }
            break;
        case 'Doctor requested':
            if (userType === 'doctor') {
                tooltip = 'Pending application';
            } else if (userType === 'practice') {
                tooltip = 'Incoming application';
            }
            break;
        case 'Practice requested':
            if (userType === 'doctor') {
                tooltip = 'Incoming offer';
            } else if (userType === 'practice') {
                tooltip = 'Pending offer';
            }
            break;
        case 'Upcoming':
            tooltip = 'Upcoming job';
            break;
        case 'Cancelled':
            tooltip = 'Cancelled job';
            break;
        case 'Completed':
        case 'Invoiced':
            tooltip = 'Completed';
            break;
        case 'Doctor declined':
            tooltip = 'Rejected offer';
            break;
        case 'Practice declined':
            tooltip = 'Rejected application';
            break;
    }

    return tooltip;
}

const AgendaColour = (props: {
    category: string;
    userType: string;
}) => {
    let className = 'agenda-colour ';
    switch (props.category) {
        case 'Availability':
            className += 'availability';
            break;
        case 'Doctor requested':
            if (props.userType === 'doctor') {
                className += 'outgoing';
            } else if (props.userType === 'practice') {
                className += 'incoming';
            }
            break;
        case 'Practice requested':
            if (props.userType === 'doctor') {
                className += 'incoming';
            } else if (props.userType === 'practice') {
                className += 'outgoing';
            }
            break;
        case 'Upcoming':
            className += 'upcoming';
            break;
        case 'Cancelled':
            className += 'cancelled';
            break;
        case 'Completed':
        case 'Invoiced':
            className += 'completed';
            break;
        case 'Doctor declined':
            className += 'declined';
            break;
        case 'Practice declined':
            className += 'declined';
            break;
    }

    return <div className={className} />;
};

interface Props {
    appState: AppState;
    onUpdate: any;

    event: any;
    btns: any;
    eventType: string;
    matches?: any;
    state?: any;
    children?: any;
}

interface State {
    showMatches: boolean;
}

class ListItem extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showMatches: false,
        };
    }

    toggleShowMatches = () => {
        if (this.props.matches != null) {
            this.setState({ showMatches: !this.state.showMatches });
        }
    };

    updateState = (field: string, newValue: any) => {
        let newState = this.props.state;
        newState[field] = newValue;
        this.props.onUpdate(this.props.event.id, newState);
    };

    render() {
        let disabledClass = '';
        if (this.props.eventType === 'invoice-request' && !this.props.state.selected) {
            disabledClass += ' list-item-disabled';
        } else if (this.props.event.category != null) {
            if (this.props.event.category === 'Cancelled') {
                disabledClass += ' list-item-cancelled';
            } else if (this.props.event.category.endsWith('declined')) {
                disabledClass += ' list-item-declined';
            }
        }

        // We define it like this so that we can have the animations of the items appearing/disappearing
        let matches = [];
        if (this.state.showMatches) {
            matches = this.props.matches;
        }

        return (
            <div className="list-item-container">
                <div
                    className={
                        (this.props.matches != null ? ' list-item-clickable' : '') +
                        (this.state.showMatches ? ' list-item-clickable-active' : '')
                    }
                >
                    <div className={'list-item' + disabledClass}>
                        {/* {this.props.eventType.startsWith('agenda') && ( */}
                        <AgendaColour
                            category={this.props.event.category}
                            userType={this.props.appState.userType}
                        />
                        {/* )} */}
                        <div className="list-item-row">
                            {this.props.eventType === 'invoice-request' && (
                                <SelectItem
                                    selected={this.props.state.selected}
                                    onUpdate={this.updateState}
                                />
                            )}

                            <Datetime
                                event={this.props.event}
                                eventType={this.props.eventType}
                                userType={this.props.appState.userType}
                            />
                            <div className="list-item-profile-body">
                                {this.props.eventType !== 'availability' &&
                                    this.props.eventType !== 'profile' &&
                                    this.props.eventType !== 'invoice-request' && (
                                        <Profile
                                            appState={this.props.appState}
                                            event={this.props.event}
                                            userType={this.props.appState.userType}
                                        />
                                    )}
                                {this.props.eventType === 'profile' && (
                                    <ItemStatus event={this.props.event} />
                                )}
                                <Body
                                    children={this.props.children}
                                    onClick={this.toggleShowMatches}
                                    clickable={this.props.matches != null}
                                />
                            </div>
                            {this.props.eventType === 'invoice-request' && (
                                <AdditionalHours
                                    value={this.props.state.additionalHours}
                                    onUpdate={this.updateState}
                                />
                            )}
                            <Money event={this.props.event} eventType={this.props.eventType} />
                            {this.props.eventType !== 'invoice-request' && (
                                <Buttons btns={this.props.btns} />
                            )}
                        </div>
                    </div>
                </div>
                {this.props.matches != null && (
                    <MatchesListItems
                        data={matches}
                        appState={this.props.appState}
                        onUpdate={this.props.onUpdate}
                        eventType={this.props.eventType}
                    />
                )}
            </div>
        );
    }
}

export default ListItem;
