import React, { Component } from "react";
import { CompProps } from "../Main";

import "../styles/EditProfile.scss";
import ProfileNav from "./Navs/ProfileNav";
import { withRouter, RouteComponentProps, Switch, Route } from "react-router";
import Details from "./EditProfile/ProfileDetails";
import NotificationOptions from "./EditProfile/NotificationOptions";
import PrivacyOptions from "./EditProfile/PrivacyOptions";
import { ComplianceDocs } from './EditProfile/ComplianceDocs';

interface Props extends CompProps, RouteComponentProps<{}> {}

class EditProfile extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.props.setTitle("Edit Profile");

        if (this.props.location.pathname === "/edit-profile") {
            this.props.history.push("/edit-profile/details");
        }
    }

    render() {
        return (
            <div id="edit-profile">
                <ProfileNav appState={this.props.appState} />
                <Switch>
                    <Route
                        path="/edit-profile/details"
                        render={() => <Details {...this.props} />}
                    />
                    <Route
                        path="/edit-profile/notifications"
                        render={() => <NotificationOptions {...this.props} />}
                    />
                    <Route
                        path="/edit-profile/privacy"
                        render={() => <PrivacyOptions {...this.props} />}
                    />
                    <Route
                        path='/edit-profile/compliance'
                        render={() => <ComplianceDocs appState={this.props.appState} />}
                    />
                </Switch>
            </div>
        );
    }
}

export default withRouter(EditProfile);
