import React, { Component } from 'react';
import { Role } from '../App';

interface RolesInputProps {
	roles: Role[];
	value: number[];
	onChange: any;
}

interface RoleCheckboxProps {
	id: number;
	name: string;
	selected: boolean;
	onChange: any;
	// description: string;
}

class RoleCheckbox extends Component<RoleCheckboxProps> {
	render() {
		return (
			<div className="form-check">
				<input
					className="form-check-input"
					type="checkbox"
					value={this.props.id}
					id={'role' + this.props.id}
					checked={this.props.selected}
					onChange={() => this.props.onChange(this.props.id)}
				/>
				<label className="form-check-label" htmlFor={'role' + this.props.id}>
					{this.props.name}
				</label>
			</div>
		);
	}
}

export class RolesInput extends Component<RolesInputProps> {
	constructor(props: RolesInputProps) {
		super(props);
		this.updateSelected = this.updateSelected.bind(this);
	}

	updateSelected(id: number) {
		let newValues = this.props.value;
		if (this.props.value.includes(id)) {
			// Remove
			newValues = newValues.filter((item) => !(item === id));
		} else {
			newValues.push(id);
		}
		this.props.onChange(newValues);
	}

	render() {
		let checkboxes = [];
		for (let i = 0; i < this.props.roles.length; i++) {
			if (!this.props.roles[i].enabled) {
				continue;
			}
			let selected = this.props.value.includes(this.props.roles[i].id);
			checkboxes.push(
				<RoleCheckbox {...this.props.roles[i]} key={i} selected={selected} onChange={this.updateSelected} />
			);
		}
		return <div>{checkboxes}</div>;
	}
}
