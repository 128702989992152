import React, { Component } from "react";

class AuthenticationBox extends Component<{
    name: string,
    changeStatus(status: string, type: string, title: string): void,
    type: string
}, {}> {
    render() {
        var changeStatus = this.props.changeStatus;
        return (
            <div className='auth-box'>
                <h2>{this.props.name}</h2>
                <div className='row'>
                    <button className='btn btn-primary col-md-12' onClick={() => changeStatus('login', this.props.type, this.props.name + " Login")}>Login</button>
                </div>
                {/* <div className='row'>
                    <button className='btn btn-secondary col-md-12' onClick={() => changeStatus('signup', this.props.type, this.props.name + " Sign Up")}>Sign Up</button>
                </div> */}
            </div>
        );
    }
}

export default AuthenticationBox;
