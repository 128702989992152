import React from 'react';
import LegalDoc from './LegalDoc';
import { NavLink } from 'react-router-dom';

const privacyPolicy = <NavLink to='/privacy'>Privacy Policy</NavLink>;

const CookiePolicy = () => {
    return (
        <LegalDoc title='Cookie Policy'>
            <h2>1. What are Cookies?</h2>
            <p>
                Cookies are small pieces of data (text files) that a website –
                when visited by a user – asks your browser to store on your
                device in order to remember information about you, such as your
                login information. Cookies contain information that is
                transferred to your computer’s hard drive.
            </p>
            <p>
                We use two types of cookies – those which are essential to
                provide you with the Versatile GP services you have requested
                and others which enable us to improve the quality of those
                services. They are set by us, and described in more detail under
                the headings ‘Essential Cookies’ and ‘Non-Essential Cookies’
                below. In brief terms, however, our website uses essential
                cookies to distinguish you from other users of our website
                during your visit to our website. This is essential in order to
                provide you with the Versatile GP services. We also use
                non-essential cookies, with your agreement, to remember you
                across browser sessions and, separately, to aggregate data from
                cookies on an anonymous basis by measuring how many users
                undertake particular actions on our website, so that we can
                monitor and improve our services.
            </p>
            <p>
                The particular cookies we use are set out below under the
                relevant headings.
            </p>

            <h2>2. Essential Cookies</h2>
            <p>
                We use cookies to recognise our users – when you login, a cookie
                is created and stored on your browser, thus acting as an
                "identification card" that identifies you as a logged in user.
                This cookie is only valid for 30 minutes.{' '}
            </p>
            <p>
                Disabling these cookies will not allow us to recognise you as an
                authenticated user, and will prevent your use of the website.
                These particular cookies are therefore essential in order to
                provide you with the Versatile GP service over the internet, as
                we cannot provide that service to you without them. As those
                cookies are therefore essential in order to provide you with a
                service that you have requested, the law does not require us to
                obtain your consent in order to use them.
            </p>
            <p>The essential cookies which we use are as follows:</p>

            <table>
                <thead>
                <tr>
                    <th>Name of Cookies</th>
                    <th>Purpose</th>
                    <th>More information</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>token</td>
                    <td>Allows us to recognise you as a logged in user.</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>cfduid</td>
                    <td>
                        Allows us to identify individual website users where
                        they are using a shared IP address, and apply
                        individualised security settings.
                    </td>
                    <td>
                        These cookies are administered by a third party,
                        called Cloudflare, and further details can be found
                        at the following link:{' '}
                        <a href='https://support.cloudflare.com/hc/en-us/articles/200170156-What-does-the-Cloudflare-cfduid-cookie-do-'>
                            https://support.cloudflare.com/hc/en-us/articles/200170156-What-does-the-Cloudflare-cfduid-cookie-do-
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
            <h2>3. Non-Essential Cookies</h2>
            <p>
                A ‘non-essential cookie’ is a term we use to describe those
                cookies which are not strictly necessary in order to provide you
                with the Versatile GP service, but nonetheless improve the
                quality of the services you receive. These fall into two broad
                categories as summarised below, but we will only use them where
                you have provided your specific positive consent to us doing so.
            </p>
            <p>
                A brief summary of the non-essential cookies we use is as
                follows:
            </p>

            <table>
                <thead>
                <tr>
                    <th>Name of Cookies</th>
                    <th>Purpose</th>
                    <th>More information</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>rmbme</td>
                    <td>
                        Allows us to recognise you as a “remembered” user.
                    </td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>cookiePermissions</td>
                    <td>Allows us to remember your cookie preferences.</td>
                    <td>&nbsp;</td>
                </tr>
                <tr>
                    <td>_ga, _gid and _gat</td>
                    <td>
                        Used to distinguish users and throttle requests for
                        Google Analytics.
                    </td>
                    <td>
                        These cookies are administered by a third party,
                        called Google, and further details can be found at
                        the following link:{' '}
                        <a href='https://support.cloudflare.com/hc/en-us/articles/200170156-What-does-the-Cloudflare-cfduid-cookie-do-'>
                            https://support.cloudflare.com/hc/en-us/articles/200170156-What-does-the-Cloudflare-cfduid-cookie-do-
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
            <p>
                The first non-essential cookie we use is created when you select
                "Remember Me" when logging in, and these are stored on your
                device across browser sessions unless and until deleted by you.
                This enables us to remember you each and every time you login to
                the Versatile GP website. This involves processing of your
                personal data, as explained further below.
            </p>
            <p>
                The second non-essential cookie we use is created when you
                choose to set your cookie preferences. This enables us to
                remember which cookies you have allowed us to store on your
                device. No personal data is stored in this cookie.
            </p>
            <p>
                The remaining non-essential cookies we use enables us to monitor
                how you use our website, and is created by Google Analytics. The
                data collected is anonymised and is not used in relation to you
                individually but to provide a measure of how many users
                undertake certain actions on the website so that we can monitor
                certain metrics of success. No individual behaviour data will be
                used for advertising nor passed on to third parties with the
                exception of Google (who run the cookie), however, summaries of
                the overall anonymised behaviour data may be shared with NHS commissioning bodies
                and other relevant stakeholders. As the information is
                anonymised, you are not identifiable and so it is not your
                personal data.
            </p>
            <p>
                You have the option to decide whether you allow these cookies to
                be stored on your browser, and can change your preferences at
                any time at the{' '}
                <NavLink to='/edit-profile/privacy'>Edit Profile page</NavLink>.
            </p>
            <h2>4. Personal Data</h2>
            <p>
                All of our essential cookies and the ‘Remember Me’ non-essential
                cookies all use data which is specific to you individually so
                that we can authenticate and remember you during and across
                browsing sessions. This uses your IP address, and the UK General
                Data Protection Regulation (<b>“the UK GDPR”</b>) / Data Protection
                Act 2018 (<b>“DPA 2018”</b>) define personal data as meaning any
                information from which an individual can be identified, in
                particular by reference to a unique identifier, including
                “online identifiers”. Accordingly, our essential and ‘Remember
                Me’ cookies use your personal data, and so must comply with both
                the UK GDPR and DPA 2018 in doing so.
            </p>
            <p>
                Further details about the manner in which we process your
                personal data can be found in our {privacyPolicy}. In brief
                terms, however, we can only process your personal data where we
                have a legal basis to do so, and our use of such data in
                connection with cookies is “necessary for the purposes of our
                legitimate interests” to provide with the Versatile GP services.
            </p>
            <p>
                Most browsers allow you to refuse to accept cookies and to
                delete cookies, although as noted in our Essential Cookies above
                refusing, deleting or blocking essential cookies will mean that
                you are unable to use all or parts of our website The methods
                for doing so vary from browser to browser, and from version to
                version. You can however obtain up-to-date information about
                blocking and deleting cookies via these links:
            </p>
            <ol>
                <li>
                    <a href='https://support.google.com/chrome/answer/95647?hl=en'>
                        https://support.google.com/chrome/answer/95647?hl=en
                    </a>{' '}
                    (Chrome);
                </li>
                <li>
                    <a href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'>
                        https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
                    </a>{' '}
                    (Firefox);
                </li>
                <li>
                    <a href='http://www.opera.com/help/tutorials/security/cookies/'>
                        http://www.opera.com/help/tutorials/security/cookies/
                    </a>{' '}
                    (Opera);
                </li>
                <li>
                    <a href='https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies'>
                        https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
                    </a>{' '}
                    (Internet Explorer);
                </li>
                <li>
                    <a href='https://support.apple.com/kb/PH21411'>
                        https://support.apple.com/kb/PH21411
                    </a>{' '}
                    (Safari); and
                </li>
                <li>
                    <a href='https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy'>
                        https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
                    </a>{' '}
                    (Edge).
                </li>
            </ol>
            <p>
                However, if you use your browser settings to block essential
                cookies then you will be unable to access all or parts of our
                website.
            </p>
        </LegalDoc>
    );
};

export default CookiePolicy;
