import { Modal } from 'react-bootstrap';
import React, { Component } from 'react';
import { AppState } from '../../App';
import { WarningAlert, StatusAlert } from '../../Alert';
import { APIParameters, fetchAPI, APIResp } from '../../utils';
import '../../styles/Modal.scss';

interface GenericModalProps {
    appState: AppState;
    show: boolean;
    closeModal: any;
    event: any;
    onSuccess: any;
    title: string;
    yesBtn: string;
    noBtn: string;
    requestParams: APIParameters;
    successMessage: string;
    checkOkSubmit?(): boolean;
    blockSubmit?: boolean;
    size?: 'lg' | 'sm' | undefined;
    children?: any;
}

interface GenericModalState {
    errorMessage: string;
    statusMessage: string;
}

class GenericModal extends Component<GenericModalProps, GenericModalState> {
    constructor(props: GenericModalProps) {
        super(props);
        this.state = {
            errorMessage: '',
            statusMessage: '',
        };
    }

    hideModal = () => {
        this.setState({ errorMessage: '', statusMessage: '' });
        this.props.closeModal();
    };

    updateState = (resp: APIResp) => {
        this.setState({ statusMessage: '' });
        if (resp.status !== 200) {
            this.setState({
                errorMessage: 'Sorry, there was an error in processing your request.',
            });
        } else {
            if (resp.data.ok) {
                // dismiss modal
                // show success alert
                this.hideModal();
                this.props.onSuccess(this.props.successMessage);
                this.props.appState.updateUICounts(resp.data);
            } else {
                this.setState({ errorMessage: resp.data.error });
            }
        }
    };

    sendRequest = (e: any) => {
        e.preventDefault();
        if (this.props.checkOkSubmit == null || this.props.checkOkSubmit()) {
            this.setState({
                errorMessage: '',
                statusMessage: 'Updating the system...',
            });

            fetchAPI(this.props.requestParams, this.updateState, this.props.appState);
        }
    };

    render() {
        if (this.props.event !== null) {
            return (
                <Modal show={this.props.show} onHide={this.hideModal} size={this.props.size}>
                    <form onSubmit={this.sendRequest}>
                        <Modal.Header closeButton>{this.props.title}</Modal.Header>
                        <Modal.Body>
                            <WarningAlert content={this.state.errorMessage} />
                            <StatusAlert content={this.state.statusMessage} />
                            {this.props.children}
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.hideModal}
                            >
                                {this.props.noBtn}
                            </button>
                            {this.props.yesBtn.length > 0 && (
                                <input
                                    type="submit"
                                    className="btn btn-primary"
                                    value={this.props.yesBtn}
                                    onClick={this.sendRequest}
                                    disabled={this.props.blockSubmit}
                                />
                            )}
                        </Modal.Footer>
                    </form>
                </Modal>
            );
        } else {
            return <div />;
        }
    }
}

export default GenericModal;
