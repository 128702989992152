import React, { Component } from 'react';
import LandingPage from './landing-page/LandingPage';
import Main from './Main';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import 'popper.js';
import 'bootstrap/dist/js/bootstrap';
import ReactGA from 'react-ga';

import './styles/App.scss';
import './styles/formElements.scss';

import { Route, RouteComponentProps, Switch, withRouter } from 'react-router';

import Cookies from 'universal-cookie';
import { SuccessAlert, WarningAlert } from './Alert';
import { calObj, CCG } from './interfaces';

import './load-assets';
import Footer from './components/Footer';
import PrivacyPolicy from './components/LegalDocs/PrivacyPolicy';
import CookiePolicy from './components/LegalDocs/CookiePolicy';
import TermsOfUse from './components/LegalDocs/TermsOfUse';
import AcceptableUse from './components/LegalDocs/AcceptableUse';
import * as Sentry from '@sentry/browser';
import { fetchAPI } from './utils';
import axios from 'axios';

const cookies = new Cookies();

type AppProps = RouteComponentProps<{}>;

export interface loggedInPayload {
    ok: boolean;
    name: string;
    userType: UserType;
    userHash: string;
    verified: boolean;
}

export interface Role {
    id: number;
    name: string;
    enabled: boolean;
    // description: string;
}

export interface DoctorRole {
    id: number;
    name: string;
    description: string;
}

export interface APIRespData {
    ok: boolean;
    error: string;
    data: any;
    numMessages: number;
    numNotifs: number;
}

export enum UserType {
    Doctor = 'doctor',
    Practice = 'practice',
    None = '',
}

export interface AppState {
    name: string;
    userType: UserType;
    userHash: string;
    roles: Role[];
    doctorRoles: DoctorRole[];
    boroughs: { [boroughID: number]: string };
    ccgs: CCG[];
    numMessages: number;
    numNotifs: number;
    userVerified: boolean;

    cookiePermissions: any;
    setCookiePermissions(cookiesSet: any): void;
    gaInit: boolean;
    prevPath: string;

    logout(e: any): void;
    checkRespAuth(error: any): void;
    updateUICounts(resp: APIRespData): void;
    errorMessage: string;
    errorMessageShow: boolean;
    errorMessageTimeout: any;
    successMessage: string;

    events: calObj[];
    setEvents(events: calObj[]): void;

    userDoctorRoles: number[];
    setUserDoctorRoles(roles: number[]): void;
}

const Content = (props: {
    userType: string;
    appState: AppState;
    doCheck(): void;
    setErrorMessage(msg: string): void;
} & RouteComponentProps<{}>) => {
    return (
        <div>
            {props.userType !== '' && <Main {...props} appState={props.appState} />}
            {props.userType === '' && (
                <LandingPage
                    doCheck={props.doCheck}
                    appState={props.appState}
                    setErrorMessage={props.setErrorMessage}
                />
            )}
        </div>
    );
};

class App extends Component<AppProps, AppState> {
    constructor(props: AppProps) {
        super(props);

        this.state = {
            name: '',
            userHash: '',
            userType: UserType.None,
            roles: [],
            doctorRoles: [],
            boroughs: {},
            ccgs: [],
            numMessages: 0,
            numNotifs: 0,
            userVerified: false,

            cookiePermissions: cookies.get('permissions'),
            setCookiePermissions: this.setCookiePermissions,
            gaInit: false,
            prevPath: '',

            logout: this.logout,
            checkRespAuth: this.checkRespAuth,
            updateUICounts: this.updateUICounts,
            errorMessage: '',
            errorMessageShow: false,
            errorMessageTimeout: null,
            successMessage: '',

            events: [],
            setEvents: this.setEvents,

            userDoctorRoles: [],
            setUserDoctorRoles: this.setUserDoctorRoles,
        };

        this.doCheck();
        this.fetchRoles();
        this.fetchBoroughs();

        let path = window.location.pathname;
        if (path.startsWith('/verify-email/')) {
            let payload = path.substr(14).split('/');
            let userType, userHash, verificationCode: string;
            if (payload.length === 3) {
                userType = payload[0];
                userHash = payload[1];
                verificationCode = payload[2];
            } else {
                return;
            }
            axios
                .post('/api/auth/verify-email', {
                    userType: userType,
                    userHash: userHash,
                    code: verificationCode,
                })
                .then((resp: any) => {
                    if (resp.data.ok) {
                        // Verified
                        this.setState(
                            {
                                successMessage:
                                    'Email address verified. Refreshing the page now...',
                            },
                            () =>
                                setTimeout(() => {
                                    window.location.href = '/';
                                }, 1000),
                        );
                    } else {
                        this.setErrorMessage(
                            'Sorry, we could not verify your email address. This could be because you have requested a new verification email, or the link is wrong.',
                        );
                    }
                });
        }
    }

    trackPage(path: string) {
        if (this.state.userHash !== '' && this.state.prevPath !== path) {
            // Check if cookies have been accepted before
            let permissions = cookies.get('permissions');
            if (permissions != null && permissions.analyticsCookies) {
                // We only do the analysis if they have allowed for it
                this.setState({ prevPath: path }); // to prevent double-counting
                // let id = this.state.userType + this.state.userHash;

                if (!this.state.gaInit) {
                    // Initialise if not initialised before
                    ReactGA.initialize([
                        {
                            trackingId: 'UA-115793962-3',
                            // gaOptions: {
                            // 	name: 'tracker',
                            // 	storage: 'none',
                            // 	storeGac: false,
                            // 	clientId: id
                            // }
                        },
                    ]);
                    this.setState({ gaInit: true });
                }

                // ReactGA.set({ userId: id });
                ReactGA.pageview(path, ['tracker']);
            }
        }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location !== prevProps.location) {
            this.doCheck();
        }
    }

    setCookiePermissions = (cookiesSet: any) => {
        let expiry = new Date();
        expiry.setDate(expiry.getDate() + 365 * 50);

        this.setState({ cookiePermissions: cookiesSet });
        cookies.set('permissions', cookiesSet, { path: '/', expires: expiry });

        if (!cookiesSet.rmbMeCookies) {
            // Remove rmb me token
            cookies.remove('rmbme');
        }

        if (!cookiesSet.analyticsCookies) {
            // Remove GA cookies
            cookies.remove('_ga');
            cookies.remove('_gid');
            cookies.remove('_gat');
        }
    };

    hideErrorMessage = () => {
        this.setState({ errorMessageShow: false });
    };

    setErrorMessage = (message: string) => {
        clearTimeout(this.state.errorMessageTimeout);
        setTimeout(() => {
            this.setState({
                errorMessage: message,
                errorMessageShow: true,
                errorMessageTimeout: setTimeout(this.hideErrorMessage, 4000),
            });
        }, 600);
    };

    fetchRoles() {
        axios
            .get('/api/roles')
            .then((resp: any) => {
                this.setState({ roles: resp.data.roles });
            })
            .catch(this.state.checkRespAuth);
    }

    fetchDoctorRoles() {
        fetchAPI(
            { url: '/doctor-roles', method: 'get' },
            (resp) => {
                this.setState({ doctorRoles: resp.data.data.roles });
                this.setUserDoctorRoles(resp.data.data.userRoles);
            },
            this.state,
        );
    }

    fetchBoroughs() {
        axios
            .get('/api/boroughs')
            .then((resp: any) => {
                this.setState({ boroughs: resp.data.boroughs, ccgs: resp.data.ccgs });
            })
            .catch(this.state.checkRespAuth);
    }

    checkLoggedIn(): Promise<loggedInPayload> {
        let token = cookies.get('token');

        return axios.post('/api/auth/check-hash', { hash: token }).then((resp: any) => {
            return resp.data;
        });
    }

    checkRespAuth = (error: any) => {
        const errAddon =
            " You can drop us a message at <a href='mailto:gillian.kyei@nhs.net'>gillian.kyei@nhs.net</a>.";
        if (error.response) {
            // Server side error
            if (error.response.status === 404 || error.response.status === 405) {
                // Just ignore
                this.setErrorMessage('Sorry, there was an issue with the server.');
                Sentry.captureException(error);
                return;
            }
            if (error.response.status === 429) {
                this.setErrorMessage(
                    'Too many requests being sent. Please wait a few seconds before continuing.',
                );
                return;
            }
            if (error.response.status === 401) {
                this.setState({ name: '', userType: UserType.None });
                this.setErrorMessage('You have been logged out. Please log in to continue.');
                return;
            }

            this.setErrorMessage(
                'Sorry, there was an error with the server. Please try again later.' + errAddon,
            );
            Sentry.captureException(error);
        } else if (error.request) {
            // No response received
            this.setErrorMessage(
                'There was a problem contacting the server. Please check your connection, or try again later.' +
                    errAddon,
            );
        } else {
            // Something else happened
            this.setErrorMessage(
                'Sorry, there was an error (' +
                    error.message +
                    '). Please try again later.' +
                    errAddon,
            );
            Sentry.captureException(error);
        }
    };

    updateUICounts = (resp: APIRespData) => {
        this.setState({
            numMessages: resp.numMessages,
            numNotifs: resp.numNotifs,
        });
    };

    logout = (e: any) => {
        e.preventDefault();
        let token = cookies.get('token');
        cookies.remove('token', { path: '/' });
        axios.post('/api/auth/logout', { hash: token });
        this.setState({ name: '', userType: UserType.None, userHash: '' });
    };

    doCheck = () => {
        this.checkLoggedIn().then((resp) => {
            if (resp.ok) {
                if (resp.userType === UserType.Doctor && window.location.pathname === '/offers') {
                    this.fetchDoctorRoles();
                }

                this.setState(
                    {
                        name: resp.name,
                        userType: resp.userType,
                        userHash: resp.userHash,
                        userVerified: resp.verified,
                    },
                    () => {
                        this.trackPage(window.location.pathname);
                    },
                );

                if (
                    window.location.pathname === '/' ||
                    window.location.pathname === '/landing-page' ||
                    window.location.pathname.startsWith('/signup')
                ) {
                    // Redirect to dashboard
                    this.props.history.push('/dashboard');
                }
            }
        });
    };

    setEvents = (events: calObj[]) => {
        this.setState({ events: events });
    };

    setUserDoctorRoles = (roles: number[]) => this.setState({ userDoctorRoles: roles });

    render() {
        return (
            <div>
                <div className="system-alert">
                    <WarningAlert
                        content={this.state.errorMessage}
                        show={this.state.errorMessageShow}
                    />
                    <SuccessAlert content={this.state.successMessage} />
                </div>

                <Switch>
                    <Route path="/acceptable-use" component={AcceptableUse} />
                    <Route path="/terms-of-use" component={TermsOfUse} />
                    <Route path="/cookies" component={CookiePolicy} />
                    <Route path="/privacy" component={PrivacyPolicy} />
                    <Route
                        render={() => (
                            <Content
                                userType={this.state.userType}
                                appState={this.state}
                                doCheck={this.doCheck}
                                setErrorMessage={this.setErrorMessage}
                                {...this.props}
                            />
                        )}
                    />
                </Switch>
                <Footer />
            </div>
        );
    }
}

export default withRouter(App);
