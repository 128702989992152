import React, { Component } from "react";
import JobEntryForm from "../JobEntryForm";
import { GenericModalProps, ModalState } from "./Modals";
import { getOtherType } from "../../utils";

class ProposeEditModal extends Component<GenericModalProps, ModalState> {
    render() {
        if (this.props.event !== null) {
            let otherType = getOtherType(this.props.appState.userType);
            let name = this.props.event[otherType].name;
            return (
                <JobEntryForm
                    {...this.props}
                    title={"Propose booking edit"}
                    job={this.props.event}
                    api="/bookings/"
                    onSuccess={() =>
                        this.props.onSuccess(
                            "Booking edit proposal has been sent"
                        )
                    }
                    proposeEdit
                >
                    <div>
                        You are proposing a change in an upcoming booking. You
                        may tweak the booking details to fit your needs, and {name} will get back to you. <br />
                        <br />
                    </div>
                </JobEntryForm>
            );
        } else {
            return <div />;
        }
    }
}

export default ProposeEditModal;
