import React, { Component } from "react";
import { getOtherType } from "../../utils";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";

class RejectEditModal extends Component<GenericModalProps> {
    render() {
        let otherType = getOtherType(this.props.appState.userType);
        let name = this.props.event[otherType].name;
        let requestParams = {
            url: '/bookings/edit/delete/' + this.props.event.id,
            method: 'post'
        }

        return (
            <GenericModal
                {...this.props}
                title="Reject edit proposal"
                yesBtn="Yes"
                noBtn="No"
                requestParams={requestParams}
                successMessage="Edit proposal rejected."
            >
                Are you sure you want to reject {name}'s proposed booking changes?
            </GenericModal>
        );
    }
}

export default RejectEditModal;
