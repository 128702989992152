import React, { Component } from "react";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";

class RetractEditModal extends Component<GenericModalProps> {
    render() {
        let requestParams = {
            url: '/bookings/edit/delete/' + this.props.event.id,
            method: 'post'
        }
        return (
            <GenericModal
                {...this.props}
                title="Retract edit proposal"
                yesBtn="Yes"
                noBtn="No"
                requestParams={requestParams}
                successMessage="Edit proposal retracted."
            >
                Are you sure you want to retract your edit proposal? 
            </GenericModal>
        );
    }
}

export default RetractEditModal;
