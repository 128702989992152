import React from 'react';
import LegalDoc from './LegalDoc';
import { NavLink } from 'react-router-dom';

const website = <a href='https://www.versatilegp.com'>www.versatilegp.com</a>;
const email = <a href='mailto:gilliankyei@versatilegp.com'>gilliankyei@versatilegp.com</a>;
const privacyPolicy = <NavLink to='/privacy'>Privacy Policy</NavLink>;
const acceptableUsePolicy = <NavLink to='/acceptable-use'>Acceptable Use Policy</NavLink>;
const cookiePolicy = <NavLink to='/cookies'>Cookie Policy</NavLink>;

const TermsOfUse = () => {
    return (
        <LegalDoc title='Terms of Use'>
            <p>
                <b>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE </b>
            </p>
            <h2>1. What's in these terms?</h2>
            <p>
                These terms tell you the rules for using our website {website} (<b>our site</b>).
            </p>
            <h2>2. Who we are and how to contact us</h2>
            <p>
                {website} is a site operated by Versatile GP Ltd (<b>We</b>). We are registered in
                England and Wales under company number 11251139 and have our registered office at
                International House, 12 Constance Street, London, E16 2DQ.
            </p>
            <p>
                Our site connects GP locums (described as “Healthcare Professionals” in these Terms of Use)
                with GP Practices. This is the “Versatile GP Service”.
            </p>
            <p>To contact us, please email {email}.</p>
            <h2>3. By using our site you accept these terms</h2>
            <p>
                By using our site, you confirm that you accept these terms of use and that you agree
                to comply with them. If you do not agree to these terms, you must not use our site.
                We recommend that you print or save a copy of these terms for future reference.
            </p>
            <h2>4. There are other terms that may apply to you</h2>
            <p>
                These terms of use refer to the following additional terms, which also apply to your
                use of our site:
            </p>
            <ul>
                <li>Our {privacyPolicy}.</li>
                <li>
                    <NavLink to='/acceptable-use'>Our Acceptable Use Policy</NavLink> which sets out
                    the permitted uses and prohibited uses of our site. When using our site, you
                    must comply with this Acceptable Use Policy.
                </li>
                <li>
                    Our {cookiePolicy} which sets out information about the cookies on our site.
                </li>
            </ul>
            <h2>5. We may make changes to these terms</h2>
            <p>
                We amend these terms from time to time. Every time you wish to use our site, please
                check these terms to ensure you understand the terms that apply at that time.
            </p>

            <h2>6. We may make changes to our site</h2>
            <p>
                We may update and change our site from time to time to reflect changes to our
                products, our users’ needs and our business priorities.
            </p>

            <h2>7. We may suspend or withdraw our site</h2>
            <p>Our site is made available free of charge.</p>
            <p>
                We do not guarantee that our site, or any content on it, will always be available or
                be uninterrupted. We may suspend or withdraw or restrict the availability of all or
                any part of our site for business and operational reasons. We will try to give you
                reasonable notice of any suspension or withdrawal.
            </p>
            <p>
                You are also responsible for ensuring that all persons who access our site through
                your internet connection are aware of these terms of use and other applicable terms
                and conditions, and that they comply with them.
            </p>

            <h2>8. Our site is only for users in the UK</h2>
            <p>
                Our site is directed to people residing in the United Kingdom. We do not represent
                that content available on or through our site is appropriate for use or available in
                other locations
            </p>

            <h2>9. You must keep your account details safe</h2>
            <p>
                If you choose, or you are provided with, a user identification code, password or any
                other piece of information as part of our security procedures, you must treat such
                information as confidential. You must not disclose it to any third party.
            </p>
            <p>
                We have the right to disable any user identification code or password, whether
                chosen by you or allocated by us, at any time, if in our reasonable opinion you have
                failed to comply with any of the provisions of these terms of use.
            </p>
            <p>
                If you know or suspect that anyone other than you knows your user identification
                code or password, you must promptly notify us at {email}.
            </p>

            <h2>10. How you may use material on our site</h2>
            <p>
                We are the owner or the licensee of all intellectual property rights in our site,
                and in the material published on it. Those works are protected by copyright laws and
                treaties around the world. All such rights are reserved.
            </p>
            <p>
                You may print off copies, and may download extracts, of any page(s) from our site
                for your personal use and you may draw the attention of others within your
                organisation to content posted on our site.
            </p>
            <p>
                You must not modify the paper or digital copies of any materials you have printed
                off or downloaded in any way, and you must not use any illustrations, photographs,
                video or audio sequences or any graphics separately from any accompanying text,
                without our written consent. Our status (and that of any identified contributors) as
                the authors of content on our site must always be acknowledged.
            </p>
            <p>
                You must not use any part of the content on our site for commercial purposes without
                obtaining a licence to do so from us or our licensors.
            </p>
            <p>
                If you print off, copy or download any part of our site in breach of these terms of
                use, your right to use our site will cease immediately and you must, at our option,
                return or destroy any copies of the materials you have made.
            </p>

            <h2>11. Do not rely on information on this site</h2>
            <p>
                We check that all GP locums are registered on the General Medical Council’s GP
                register and National Performers List at the date upon which that GP locum is
                registered on our site. This is not an ongoing check. We do not check GPs locums’
                compliance documents. This is the responsibility of the GP practice prior to
                engagement. We do not carry out any checks on GP practices. It is the responsibility
                of all users to carry out adequate due diligence on other users before entering into
                any contract.
            </p>
            <p>
                The content on our site is provided for general information only. It is not intended
                to amount to advice on which you should rely. You must obtain professional or
                specialist advice before taking, or refraining from, any action on the basis of the
                content on our site.
            </p>
            <p>
                Although we make reasonable efforts to update the information on our site, we make
                no representations, warranties or guarantees, whether express or implied, that the
                content on our site is accurate, complete or up to date.
            </p>

            <h2>12. We are not responsible for websites we link to</h2>
            <p>
                Where our site contains links to other sites and resources provided by third
                parties, these links are provided for your information only. Such links should not
                be interpreted as approval by us of those linked websites or information you may
                obtain from them.
            </p>
            <p>We have no control over the contents of those sites or resources.</p>

            <h2>13. User-generated content is not approved by us</h2>
            <p>
                Our site may include content and material contributed by other users of the site.
                This content and material have not been verified or approved by us. The
                views expressed by other users on our site do not represent our views or values.
            </p>
            <p>
                By posting any personal data to our site, you confirm that you have permission from
                the data subject and that such posting of information is in accordance with all data
                protection legislation.
            </p>
            <p>
                If you wish to complain about information and materials uploaded by other users
                please contact us on {email}.
            </p>
            <h2>14. Our responsibility for loss or damage suffered by you</h2>
            <p>
                We do not exclude or limit in any way our liability to you where it would be
                unlawful to do so. This includes liability for death or personal injury caused by
                our negligence or the negligence of our employees, agents or subcontractors and for
                fraud or fraudulent misrepresentation.
            </p>
            <p>
                We exclude all implied conditions, warranties, representations or other terms that
                may apply to our site or any content on it.
            </p>
            <p>
                We will not be liable to you for any loss or damage, whether in contract, tort
                (including negligence), breach of statutory duty, or otherwise, even if foreseeable,
                arising under or in connection with:
            </p>
            <ul>
                <li>use of, or inability to use, our site; or</li>
                <li>use of or reliance on any content displayed on our site.</li>
            </ul>
            <p>In particular, we will not be liable for:</p>
            <ul>
                <li>loss of profits, sales, business, or revenue;</li>
                <li>business interruption;</li>
                <li>loss of anticipated savings;</li>
                <li>loss of business opportunity, goodwill or reputation; or</li>
                <li>any indirect or consequential loss or damage.</li>
            </ul>
            <p>
                We have no involvement in any contract or communication between users. We do not
                review users’ posts or profiles and we do not guarantee the accuracy of postings or
                user communications. We host the site only and exclude all liability in respect of
                any loss or damage caused to a user by another user.
            </p>

            <h2>15. How we may use your personal information</h2>
            <p>We will only use your personal information as set out in our {privacyPolicy}.</p>

            <h2>16. Contributing content and uploading documents to our site</h2>
            <p>
                Whenever you make use of a feature that allows you to contribute content and material
                to our site, or to make contact with other users of our site, you must comply with the content
                standards set out in our {acceptableUsePolicy}.
            </p>
            <p>
                If you are a Healthcare Professional and make use of any facility to upload documents relating to
                your ID, professional background, experience, qualifications and accreditations, and eligibility for
                work (<b>compliance documentation</b>), you must comply with the content standards set out in
                our Acceptable Use Policy.
            </p>
            <p>
                You warrant that any contribution you make and accreditation documentation you upload complies
                with those standards, and you will be liable to us and indemnify us for any breach of that warranty.
                This means
                you will be responsible for any loss or damage we suffer as a result of your breach
                of warranty.
            </p>
            <p>
                Any content and material you contribute to our site will be considered non-confidential and
                non-proprietary. You retain all of your ownership rights in your content and material, but you
                are required to grant us a limited licence to use, store and copy that content and
                to distribute and make it available to third parties.
            </p>
            <p>
                Your privacy rights in relation to any compliance documentation you upload to our site will be
                respected in accordance with the obligations imposed on us by data protection legislation and
                any obligations of confidence which arise. You retain all your ownership rights in this information
                but permit us and GP Practices to use this information in connection with the provision of the
                Versatile GP Service.
            </p>
            <p>
                We also have the right to disclose your identity to any third party who is claiming
                that any content or material contributed by you to our site constitutes a violation of
                their intellectual property rights, or of their right to privacy.
            </p>
            <p>
                We have the right to remove any content and material you contribute or compliance documentation you
                upload to on our site if, in our opinion, your contribution does not comply with the content standards
                set out in our {acceptableUsePolicy}.
            </p>
            <p>You are solely responsible for securing and backing up your content.</p>

            <h2>17. We are not responsible for viruses and you must not introduce them</h2>
            <p>We do not guarantee that our site will be secure or free from bugs or viruses.</p>
            <p>
                You are responsible for configuring your information technology, computer programmes
                and platform to access our site. You should use your own virus protection software.
            </p>
            <p>
                You must not misuse our site by knowingly introducing viruses, Trojans, worms, logic
                bombs or other material that is malicious or technologically harmful. You must not
                attempt to gain unauthorised access to our site, the server on which our site is
                stored or any server, computer or database connected to our site. You must not
                attack our site via a denial-of-service attack or a distributed denial-of service
                attack. By breaching this provision, you would commit a criminal offence under the
                Computer Misuse Act 1990. We will report any such breach to the relevant law
                enforcement authorities and we will co-operate with those authorities by disclosing
                your identity to them. In the event of such a breach, your right to use our site
                will cease immediately.
            </p>

            <h2>18. Rules about linking our site</h2>
            <p>
                You may link to our home page, provided you do so in a way that is far and legal and
                does not damage our reputation or take advantage of it.
            </p>
            <p>
                You must not establish a link to our site in such a way as to suggest any form of
                association, approval or endorsement on our part where none exists.
            </p>
            <p>
                You must not establish a link to our site in any website that is not owned by you.
            </p>
            <p>
                Our site must not be framed on any other site, nor may you create a link to any part
                of our site other than the home page.
            </p>
            <p>We reserve the right to withdraw linking permission without notice.</p>
            <p>
                The website in which you are linking must comply in all respects with the content
                standards set out in our {acceptableUsePolicy}.
            </p>
            <p>
                If you wish to link to or make any use of content on our site other than that set
                out above, please contact {email}.
            </p>

            <h2>19. Which country’s laws apply to any disputes?</h2>
            <p>
                These terms of use, their subject matter and their formation (and any
                non-contractual disputes or claims) are governed by English law. We both agree to
                the exclusive jurisdiction of the courts of England and Wales.
            </p>
        </LegalDoc>
    );
};

export default TermsOfUse;
