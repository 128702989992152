import React, { ChangeEvent, Component } from 'react';
import { GenericModalProps } from './Modals';
import GenericModal from './GenericModal';
import { availability } from '../../interfaces';
import { fetchAPI, APIResp, formatTime, formatJobTimestamp, formatMoney } from '../../utils';
import RateCalculations from '../JobEntryForm/RateCalculations';

interface State {
    claimingPension: boolean;
    clashes: availability[];
}

class AcceptOfferModal extends Component<GenericModalProps, State> {
    constructor(props: GenericModalProps) {
        super(props);
        this.state = {
            claimingPension: false,
            clashes: [],
        };

        this.fetchData();
    }

    fetchData = () => {
        fetchAPI(
            { url: '/availabilities/user/check-clash/' + this.props.event.id },
            this.updateState,
            this.props.appState,
        );
    };

    updateState = (resp: APIResp) => {
        this.setState({ clashes: resp.data.data });
    };

    updateChoice = (e: any) => {
        this.setState({ claimingPension: e.target.value === 'yes' });
    };

    render() {
        let requestParams = {
            url: '/offers/accept/',
            method: 'post',
            payload: {
                id: this.props.event.id,
                pension: this.state.claimingPension,
                doctorRoles: this.props.appState.userDoctorRoles,
            },
        };
        let clashes = this.state.clashes.map((clash) => (
            <li key={clash.id}>
                {formatTime(clash.startTime)} - {formatTime(clash.endTime)} hrs
            </li>
        ));

        return (
            <GenericModal
                {...this.props}
                title="Accept Job Offer"
                yesBtn="Submit"
                noBtn="Cancel"
                requestParams={requestParams}
                successMessage="Job offer has been accepted"
                blockSubmit={this.props.appState.userDoctorRoles.length === 0}
            >
                <p>
                    You are currently accepting a job from <b>{this.props.event.practice.name}</b>{' '}
                    on <b>{formatJobTimestamp(this.props.event)}</b>, at the{' '}
                    {this.props.event.fixedRate ? 'fixed' : 'hourly'} rate of{' '}
                    <b>
                        {formatMoney(this.props.event.rate)}
                        {this.props.event.fixedRate ? '' : '/hr'}
                    </b>
                    .
                </p>
                <p>Please note that the rate will be as follows:</p>
                <RateCalculations
                    hours={(this.props.event.endTime - this.props.event.startTime) / 60}
                    rate={this.props.event.rate}
                    userType={this.props.appState.userType}
                    fixedRate={this.props.event.fixedRate}
                />
                <div className="modal-question">
                    <p>Will you be claiming pension for this job?</p>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="claiming-radio"
                            value="yes"
                            onChange={this.updateChoice}
                            checked={this.state.claimingPension}
                        />
                        <label className="form-check-label" htmlFor="claiming-radio">
                            Yes, I will be claiming pension
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="not-claiming-radio"
                            value="no"
                            onChange={this.updateChoice}
                            checked={!this.state.claimingPension}
                        />
                        <label className="form-check-label" htmlFor="not-claiming-radio">
                            No, I will not be claiming pension
                        </label>
                    </div>
                </div>
                <div className="modal-question">
                    <p>
                        Please select the other GP roles that you undertake (this is for anonymised
                        reporting requirements for the NHS and will NOT be shared with practices or
                        other parties and you will not be identified by this). You can select more
                        than one role if this applies to you:
                    </p>
                    <div className="form-check">
                        {this.props.appState.doctorRoles.map((role) => {
                            const id = 'dr-role-' + role.id;
                            const onChange = (e: ChangeEvent<HTMLInputElement>) => {
                                if (e.target.checked) {
                                    this.props.appState.setUserDoctorRoles([
                                        ...this.props.appState.userDoctorRoles,
                                        role.id,
                                    ]);
                                } else {
                                    const index = this.props.appState.userDoctorRoles.indexOf(
                                        role.id,
                                    );
                                    const newRoles = [...this.props.appState.userDoctorRoles];
                                    newRoles.splice(index, 1);
                                    this.props.appState.setUserDoctorRoles(newRoles);
                                }
                            };

                            return (
                                <div key={role.id}>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={id}
                                        checked={this.props.appState.userDoctorRoles.includes(
                                            role.id,
                                        )}
                                        onChange={onChange}
                                    />
                                    <label htmlFor={id}>
                                        <b>{role.name}</b>
                                        {role.description.length > 0 ? (
                                            <span> ({role.description})</span>
                                        ) : (
                                            <></>
                                        )}
                                    </label>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {this.state.clashes.length > 0 && (
                    <div
                        style={{
                            marginTop: '18px',
                            fontStyle: 'italic',
                            opacity: 0.7,
                        }}
                    >
                        Please note that your availabilities at the following timings will clash
                        with your newly confirmed job, and will be automatically deleted.
                        <ul style={{ marginTop: '6px' }}>{clashes}</ul>
                    </div>
                )}
            </GenericModal>
        );
    }
}

export default AcceptOfferModal;
