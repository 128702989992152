import React, { Component } from 'react';

interface RateFieldProps {
    onChange: any;
    value: number;
    fixedRate: boolean;
}

interface RateFieldState {
    // rate: number;
}

class RateField extends Component<RateFieldProps, RateFieldState> {
    render() {
        return (
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">&pound;</span>
                </div>
                <input
                    type="number"
                    step={0.01}
                    min={0}
                    className="form-control"
                    value={this.props.value}
                    onChange={this.props.onChange}
                />
                {!this.props.fixedRate && (
                    <div className="input-group-append">
                        <span className="input-group-text">/hr</span>
                    </div>
                )}
            </div>
        );
    }
}

export default RateField;
