import React, { Component } from 'react';
import { GenericModalProps } from './Modals';
import GenericModal from './GenericModal';


class MarkPaidModal extends Component<GenericModalProps> {
	render() {
		let name = this.props.event.doctor.name;
		let requestParams = {
            url: '/invoices/id/' + this.props.event.id + '/paid', 
            method: 'post'
		};

		return (
			<GenericModal
				{...this.props}
				title="Mark Invoice as Paid"
				yesBtn="Yes"
				noBtn="No"
				requestParams={requestParams}
				successMessage="Invoice has been marked as paid."
			>
				Are you sure you want to mark {name}'s invoice as paid?
			</GenericModal>
		);
	}
}

export default MarkPaidModal;
