import React, { Component } from 'react';
import { formatMessageTimestamp } from '../utils';
import { withRouter, RouteComponentProps } from 'react-router';

export interface message {
	id: number;
	userName: string;
	userHash: string;
	direction: string;
	read: boolean;
	content: string;
	timestamp: string;
	systemHeader: string;
}

class Message extends Component<{ message: message } & RouteComponentProps> {
	render() {
		let timestamp = formatMessageTimestamp(this.props.message.timestamp);

		return (
			<tr
				className={'messages-message-row ' + (!this.props.message.read ? 'messages-message-row-unread' : '')}
				onClick={() => {
					this.props.history.push('/messages/' + this.props.message.userHash);
				}}
			>
				<td className="messages-message-name">{this.props.message.userName}</td>
				<td className="messages-message-content">{this.props.message.content}</td>
				<td className="messages-message-timestamp">{timestamp}</td>
			</tr>
		);
	}
}

export default withRouter(Message);
