import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../styles/Loading.scss';

const Loading = (props: { loading: boolean, children: JSX.Element }) => {
    if (props.loading) {
        return (
            <div className='loading'>
                Loading, please wait...
                <br />
                <FontAwesomeIcon icon='spinner-third' spin size='lg' />
            </div>
        );
    } else {
        return props.children;
    }
};

export default Loading;
