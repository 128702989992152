import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchAPI } from "../utils";
import { AppState } from "../App";

export interface dashboardNotif {
    id: number;
    content: string;
}

class DashboardNotif extends Component<
    dashboardNotif & { appState: AppState },
    { show: boolean }
> {
    constructor(props: dashboardNotif & { appState: AppState }) {
        super(props);
        this.state = {
            show: true
        };
    }
    dismissNotif = () => {
        fetchAPI(
            {
                url: "/dismiss-dashboard-notif/" + this.props.id,
                method: "post"
            },
            () => {},
            this.props.appState
        );
        this.setState({ show: false });
    };

    render() {
        if (this.state.show) {
            return (
                <div className="alert alert-warning">
                    <span className="alert-content">{this.props.content}</span>
                    <span className="alert-dismiss" onClick={this.dismissNotif}>
                        <FontAwesomeIcon icon="times" />
                    </span>
                </div>
            );
        } else {
            return <div />;
        }
    }
}

export default DashboardNotif;
