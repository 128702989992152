import React, { Component } from "react";
import { APIResp, fetchAPI } from "../utils";
import { AddBtn } from "../components/Buttons";
import "react-datepicker/dist/react-datepicker.css";
import { AppState } from "../App";
import AvailabilityListItems from "../components/ListItems/AvailabilityListItems";
import { CompProps } from "../Main";
import Loading from "../components/Loading";
import { availability } from "../interfaces";

interface OwnAvailabilitiesProps {
    createText: string;
    appState: AppState;
    setSuccessMessage: any;
}

interface OwnAvailabilitiesState {
    fetchingData: boolean;
    availabilities: any;
    currentAvailability: availability;
}

class OwnAvailabilities extends Component<
    OwnAvailabilitiesProps,
    OwnAvailabilitiesState
> {
    constructor(props: OwnAvailabilitiesProps) {
        super(props);

        this.state = {
            fetchingData: true,
            availabilities: [],
            currentAvailability: {
                id: 0,
                date: new Date(),
                startTime: 0,
                endTime: 0,
                roles: [],
                rate: 0
            }
        };

        this.fetchAvailabilities();
    }

    updateState = (resp: APIResp) => {
        this.setState({ fetchingData: false });
        if (resp.data.ok) {
            this.props.appState.updateUICounts(resp.data);
            this.setState({ availabilities: resp.data.data });
            this.props.appState.updateUICounts(resp.data);
        }
    }

    fetchAvailabilities = () => {
        fetchAPI({url: '/availabilities/user'}, this.updateState, this.props.appState)
    };

    setAvailability = (availability: availability) => {
        this.setState({ currentAvailability: availability });
    };

    updateAvailability = (key: string, value: any) => {
        let availability = this.state.currentAvailability;
        switch (key) {
            case "date":
                availability.date = value;
                break;
            case "startTime":
                availability.startTime = value;
                break;
            case "endTime":
                availability.endTime = value;
                break;
            case "rate":
                availability.rate = value;
                break;
            case "roles":
                availability.roles = value;
                break;
        }

        this.setAvailability(availability);
    };

    onUpdate = (message: string) => {
        if (message != null && message.length > 0) {
            this.props.setSuccessMessage(message)
        }
        this.fetchAvailabilities();
    }

    render() {
        let emptyAvailability = {
            roles: [],
            id: 0,
            date: new Date(),
            startTime: 0,
            endTime: 60,
            rate: 0
        };

        return (
            <div>
                <AddBtn
                    appState={this.props.appState}
                    event={emptyAvailability}
                    onSuccess={this.onUpdate}
                >
                    &nbsp;&nbsp;New {this.props.createText}
                </AddBtn>

                <Loading loading={this.state.fetchingData}>
                    <AvailabilityListItems
                        appState={this.props.appState}
                        data={this.state.availabilities}
                        onUpdate={this.onUpdate}
                    />
                </Loading>
            </div>
        );
    }
}

export class MyAvailabilities extends Component<CompProps> {
    constructor(props: CompProps) {
        super(props);
        this.props.setTitle("My Availabilities");
    }
    render() {
        return <OwnAvailabilities createText="availability" {...this.props} />;
    }
}

export class MyAvailableSessions extends Component<CompProps> {
    constructor(props: CompProps) {
        super(props);
        this.props.setTitle("My Available Sessions");
    }
    render() {
        return (
            <OwnAvailabilities createText="available session" {...this.props} />
        );
    }
}
