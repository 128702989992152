import React, { Component } from "react";
import { AppState } from "../../App";
import { ModalProps, ModalState } from "./Modals";
import { Modal } from "react-bootstrap";
import { APIResp, fetchAPI } from "../../utils";
import { WarningAlert, StatusAlert } from "../../Alert";
import JobEntryForm from "../JobEntryForm";

const api = "/availabilities/user";

var moment = require("moment");
moment().format();
moment().locale("en");

export interface AvailModalProps {
    appState: AppState;
    show: boolean;
    closeModal: any;
    event: any;
    eventType: string;
    onSuccess: any;
    title: string;
    action: string;
}

export class AvailabilityModal extends Component<AvailModalProps> {
    render() {
        let eventType = "";
        if (this.props.appState.userType === "doctor") {
            eventType = "availability";
        } else if (this.props.appState.userType === "practice") {
            eventType = "available session";
        }

        if (this.props.event !== null) {
            return (
                <JobEntryForm
                    {...this.props}
                    title={this.props.title + " " + eventType}
                    job={this.props.event}
                    api={api}
                    onSuccess={() =>
                        this.props.onSuccess(
                            "Your " +
                                eventType +
                                " has been " +
                                this.props.action +
                                "."
                        )
                    }
                />
            );
        } else {
            return <div />;
        }
    }
}

export class NewAvailabilityModal extends Component<ModalProps> {
    render() {
        return (
            <AvailabilityModal title="Add new" {...this.props} action="added" />
        );
    }
}

export class EditAvailabilityModal extends Component<ModalProps> {
    render() {
        return (
            <AvailabilityModal title="Edit" {...this.props} action="edited" />
        );
    }
}

export class DeleteAvailabilityModal extends Component<ModalProps, ModalState> {
    constructor(props: ModalProps) {
        super(props);
        this.state = {
            errorMessage: "",
            statusMessage: ""
        };
    }

    hideModal = () => {
        this.setState({ errorMessage: "", statusMessage: "" });
        this.props.closeModal();
    };

    updateState = (resp: APIResp) => {
        this.setState({ statusMessage: "" });
        if (resp.data.ok) {
            this.hideModal();

            let eventType = "";
            if (this.props.appState.userType === "doctor") {
                eventType = "availability";
            } else if (this.props.appState.userType === "practice") {
                eventType = "available session";
            }

            this.props.onSuccess("Your " + eventType + " has been deleted.");
            this.props.appState.updateUICounts(resp.data);
        }
    };

    sendRequest = (e: any) => {
        e.preventDefault();
        this.setState({ statusMessage: "Updating the server..." });
        let url = api + "/delete/" + this.props.event.id;
        fetchAPI(
            { url: url, method: "post" },
            this.updateState,
            this.props.appState
        );
    };

    render() {
        let eventType = "";
        if (this.props.appState.userType === "doctor") {
            eventType = "availability";
        } else if (this.props.appState.userType === "practice") {
            eventType = "available session";
        }

        return (
            <Modal show={this.props.show} onHide={this.hideModal}>
                <Modal.Header closeButton>
                    Retracting your {eventType}
                </Modal.Header>
                <form onSubmit={this.sendRequest}>
                    <Modal.Body>
                        <WarningAlert content={this.state.errorMessage} />
                        <StatusAlert content={this.state.statusMessage} />
                        <div>
                            Are you sure you want to permanently delete your{" "}
                            {eventType} on{" "}
                            {moment(this.props.event.date).format(
                                "ddd, MMM D, YYYY"
                            )}
                            ? This action cannot be reversed.
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={this.hideModal}
                        >
                            Cancel
                        </button>
                        <input
                            type="submit"
                            className="btn btn-primary"
                            value="Delete"
                            onClick={this.sendRequest}
                        />
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}
