import React, { Component } from "react";
import AuthenticationBox from "./AuthenticationBox";

class AuthenticationBoxes extends Component<
    { changeStatus(status: string, type: string, title: string): void },
    {}
> {
    render() {
        return (
            <div className="auth-box-row">
                <AuthenticationBox
                    name="Locum GP"
                    type="doctor"
                    changeStatus={this.props.changeStatus}
                />
                <AuthenticationBox
                    name="General Practice"
                    type="practice"
                    changeStatus={this.props.changeStatus}
                />
            </div>
        );
    }
}

export default AuthenticationBoxes;
