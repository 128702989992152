import React, { Component } from 'react';
import { CompProps } from '../Main';
import { calObj } from '../interfaces';
import { APIResp, fetchAPI } from '../utils';
import Loading from './Loading';
import JobListItems from './ListItems/JobListItems';
import EmptyState from './EmptyState';

interface SessionsState {
    sessions: calObj[];
    fetchingData: boolean;
}

export class AvailableSessions extends Component<CompProps, SessionsState> {
    constructor(props: CompProps) {
        super(props);
        if (this.props.appState.userType === 'doctor') {
            this.props.setTitle('Browse Practice Sessions');
        } else if (this.props.appState.userType === 'practice') {
            this.props.setTitle('Browse Available Locums');
        }

        this.state = {
            sessions: [],
            fetchingData: true,
        };

        this.fetchData();
    }

    updateState = (resp: APIResp) => {
        if (resp.data.ok) {
            this.setState({ sessions: resp.data.data, fetchingData: false });
        } else {
            console.log(resp);
        }
    };

    fetchData = () => {
        fetchAPI(
            { url: '/availabilities/' },
            this.updateState,
            this.props.appState,
        );
    };

    onUpdate = (message: string) => {
        this.props.setSuccessMessage(message);
        this.fetchData();
    };

    render() {
        let eventType = '';
        if (this.props.appState.userType === 'doctor') {
            eventType = 'available sessions';
        } else if (this.props.appState.userType === 'practice') {
            eventType = 'listed availabilities';
        }
        return (
            <div>
                <Loading loading={this.state.fetchingData}><>
                    {this.state.sessions.length > 0 && (
                        <JobListItems
                            data={this.state.sessions}
                            appState={this.props.appState}
                            onUpdate={this.onUpdate}
                            eventType={'posted-availability'}
                        />
                    )}
                    {this.state.sessions.length === 0 && (
                        <EmptyState icon='calendar-times'>
                            No {eventType} found. Please wait for some{' '}
                            {eventType} to be posted within the next few days!
                        </EmptyState>
                    )}
                </>
                </Loading>
            </div>
        );
    }
}
