import React, { Component } from 'react';
import { doctorProfile } from '../../interfaces';
import { fetchAPI, APIResp } from '../../utils';
import { NavLink, Prompt } from 'react-router-dom';
import { SavePrivacyOptionsBtn, EditCookiePreferencesBtn } from '../Buttons';
import { PrivacyProps } from './PrivacyOptions';
import Loading from '../Loading';

interface indivDoctor {
    profile: doctorProfile;
    reveal: boolean;
}

interface State {
    loading: boolean;
    changesMade: boolean;
    reveal: string;
    individuals: indivDoctor[];
}

const DoctorRow = (props: indivDoctor & { onChange: any }) => {
    return (
        <div className="list-item-name">
            <input type="checkbox" checked={props.reveal} onChange={props.onChange} />
            <NavLink to={'/view-profile/' + props.profile.hash}>{props.profile.name}</NavLink>
        </div>
    );
};

class PracticePrivacyOptions extends Component<PrivacyProps, State> {
    constructor(props: PrivacyProps) {
        super(props);
        this.state = {
            loading: true,
            changesMade: false,
            reveal: 'reveal',
            individuals: [],
        };

        this.fetchData();
    }

    componentDidUpdate = () => {
        if (this.state.changesMade) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = () => {};
        }
    };

    fetchData = () => {
        fetchAPI({ url: '/profile/privacy/' }, this.updateState, this.props.appState);
    };

    updateState = (resp: APIResp) => {
        this.setState({ loading: false });
        if (resp.data.ok) {
            let revealValue = 'hide';
            if (resp.data.data.reveal) {
                revealValue = 'reveal';
            }

            let individuals = resp.data.data.individuals;

            individuals = individuals.sort(function (a: indivDoctor, b: indivDoctor) {
                let aName = a.profile.name.toLowerCase();
                let bName = b.profile.name.toLowerCase();
                if (aName > bName) return 1;
                if (aName < bName) return -1;
                return 0;
            });

            this.setState({
                reveal: revealValue,
                individuals: individuals,
            });
        }
    };

    updateDefault = (e: any) => {
        this.setState({ reveal: e.target.value }, () => {
            fetchAPI(
                {
                    url: '/profile/privacy/default',
                    method: 'post',
                    payload: { reveal: this.state.reveal === 'reveal' },
                },
                () => {
                    this.props.setSuccessMessage(
                        'Your default privacy settings have been updated.',
                    );
                },
                this.props.appState,
            );
        });
    };

    updateReveal = (i: number, e: any) => {
        let individuals = this.state.individuals;
        individuals[i].reveal = e.target.checked;
        this.setState({ individuals: individuals, changesMade: true });
    };

    updateAllReveal = (e: any) => {
        let individuals = this.state.individuals;
        for (let i = 0; i < individuals.length; i++) {
            individuals[i].reveal = e.target.checked;
        }
        this.setState({ individuals: individuals, changesMade: true });
    };

    savePrivacyOptions = (e: any) => {
        e.preventDefault();
        this.setState({ changesMade: false });

        fetchAPI(
            {
                url: '/profile/privacy',
                method: 'post',
                payload: {
                    individuals: this.state.individuals.map((indivDoctor) => ({
                        reveal: indivDoctor.reveal,
                        profile_id: indivDoctor.profile.id,
                    })),
                },
            },
            () => {
                this.props.setSuccessMessage(
                    'Your individual privacy preferences have been updated.',
                );
            },
            this.props.appState,
        );
    };

    render() {
        let profiles = this.state.individuals.map((indiv, i) => (
            <DoctorRow
                key={indiv.profile.id}
                {...indiv}
                onChange={(e: any) => this.updateReveal(i, e)}
            />
        ));
        let numChecked = 0;
        for (let i = 0; i < this.state.individuals.length; i++) {
            if (this.state.individuals[i].reveal) {
                numChecked++;
            }
        }
        return (
            <Loading loading={this.state.loading}>
                <>
                    <Prompt
                        when={this.state.changesMade}
                        message="You have unsaved changes, are you sure you want to leave?"
                    />
                    <EditCookiePreferencesBtn
                        appState={this.props.appState}
                        event={null}
                        onSuccess={null}
                    />
                    <h2>Hide/Reveal for New Registrations</h2>
                    <p>
                        I want to{' '}
                        <select onChange={this.updateDefault} value={this.state.reveal}>
                            <option value="reveal">show our available sessions to</option>
                            <option value="hide">hide our available sessions from</option>
                        </select>{' '}
                        all newly registered locums.{' '}
                    </p>
                    <h2>Individual Options</h2>
                    <p>Show our available sessions to the following locums:</p>
                    <p>
                        (Please scroll down and click on Save Privacy Preferences before you leave
                        the page.)
                    </p>

                    <div className="list-item-name">
                        <input
                            type="checkbox"
                            id="all-locums"
                            checked={numChecked === this.state.individuals.length}
                            onChange={this.updateAllReveal}
                        />
                        <b>
                            <label htmlFor="all-locums">All locums</label>
                        </b>
                    </div>
                    {profiles}
                    <br />
                    <SavePrivacyOptionsBtn onClick={this.savePrivacyOptions}>
                        &nbsp;&nbsp;Save Individual Privacy Preferences
                    </SavePrivacyOptionsBtn>
                </>
            </Loading>
        );
    }
}

export default PracticePrivacyOptions;
