import React, { Component } from 'react';
import JobEntryForm from '../JobEntryForm';
import { GenericModalProps, ModalState } from './Modals';

class SendOfferModal extends Component<GenericModalProps, ModalState> {
    render() {
        if (this.props.event !== null) {
            return (
                <JobEntryForm
                    {...this.props}
                    title={'Send job offer to ' + this.props.event.doctor.name}
                    job={this.props.event}
                    api="/offers/"
                    onSuccess={() =>
                        this.props.onSuccess(
                            'Job offer has been sent to ' + this.props.event.doctor.name,
                        )
                    }
                >
                    <div>
                        You are sending a job offer to {this.props.event.doctor.name}. You may want
                        to tweak the offer details to fit your needs. <br />
                        <br />
                    </div>
                </JobEntryForm>
            );
        } else {
            return <div />;
        }
    }
}

export default SendOfferModal;
