import React, { Component } from "react";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";

class MarkCompleteModal extends Component<GenericModalProps> {
    render() {
        let requestParams = {
            url: '/bookings/complete/' + this.props.event.id,
            method: 'post'
        }

        return (
            <GenericModal
                {...this.props}
                title="Mark job as completed"
                yesBtn="Yes"
                noBtn="No"
                requestParams={requestParams}
                successMessage="Booking has been marked as completed"
            >
                Are you sure you want to mark the booking as completed? After
                this booking has been marked completed, you will be able to send
                an invoice for it.
            </GenericModal>
        );
    }
}

export default MarkCompleteModal;
