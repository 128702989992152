import React, { Component } from "react";

import "../styles/NotificationsWindow.scss";
import { NavLink } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Loading from "./Loading";

export interface notification {
    message: string;
    link: string;
    timestamp: Date;
    read: boolean;
}

class Notification extends Component<notification> {
    render() {
        return (
            <NavLink
                className={
                    "notification" +
                    (this.props.read ? "" : " notification-unread")
                }
                to={this.props.link}
            >
                <div>{this.props.message}</div>
                <div className="notification-timestamp">
                    {formatDistanceToNow(new Date(this.props.timestamp), {
                        addSuffix: true,
                        includeSeconds: true
                    })}
                </div>
            </NavLink>
        );
    }
}

interface WindowProps {
    notifications: notification[];
    loadingNotifs: boolean;
    setRef: any;
}

class NotificationsWindow extends Component<WindowProps> {
    render() {
        let notifs = [];
        for (let i = 0; i < this.props.notifications.length; i++) {
            let notif = this.props.notifications[i];
            notifs.push(
                <CSSTransition
                    key={notif.timestamp + notif.message}
                    classNames={"transition"}
                    timeout={500}
                >
                    <Notification {...notif} />
                </CSSTransition>
            );
        }
        if (notifs.length === 0) {
            return (
                <div id="notifications-window" ref={this.props.setRef}>
                    <div className="no-entries-found">
                        No notifications found
                    </div>
                </div>
            );
        } else {
            return (
                <div id="notifications-window" ref={this.props.setRef}>
                    <Loading loading={this.props.loadingNotifs}>
                        <TransitionGroup>{notifs}</TransitionGroup>
                    </Loading>
                </div>
            );
        }
    }
}

export default NotificationsWindow;
