import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js';

import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';

// Raven.config('https://cbba211c9dbf4dbf84c0eaab2c6ea9ff@sentry.yenter.io/2').install()
if (!window.location.origin.startsWith('http://localhost')) {
    Sentry.init({
        dsn: 'https://f30c4402e0cb417799ee73f6a73383dd@o438870.ingest.sentry.io/5494796',
        environment: window.location.origin,
    });
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
