import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/pro-solid-svg-icons';
import MessageModal from './Modals/MessageModal';
import { AppState } from '../App';
import RejectModal from './Modals/RejectModal';
import AcceptOfferModal from './Modals/AcceptOfferModal';
import SendOfferModal from './Modals/SendOfferModal';
import RetractModal from './Modals/RetractModal';
import { DeleteAvailabilityModal, EditAvailabilityModal, NewAvailabilityModal } from './Modals/AvailabilityModals';
import SendApplicationModal from './Modals/SendApplicationModal';
import CancelBookingModal from './Modals/CancelBookingModal';
import MarkCompleteModal from './Modals/MarkComplete';
import ProposeEditModal from './Modals/ProposeEditModal';
import RetractEditModal from './Modals/RetractEditModal';
import AcceptEditModal from './Modals/AcceptEditModal';
import RejectEditModal from './Modals/RejectEditModal';
import SendInvoiceModal, { SendInvoiceProps } from './Modals/SendInvoiceModal';
import { APIResp, fetchAPI, formatJobTimestamp, getOtherType } from '../utils';
import MarkPaidModal from './Modals/MarkPaidModal';
import RejectInvoiceModal from './Modals/RejectInvoiceModal';
import RetractInvoiceModal from './Modals/RetractInvoiceModal';
import EditProfileFieldModal from './Modals/EditProfileFieldModal';
import ChangePasswordModal from './Modals/ChangePasswordModal';
import LogOutEverywhereModal from './Modals/LogOutEverywhereModal';

import '../styles/Buttons.scss';
import { NavLink } from 'react-router-dom';
import CookieModal from './Modals/CookieModal';
import ClashingAcceptOfferModal from './Modals/ClashingAcceptOfferModal';

var $ = require('jquery');

interface BtnState {
    exist: boolean;
    show: boolean;
}

interface GenericBtnProps {
    btnClass: string;
    icon: IconName;
    iconStyle?: IconPrefix;
    tooltip: string;
    onClick: any | null;
    fullSized?: boolean;
    children?: any;
}

class Button extends Component<GenericBtnProps> {
    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip();
    }

    onClick = (e: any) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        if (this.props.onClick !== null) {
            this.props.onClick(e);
        }
    };

    render() {
        let btnClass = 'btn ' + this.props.btnClass;
        let btn;
        let iconStyle: IconPrefix = 'far';

        if (this.props.iconStyle != null) {
            iconStyle = this.props.iconStyle;
        }

        if (!this.props.fullSized) {
            btnClass += ' icon-btn';
            btn = (
                <button
                    className={btnClass}
                    data-toggle='tooltip'
                    title={this.props.tooltip}
                    onClick={this.onClick}
                >
                    <FontAwesomeIcon icon={[iconStyle, this.props.icon]} fixedWidth />
                    {this.props.children}
                </button>
            );
        } else {
            btnClass += ' full-btn';
            btn = (
                <button className={btnClass} onClick={this.onClick}>
                    <FontAwesomeIcon icon={[iconStyle, this.props.icon]} fixedWidth />
                    {this.props.tooltip}
                </button>
            );
        }
        return <span data-toggle='modal'>{btn}</span>;
    }
}

interface ModalBtnProps {
    appState: AppState;
    event: any;
    onSuccess: any;
    eventType?: string;
    fullSized?: boolean;
    describeEvent?: boolean;
    children?: any;
}

interface GenericModalBtnProps {
    appState: AppState;
    event: any;
    onSuccess: any;

    modalName: React.ComponentType<any>;
    btnClass: string;
    icon: IconName;
    iconStyle?: IconPrefix;
    tooltip: string;

    eventType?: string;
    fullSized?: boolean;
    children?: any;

    options?: string[];
}

export class ModalBtn extends Component<GenericModalBtnProps, BtnState> {
    constructor(props: GenericModalBtnProps) {
        super(props);
        this.state = {
            exist: false,
            show: false,
        };
    }

    setModalVisibility = (show: boolean) => {
        if (show) {
            this.setState({ exist: true, show: true });
        } else {
            this.setState({ show: false });
            setTimeout(() => {
                this.setState({ exist: false });
            }, 500);
        }
    };

    render() {
        const ModalName = this.props.modalName;

        return (
            <span>
                <Button
                    onClick={() => this.setModalVisibility(true)}
                    {...this.props}
                >
                    {this.props.children}
                </Button>
                {this.state.exist && (
                    <ModalName
                        show={this.state.show}
                        closeModal={() => this.setModalVisibility(false)}
                        {...this.props}
                    />
                )}
            </span>
        );
    }
}

export class AddBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={NewAvailabilityModal}
                btnClass='btn-primary add-new-btn'
                icon='plus'
                tooltip=''
            />
        );
    }
}

export class EditBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={EditAvailabilityModal}
                btnClass='btn-tertiary'
                icon='pencil-alt'
                tooltip='Edit'
            />
        );
    }
}

export class DeleteBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={DeleteAvailabilityModal}
                btnClass='btn-tertiary'
                icon='trash-alt'
                tooltip='Delete'
            />
        );
    }
}

export class MessageBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={MessageModal}
                btnClass='btn-tertiary'
                icon='comments'
                tooltip={
                    'Message ' + this.props.event[getOtherType(this.props.appState.userType)].name
                }
                eventType={this.props.eventType}
            />
        );
    }
}

export class RetractBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={RetractModal}
                btnClass='btn-danger'
                icon='undo'
                tooltip='Retract'
            />
        );
    }
}

export class RejectBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={RejectModal}
                btnClass='btn-danger'
                icon='times'
                tooltip='Reject'
            />
        );
    }
}

export class AcceptOfferBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={AcceptOfferModal}
                btnClass='btn-primary'
                icon='check'
                tooltip='Accept offer'
            />
        );
    }
}

export class ClashingAcceptOfferBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={ClashingAcceptOfferModal}
                btnClass='btn-primary'
                icon='exclamation'
                iconStyle='fas'
                tooltip='Clashing job offer'
            />
        );
    }
}

export class SendApplicationBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={SendApplicationModal}
                btnClass='btn-primary'
                icon='calendar-plus'
                tooltip='Send job application'
            />
        );
    }
}

export class ClashingSendApplicationBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={SendApplicationModal}
                btnClass='btn-primary'
                icon='exclamation'
                iconStyle='fas'
                tooltip='Clashing job application'
            />
        );
    }
}

export class SendOfferBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={SendOfferModal}
                btnClass='btn-primary'
                icon='calendar-plus'
                tooltip='Send job offer'
            />
        );
    }
}

export class MarkJobCompletedBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={MarkCompleteModal}
                btnClass='btn-primary'
                icon='check'
                tooltip='Mark completed'
            />
        );
    }
}

export class ProposeEditBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={ProposeEditModal}
                btnClass='btn-tertiary'
                icon='pencil-alt'
                tooltip='Propose edit'
            />
        );
    }
}

export class CancelBookingBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        let tooltip = 'Cancel booking';
        if (this.props.describeEvent) {
            tooltip += ' on ' + formatJobTimestamp(this.props.event);
        }
        return (
            <ModalBtn
                {...this.props}
                modalName={CancelBookingModal}
                btnClass='btn-secondary-danger'
                icon='ban'
                tooltip={tooltip}
            />
        );
    }
}

export class RetractEditBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={RetractEditModal}
                btnClass='btn-danger'
                icon='undo'
                tooltip='Retract edit proposal'
            />
        );
    }
}

export class RejectEditBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={RejectEditModal}
                btnClass='btn-danger'
                icon='times'
                tooltip='Reject edit proposal'
            />
        );
    }
}

export class AcceptEditBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={AcceptEditModal}
                btnClass='btn-success'
                icon='check'
                tooltip='Accept edit proposal'
            />
        );
    }
}

export class SendInvoiceBtn extends Component<ModalBtnProps & SendInvoiceProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={SendInvoiceModal}
                btnClass='btn-primary'
                icon='money-bill-wave'
                tooltip='Send invoice'
            />
        );
    }
}

export class ViewInvoiceBtn extends Component<ModalBtnProps, BtnState> {
    openInvoiceBlob = (resp: APIResp) => {
        this.props.appState.updateUICounts(resp.data);
        let file = resp.data;
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file, 'invoice.pdf');
        } else {
            const fileURL = URL.createObjectURL(file);
            // window.open(fileURL);
            let tempLink = document.createElement('a');
            tempLink.href = fileURL;
            tempLink.setAttribute('download', 'invoice.pdf');
            tempLink.click();
        }
        this.props.onSuccess();
    };

    fetchInvoice = () => {
        fetchAPI(
            {
                url: '/invoices/id/' + this.props.event.id + '/',
                responseType: 'blob',
            },
            this.openInvoiceBlob,
            this.props.appState,
        );
    };

    render() {
        return (
            <Button
                {...this.props}
                btnClass='btn-light'
                icon='file-invoice'
                tooltip='Download invoice'
                onClick={this.fetchInvoice}
            />
        );
    }
}

export class MarkPaidBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={MarkPaidModal}
                btnClass='btn-success'
                icon='money-bill-wave'
                tooltip='Mark invoice as paid'
            />
        );
    }
}

export class RetractInvoiceBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={RetractInvoiceModal}
                btnClass='btn-secondary-danger'
                icon='undo'
                tooltip='Retract invoice'
            />
        );
    }
}

export class RejectInvoiceBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={RejectInvoiceModal}
                btnClass='btn-danger'
                icon='times'
                tooltip='Reject invoice'
            />
        );
    }
}

export const EditProfileFieldBtn = (props: ModalBtnProps & { options?: string[] }) => {
    return (
        <ModalBtn
            {...props}
            modalName={EditProfileFieldModal}
            btnClass='btn-warning'
            icon='pencil-alt'
            tooltip='Edit'
        />
    );
};

export class ChangePasswordBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={ChangePasswordModal}
                btnClass='btn-warning'
                icon='pencil-alt'
                tooltip=''
            />
        );
    }
}

export class LogOutEverywhereBtn extends Component<ModalBtnProps, BtnState> {
    render() {
        return (
            <ModalBtn
                {...this.props}
                modalName={LogOutEverywhereModal}
                btnClass='btn-light'
                icon='sign-out'
                tooltip=''
            />
        );
    }
}

export class ViewMessagesBtn extends Component<{ hash: string }> {
    render() {
        return (
            <NavLink className='btn full-btn btn-tertiary' to={'/messages/' + this.props.hash}>
                <FontAwesomeIcon icon={['far', 'comments']} fixedWidth />
                View Messages
            </NavLink>
        );
    }
}

export const SavePrivacyOptionsBtn = (props: {
    onClick(e: any): void;
    children?: any;
}) => {
    return (
        <Button btnClass='btn-primary' icon='save' tooltip='' onClick={props.onClick}>
            {props.children}
        </Button>
    );
};

export const EditCookiePreferencesBtn = (props: ModalBtnProps) => {
    return (
        <ModalBtn
            {...props}
            modalName={CookieModal}
            btnClass='btn-light'
            icon='pencil-alt'
            tooltip=''
        >
            &nbsp;&nbsp;Edit your cookie preferences
        </ModalBtn>
    );
};

export const LoadingBtn = (props: { className: string; onClick(): void; isLoading: boolean; children: any }) => {
    return <button className={props.className} onClick={props.onClick}
                   disabled={props.isLoading}>{props.children}</button>;
};