import React, { Component } from "react";
import { APIResp, fetchAPI } from "../utils";
import "react-datepicker/dist/react-datepicker.css";
import Loading from "../components/Loading";
import JobListItems from "../components/ListItems/JobListItems";

import "../styles/Bookings.scss";
import BookingsNav from "../components/Navs/BookingsNav";
import { withRouter, RouteComponentProps } from "react-router";
import { CompProps } from "../Main";

type BookingsProps = RouteComponentProps<{}> & CompProps;

interface BookingsState {
    bookings: any;
    fetchingData: boolean;
}

class Bookings extends Component<BookingsProps, BookingsState> {
    unlisten: any;
    constructor(props: BookingsProps) {
        super(props);
        this.props.setTitle("Confirmed Bookings");

        this.state = {
            bookings: [],
            fetchingData: true
        };

        if (this.props.location.pathname === "/bookings") {
            this.props.history.push("/bookings/upcoming");
        }
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, active) => {
            this.fetchBookings(location.pathname);
        });
        this.fetchBookings(window.location.pathname);
    }

    componentWillUnmount() {
        this.unlisten();
    }

    updateState = (resp: APIResp) => {
        this.setState({ fetchingData: false });
        if (resp.data.ok) {
            this.setState({ bookings: resp.data.data });
            this.props.appState.setEvents(resp.data.data);
            this.props.appState.updateUICounts(resp.data);
        }
    };

    fetchBookings = (path: string) => {
        if (!path.startsWith("/booking")) {
            // because this function is still being called when navigating away
            return;
        }
        if (path === "/bookings") {
            path = "/bookings/upcoming";
        }
        fetchAPI({ url: path }, this.updateState, this.props.appState);
    };

    onUpdate = (message: string) => {
        if (message != null && message.length > 0) {
            this.props.setSuccessMessage(message);
        }
        this.fetchBookings(this.props.location.pathname);
    };

    render() {
        let bookingType = "";
        if (this.props.location.pathname.startsWith("/booking")) {
            bookingType = this.props.location.pathname.substring(10);
        }
        return (
            <div>
                <BookingsNav />

                <Loading loading={this.state.fetchingData}>
                    <JobListItems
                        appState={this.props.appState}
                        data={this.state.bookings}
                        onUpdate={this.onUpdate}
                        eventType={"booking"}
                        bookingType={bookingType}
                    />
                </Loading>
            </div>
        );
    }
}

export default withRouter(Bookings);
