import React, { useEffect, useState } from 'react';
import { AppState } from '../../App';

export const DisplayPracticeBorough = (props: { appState: AppState; borough: number }) => {
    const [userBorough, setUserBorough] = useState<string>('');

    useEffect(() => {
        setUserBorough(props.appState.boroughs[props.borough]);
        // eslint-disable-next-line
    }, []);

    return (
        <tr className="data-field">
            <td className="field-name">Borough</td>
            <td className="field-value">
                {userBorough}
                <div className="job-status-text">
                    Please{' '}
                    <a href="https://versatilegp.atlassian.net/servicedesk/customer/portal/3/group/4/create/27">
                        contact us
                    </a>{' '}
                    if you are assigned to the wrong borough.
                </div>
            </td>
            <td>&nbsp;</td>
        </tr>
    );
};
