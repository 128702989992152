import React from 'react';
import '../styles/Footer.scss';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <div id="footer">
            <NavLink to="/acceptable-use">Acceptable Use Policy</NavLink> |{' '}
            <NavLink to="/terms-of-use">Terms of Use</NavLink> |{' '}
            <NavLink to="/cookies">Cookie Policy</NavLink> |{' '}
            <NavLink to="/privacy">Privacy Policy</NavLink>
        </div>
    );
};

export default Footer;
