import React, { Component } from "react";
import { CompProps } from "../Main";
import { fetchAPI, APIResp } from "../utils";
import Loading from "./Loading";
import InvoicesNav from "./Navs/InvoicesNav";

import "../styles/Bookings.scss";
import { RouteComponentProps, withRouter } from "react-router";
import InvoiceListItems from "./ListItems/InvoiceListItems";

type InvoicesProps = RouteComponentProps<{}> & CompProps;

interface State {
    invoices: any;
    fetchingData: boolean;
}

class Invoices extends Component<InvoicesProps, State> {
    unlisten: any;
    constructor(props: InvoicesProps) {
        super(props);
        this.state = { invoices: [], fetchingData: true };
        this.props.setTitle("My Invoices");

        if (this.props.location.pathname === "/invoices") {
            this.props.history.push("/invoices/pending-payment");
        }
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((loc, active) => {
            this.fetchData(loc.pathname, true);
        });
        this.fetchData(window.location.pathname, true);
    }

    componentWillUnmount() {
        this.unlisten();
    }

    updateState = (resp: APIResp) => {
        if (resp.data.ok) {
            this.setState({
                invoices: resp.data.data,
                fetchingData: false
            });
            this.props.appState.updateUICounts(resp.data);
        }
    };

    fetchData = (path: string, showLoading: boolean = false) => {
        if (!path.startsWith("/invoices")) {
            return;
        }
        if (showLoading) {
            this.setState({ invoices: [], fetchingData: true });
        }
        if (path === "/invoices") {
            path = "/invoices/pending-payment";
        }

        fetchAPI({ url: path }, this.updateState, this.props.appState);
    };

    onUpdate = (message: string) => {
        if (message != null && message.length > 0) {
            this.props.setSuccessMessage(message);
        }
        this.fetchData(this.props.location.pathname);
    };

    render() {
        return (
            <div>
                <InvoicesNav />
                <Loading loading={this.state.fetchingData}>
                    <InvoiceListItems
                        appState={this.props.appState}
                        data={this.state.invoices}
                        onUpdate={this.onUpdate}
                    />
                </Loading>
            </div>
        );
    }
}

export default withRouter(Invoices);
