import React, { Component } from "react";
import { formatTime } from "../../utils";

interface TimeSelectProps {
    startPeriod: number;
    endPeriod: number;
    value: number;
    onChange: any;
}

class TimeSelect extends Component<TimeSelectProps> {
    constructor(props: TimeSelectProps) {
        super(props);
        this.change = this.change.bind(this);
    }

    change(e: any) {
        let value = parseInt(e.target.value);
        this.props.onChange(value);
    }

    render() {
        let options = [];
        for (
            let i = this.props.startPeriod;
            i <= this.props.endPeriod;
            i += 15
        ) {
            options.push(
                <option value={i} key={i}>
                    {formatTime(i) + " hrs"}
                </option>
            );
        }

        return (
            <div>
                <select
                    className="form-control form-control-time"
                    value={this.props.value}
                    onChange={this.change}
                >
                    {options}
                </select>
            </div>
        );
    }
}

interface StartEndTimeSelectProps {
    onChange: any;
    startTime: number;
    endTime: number;
}

class StartEndTimeSelect extends Component<StartEndTimeSelectProps> {
    constructor(props: StartEndTimeSelectProps) {
        super(props);

        this.updateStart = this.updateStart.bind(this);
        this.updateEnd = this.updateEnd.bind(this);
    }

    updateStart(e: number) {
        if (e >= this.props.endTime) {
            // Need to update the endtime as well, if start is after (or same time as) end
            this.props.onChange(e, e + 30);
        } else {
            this.props.onChange(e, this.props.endTime);
        }
    }

    updateEnd(e: number) {
        this.props.onChange(this.props.startTime, e);
    }

    render() {
        return (
            <table>
                <tbody>
                    <tr>
                        <td>
                            <TimeSelect
                                startPeriod={0}
                                endPeriod={60 * 23.5}
                                value={this.props.startTime}
                                onChange={this.updateStart}
                            />
                        </td>
                        <td>&nbsp;&mdash;&nbsp;</td>
                        <td>
                            <TimeSelect
                                startPeriod={this.props.startTime + 30}
                                endPeriod={60 * 24}
                                value={this.props.endTime}
                                onChange={this.updateEnd}
                            />
                        </td>
                        <td style={{ paddingLeft: "12px" }}>
                            ({(this.props.endTime - this.props.startTime) / 60}{" "}
                            hours)
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default StartEndTimeSelect;
