import React from 'react';
import LegalDoc from './LegalDoc';

const PrivacyPolicy = () => {
    return (
        <LegalDoc title='Privacy Policy'>
            <p>
                In this Privacy Notice “we”, “our”, or “us” refers to Versatile
                GP Limited. We will describe how we collect, store and use your
                personal information as well as the rights you have in relation
                to your personal data. You should read this Privacy Policy
                carefully and raise any questions you may have with Gillian Kyei
                at{' '}
                <a href='mailto:gilliankyei@versatilegp.com'>
                    gilliankyei@versatilegp.com
                </a>
                .
            </p>
            <p>
                Versatile GP Limited operates a website called the “Versatile GP
                Website” which connects GP locums (described as “Healthcare
                Professionals” in this Privacy Notice) with GP Practices. This
                is the “Versatile GP Service”.
            </p>
            <p>
                We may collect information about you because you have signed up
                as a Healthcare Professional or GP Practice on the Versatile GP
                Website or because you have contacted us via email (at the email
                address above) in relation to the Versatile GP Service. Both
                Healthcare Professionals and GP Practices may be “users” of the
                Versatile GP Website and the Versatile GP Service.
            </p>
            <h2>What information we collect</h2>
            <p>
                In the course of communicating with you and/or when you register
                as a “user” of the Versatile GP Website, we may ask for
                information that is classed as personal data which is
                information that identifies you and relates to you as an
                individual. Versatile GP Limited will collect, store and use
                your personal data in order for it to provide the Versatile GP
                Service depending on your relationship with us. We have set out
                the main categories of personal data which we process through
                the Versatile GP Website:
            </p>
            <ul>
                <li>
                    Personal contact information (including your name, mobile
                    telephone number, email address and user name for the
                    Versatile GP Website)
                </li>
                <li>Password (this is chosen by you)</li>
                <li>
                    Name, telephone number and address of GP Practice (where the
                    GP Practice is the user) and contact details (name and email
                    address) of an employee of the GP Practice who can be
                    contacted
                </li>
                <li>
                    GMC number (where the Healthcare Professional is the user)
                </li>
                <li>
                    Information relating to a user’s ID, professional background, experience, qualifications and
                    accreditations, and eligibility for work (“Compliance Documentation”) (where the Healthcare
                    Professional is the user). This information is verified by GP Practices.
                </li>
                <li>
                    Details of Job Records between a Healthcare Professional and
                    a GP Practice including start and end time of sessions,
                    hourly rates and NHS pension contributions and what the
                    Healthcare Professional has agreed to do (clinical on site,
                    home visit or administrative work).
                </li>
            </ul>
            <p>
                In addition to such information we use systems which provide
                general statistics relating to who is using the Versatile GP
                Website and how the Versatile GP Website is being utilised,
                which may be tracked to you as an individual. See our Cookies policy
                which sets out information about the use of cookies on the Versatile GP Website.
            </p>
            <p>
                There may be other personal data that Versatile GP Limited
                collects, stores and uses in the context of the relationship. We
                will update this Privacy Notice from time to time to reflect any
                notable changes in the categories of data which are processed.
            </p>
            <h2>Ways in which we might use your information</h2>
            <p>
                We are committed to respecting your privacy and personal data
                and will only use the information you have given us in
                connection with the provision of the Versatile GP Service and
                analysing how the Versatile GP Website is being used. We may use
                your personal data on the basis that it is necessary for the
                performance of a contract with you or you have provided your
                consent to such use. Where consent is relied upon as the lawful
                basis for processing your data, evidence of opt-in consent shall
                be sought on the Versatile GP Website (and access will not be
                granted without opt-in consent) or via email in which case the
                consent for communication will be implied by the user contacting
                Gillian Kyei by email. Where Gillian Kyei makes the first email
                contact to the user, that user will have opted in to a mailing
                list by providing their email address.
            </p>
            <p>
                If you fail to provide certain information when requested, we may not be able to provide you with
                access to the Versatile GP Website or provide the Versatile GP Service to you. We also provide
                an upload facility on the Versatile GP Website to enable users to upload Compliance
                Documentation for verification by GP Practices. The use of this facility is optional.
            </p>
            <h2>Retention of Information</h2>
            <p>
                Your information will be retained by us while you are a user of
                the Versatile GP Service. To ensure that personal data is kept
                for no longer than necessary, Versatile GP Limited has an
                archiving/removal policy for each area in which personal data is
                processed and this will be reviewed annually. Versatile GP
                Limited will only keep an archive of personal data external to
                the Versatile GP Website when it is sent to Gillian Kyei by
                email. Deletion of a user’s profile (that contains their
                personal data) will be carried out at the request of the user by
                email to Gillian Kyei or at the discretion of Versatile GP
                Limited in the event of unacceptable use of the Versatile GP
                Website, death of the user, dissolution of the general practice
                related to a profile or inactivity on a profile for five (5)
                years. Users will be contacted by email to confirm their profile
                has been deleted.
            </p>
            <h2>Confidentiality</h2>
            <p>
                We do not sell any personal data to third parties and we do not
                share any personal information about you to others except with
                certain third parties directly in connection with the provision
                of the Versatile GP Service, including the Versatile GP Website
                developer who is based in Singapore. As the UK has not to date made adequacy regulations in respect of
                Singapore, we have put in place a written
                transfer agreement with the Versatile GP Website developer to
                protect the personal data, incorporating the standard contractual
                clauses approved by the European Commission prior to the UK's exit from the EU.
            </p>
            <p>
                The Terms of Use for the Versatile GP Website set out that users
                are prohibited from sharing the personal data of other users of
                the Versatile GP Website without the explicit consent of the
                data subject of the personal data.
            </p>

            <h2>Protection of your information</h2>
            <p>
                We protect your information through restricting access to the
                Versatile GP Website strictly to those who are users of the
                Versatile GP Service i.e. who have been verified as a legitimate
                Healthcare Professional or GP Practice.
            </p>

            <h2>Your rights</h2>
            <p>
                Versatile GP Limited will seek to process your personal data in
                accordance with our obligations, our rights and your rights. You
                will not be subject to decisions based solely on automated data
                processing without your prior consent. In certain circumstances,
                you have the right to seek the erasure or correction of your
                personal data, to object to particular aspects of how your data
                is processed and otherwise to seek the restriction of the
                processing of your personal data. You also have the right to
                request the transfer of your personal data to another party in a
                commonly used format. If we are processing your personal data on
                the basis that you have provided your consent, then you have the
                right to withdraw this consent at any time. You also have the
                right to access your personal data processed by Versatile GP
                Limited. This is called a data subject access request. You may
                be asked to confirm your identity if you make a data subject
                access request.
            </p>
            <p>
                If you wish to exercise any of the above rights including but
                not limited to withdrawing your consent to the processing, you
                can email Gillian Kyei at{' '}
                <a href='mailto:gilliankyei@versatilegp.com'>
                    gilliankyei@versatilegp.com
                </a>
                .
            </p>
            <p>
                Where a user of the Versatile GP Website revokes their consent
                to the processing of their personal data or communications,
                their profile (containing their personal data) will be deleted
                due to the essential nature of processing personal data as a
                function of the Versatile GP Website.
            </p>
            <p>
                You also have the right to raise any concerns about how your
                personal data is being processed with the Information
                Commissioner’s Office (ICO) by going to the ICO’s website:{' '}
                <a href='https://ico.org.uk/concerns'>
                    https://ico.org.uk/concerns
                </a>{' '}
                or by contracting the ICO on 0303 123 1113 or{' '}
                <a href='mailto:casework@ico.org'>casework@ico.org</a>.
            </p>
        </LegalDoc>
    );
};

export default PrivacyPolicy;
