import React, { Component } from "react";
import { getOtherType, formatJobTimestamp } from "../../utils";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";

class CancelBookingModal extends Component<GenericModalProps> {
    render() {
        let otherType = getOtherType(this.props.appState.userType);
        let name = this.props.event[otherType].name;

        let requestParams = {
            url: "/bookings/delete/" + this.props.event.id,
            method: "post"
        };

        return (
            <GenericModal
                {...this.props}
                title="Cancel booking"
                yesBtn="Yes"
                noBtn="No"
                requestParams={requestParams}
                successMessage="Booking cancelled."
            >
                Are you sure you want to cancel your booking on{" "}
                {formatJobTimestamp(this.props.event)}? This action is
                irreversible, and {name} will be notified.
            </GenericModal>
        );
    }
}

export default CancelBookingModal;
