import React, { Component } from "react";
import { checkPasswordStrength } from "../../utils";
import { GenericModalProps } from "./Modals";
import GenericModal from "./GenericModal";
import { WarningAlert, StatusAlert } from "../../Alert";

interface FieldState {
    oldPw: string;
    pw1: string;
    pw2: string;
    passwordMessage: string;
    passwordStrength: string;
    passwordRepeat: string;
}

class ChangePasswordModal extends Component<GenericModalProps, FieldState> {
    constructor(props: GenericModalProps) {
        super(props);
        this.state = {
            oldPw: "",
            pw1: "",
            pw2: "",
            passwordMessage: "",
            passwordStrength: "",
            passwordRepeat: ""
        };
    }

    checkPasswordStrength = () => {
        this.setState(checkPasswordStrength(this.state.pw1));
        this.checkPasswordRepeat();
    };

    checkPasswordRepeat = () => {
        if (this.state.pw1 !== this.state.pw2) {
            this.setState({ passwordRepeat: "Passwords do not match." });
        } else {
            this.setState({ passwordRepeat: "" });
        }
    };

    updateOldPw = (e: any) => {
        let value = e.target.value;
        this.setState({ oldPw: value });
    };

    updatePw1 = (e: any) => {
        let value = e.target.value;
        this.setState({ pw1: value }, this.checkPasswordStrength);
    };

    updatePw2 = (e: any) => {
        let value = e.target.value;
        this.setState({ pw2: value }, this.checkPasswordRepeat);
    };

    checkOkSubmit = () => {
        this.checkPasswordStrength();
        this.checkPasswordRepeat();

        if (
            this.state.passwordMessage.length > 0 ||
            this.state.passwordRepeat.length > 0 ||
            this.state.oldPw.length === 0 ||
            this.state.pw1.length === 0
        ) {
            // Don't let them submit if there's still a warning message
            return false;
        }
        return true;
    };

    render() {
        let requestParams = {
            url: "/auth/change-password",
            method: "post",
            payload: {
                old: this.state.oldPw,
                new: this.state.pw1
            }
        };

        return (
            <GenericModal
                {...this.props}
                title={"Change Password"}
                yesBtn="Update"
                noBtn="Cancel"
                requestParams={requestParams}
                checkOkSubmit={this.checkOkSubmit}
                successMessage="Password successfully changed. You may want to log out of all devices as well."
            >
                <div className="form-group">
                    <label>Old Password:</label>
                    <input
                        type="password"
                        className="form-control"
                        value={this.state.oldPw}
                        onChange={this.updateOldPw}
                    />
                </div>

                <StatusAlert content={this.state.passwordStrength} />
                <WarningAlert content={this.state.passwordMessage} />
                <div className="form-group">
                    <label>New Password:</label>
                    <input
                        type="password"
                        className="form-control"
                        value={this.state.pw1}
                        onChange={this.updatePw1}
                    />
                </div>
                <WarningAlert content={this.state.passwordRepeat} />
                <div className="form-group">
                    <label>Repeat New Password:</label>
                    <input
                        type="password"
                        className="form-control"
                        value={this.state.pw2}
                        onChange={this.updatePw2}
                    />
                </div>
            </GenericModal>
        );
    }
}

export default ChangePasswordModal;
