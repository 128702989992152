import React from 'react';
import { formatMoney } from '../../utils';
import InfoButton from '../InfoButton';

import '../../styles/RateCalculations.scss';

const SingleRateCalculation: React.FunctionComponent<{
    label: string;
    amount: number;
    infoText?: string;
}> = (props) => {
    return (
        <tr className="rate-calculations-row">
            <td className="rate-calculations-label">
                {props.label}:{props.infoText != null && <InfoButton text={props.infoText} />}
            </td>
            <td className="rate-calculations-amount">{formatMoney(props.amount)}</td>
        </tr>
    );
};

const RateCalculations: React.FunctionComponent<{
    hours: number;
    rate: number;
    userType: string;
    fixedRate: boolean;
}> = (props) => {
    let withoutPension = props.rate;
    if (!props.fixedRate) {
        withoutPension *= props.hours;
    }

    let pension = withoutPension * 0.12942;
    let withPension = withoutPension + pension;

    let pensionTooltip: string;
    if (props.userType === 'doctor') {
        pensionTooltip = 'The pension will only be added to the rate if you opt to claim this.';
    } else {
        pensionTooltip =
            'The pension will automatically be added to the rate if the locum opts to claim this.';
    }

    return (
        <table>
            <tbody>
                <SingleRateCalculation
                    label="Rate for session without NHS employer pension contribution"
                    amount={withoutPension}
                />
                <SingleRateCalculation
                    label="Employer NHS pension contribution (if claimed)"
                    amount={pension}
                    infoText="Calculated as 14.38% of 90% of the above rate."
                />
                <SingleRateCalculation
                    label="Rate for session including NHS employer pension"
                    amount={withPension}
                    infoText={pensionTooltip}
                />
            </tbody>
        </table>
    );
};

export default RateCalculations;
