import React, { Component } from "react";
import { CompProps } from "../Main";
import { APIResp, fetchAPI } from "../utils";

import "../styles/Messages.scss";
import Message, { message } from "./Message";
import Loading from "./Loading";
import EmptyState from "./EmptyState";
import { NavLink } from "react-router-dom";

interface MessagesState {
    fetchingData: boolean;
    messages: message[];
}

class Messages extends Component<CompProps, MessagesState> {
    constructor(props: CompProps) {
        super(props);
        this.state = {
            fetchingData: true,
            messages: []
        };
        this.props.setTitle("Messages");
        this.fetchData();
    }

    updateState = (resp: APIResp) => {
        if (resp.data.ok) {
            this.setState({ fetchingData: false, messages: resp.data.data });
            this.props.appState.updateUICounts(resp.data);
        }
    };

    fetchData = () => {
        fetchAPI({ url: "/messages" }, this.updateState, this.props.appState);
    };

    render() {
        if (this.state.fetchingData || this.state.messages.length > 0) {
            let messages = this.state.messages.map((message, i) => (
                <Message message={message} key={message.userHash} />
            ));
            return (
                <Loading loading={this.state.fetchingData}>
                    <table id="messages-list">
                        <tbody>{messages}</tbody>
                    </table>
                </Loading>
            );
        } else {
            return <NoMessages userType={this.props.appState.userType} />;
        }
    }
}

const NoMessages: React.FC<{
    userType: "doctor" | "practice" | "";
}> = props => {
    let listProfiles = <span />;
    if (props.userType === "doctor") {
        listProfiles = (
            <NavLink to="/gp-profiles">list of practice profiles</NavLink>
        );
    } else {
        listProfiles = (
            <NavLink to="/locum-profiles">list of locum profiles</NavLink>
        );
    }
    return (
        <EmptyState
            icon="comment-slash"
            heading="You currently have no messages"
        >
            No worries! You can send a message by selecting a profile from the{" "}
            {listProfiles} or by clicking on the "Message" button on any of your
            bookings.
        </EmptyState>
    );
};

export default Messages;
