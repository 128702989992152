import React, { useCallback, useEffect, useState } from 'react';
import { APIResp, fetchAPI } from '../../utils';
import { CompProps } from '../../Main';

import '../../styles/NotificationOptions.scss';
import Loading from '../Loading';

const NotificationRow = (props: {
    text: string;
    notifKey: string;
    notifs: notifs;
    onChange: any;
    hideImmediateOption?: boolean;
}) => {
    return (
        <tr className='notif-row'>
            <td>{props.text}</td>
            <td>
                <select
                    onChange={(e: any) =>
                        props.onChange(
                            props.notifKey + '_email',
                            e.target.value,
                        )
                    }
                    value={props.notifs[props.notifKey + '_email']}
                >
                    <option value='0'>Do not email me</option>
                    {!props.hideImmediateOption && <option value='1'>Email me immediately</option>}
                    <option value='2'>Email me at the end of the day</option>
                </select>
            </td>
        </tr>
    );
};

const DoctorNotifications: React.FC<{
    notifs: notifs;
    onChange: any;
}> = props => {
    return (
        <tbody>
        <NotificationRow
            text='When a new practice signs up'
            notifKey='signup'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When I get a new message'
            notifKey='msg'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When I receive a job offer'
            notifKey='offer'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a practice declines my application'
            notifKey='dec_app'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a practice cancels a booking'
            notifKey='cancel_bk'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a practice proposes to edit a booking'
            notifKey='edit_bk'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a practice accepts my proposed booking edit'
            notifKey='acc_edit_bk'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a practice rejects my proposed booking edit'
            notifKey='rej_edit_bk'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a practice rejects an invoice'
            notifKey='rej_inv'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a practice marks an invoice as paid'
            notifKey='paid'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text={'When a compliance document is expiring in 4 weeks'}
            notifKey='doc_expire'
            notifs={props.notifs}
            onChange={props.onChange}
            hideImmediateOption
        />
        </tbody>
    );
};

const PracticeNotifications: React.FC<{
    notifs: notifs;
    onChange: any;
}> = props => {
    return (
        <tbody>
        <NotificationRow
            text='When a new locum signs up'
            notifKey='signup'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When I get a new message'
            notifKey='msg'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When I receive a job application'
            notifKey='app'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a locum declines a job offer'
            notifKey='dec_offer'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a locum accepts a job offer'
            notifKey='acc_offer'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a locum cancels a booking'
            notifKey='cancel_bk'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a locum proposes to edit a booking'
            notifKey='edit_bk'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a locum accepts my proposed booking edit'
            notifKey='acc_edit_bk'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When a locum rejects my proposed booking edit'
            notifKey='rej_edit_bk'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        <NotificationRow
            text='When I receive an invoice'
            notifKey='invoice'
            notifs={props.notifs}
            onChange={props.onChange}
        />
        </tbody>
    );
};

interface notifs {
    [key: string]: number;
}

const NotificationOptions = (props: CompProps) => {
    const [notifsSettings, setNotifSettings] = useState<notifs>({});
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = useCallback(() => {
        fetchAPI(
            { url: '/profile/notifications/' },
            updateState,
            props.appState,
        );
    }, [props.appState]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const updateState = (resp: APIResp) => {
        setIsLoading(false);
        if (resp.data.ok) {
            setNotifSettings(resp.data.data);
        }
    };

    const onChange = (key: string, value: string) => {
        const newSettings = { ...notifsSettings };
        newSettings[key] = parseInt(value);
        setNotifSettings(newSettings)

        fetchAPI(
            {
                url: '/profile/notifications/',
                method: 'post',
                payload: { key: key, frequency: parseInt(value) },
            },
            (resp: APIResp) => {
                if (resp.data.ok) {
                    props.setSuccessMessage(
                        'Your notification preferences have been updated.',
                    );
                } else {
                    props.setErrorMessage(resp.data.error);
                }
            },
            props.appState,
        );
    };

    return (
        <Loading loading={isLoading}>
            <div>
                Select your notification options below:
                <table className='notif-options'>
                    <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Email</th>
                        {/* <th>SMS</th> */}
                    </tr>
                    </thead>
                    {props.appState.userType === 'doctor' && (
                        <DoctorNotifications
                            notifs={notifsSettings}
                            onChange={onChange}
                        />
                    )}
                    {props.appState.userType === 'practice' && (
                        <PracticeNotifications
                            notifs={notifsSettings}
                            onChange={onChange}
                        />
                    )}
                </table>
            </div>
        </Loading>
    );
};

export default NotificationOptions;
