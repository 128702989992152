import { IconName } from '@fortawesome/pro-solid-svg-icons';
import { UserType } from './App';

export interface page {
	name: string;
	icon: IconName;
	link: string;
}

function flatten(input: page[][]): page[] {
	let pages = [];
	for (let i = 0; i < input.length; i++) {
		let section = input[i];
		for (let j = 0; j < section.length; j++) {
			pages.push(section[j]);
		}
	}
	return pages;
}

const dashboard: page = {
	name: 'Dashboard',
	icon: 'chart-network',
	link: 'dashboard'
};
const calendar: page = {
	name: 'My Calendar',
	icon: 'calendar-day',
	link: 'calendar'
};

const feedback: page = {
	name: 'Feedback',
	icon: 'comment-alt-edit',
	link: 'feedback'
};

const doctorPagesSectioned: page[][] = [
	[ feedback, dashboard, calendar ],
	[
		{
			name: 'My Availabilities',
			icon: 'plus-square',
			link: 'my-availabilities'
		},
		{ name: 'Pending Applications', icon: 'clock', link: 'applications' },
		{ name: 'Incoming Offers', icon: 'inbox-in', link: 'offers' },
		{
			name: 'Confirmed Bookings',
			icon: 'check-square',
			link: 'bookings/upcoming'
		},
		{ name: 'My Invoices', icon: 'file-invoice', link: 'invoices' }
	],
	[
		{ name: 'Practice Profiles', icon: 'users', link: 'gp-profiles' },
		{
			name: 'Browse Practice Sessions',
			icon: 'exclamation-square',
			link: 'available-sessions'
		},
		{
			name: 'Noticeboard',
			icon: 'thumbtack',
			link: 'noticeboard'
		}
	]
];

const practicePagesSectioned: page[][] = [
	[ feedback, dashboard, calendar ],
	[
		{
			name: 'My Available Sessions',
			icon: 'plus-square',
			link: 'my-available-sessions'
		},
		{
			name: 'Incoming Applications',
			icon: 'inbox-in',
			link: 'applications'
		},
		{ name: 'Pending Offers', icon: 'clock', link: 'offers' },
		{
			name: 'Confirmed Bookings',
			icon: 'check-square',
			link: 'bookings/upcoming'
		},
		{ name: 'My Invoices', icon: 'file-invoice', link: 'invoices' }
	],
	[
		{ name: 'Locum Profiles', icon: 'users', link: 'locum-profiles' },
		{
			name: 'Browse Available Locums',
			icon: 'exclamation-square',
			link: 'listed-availabilities'
		},
		{
			name: 'Noticeboard',
			icon: 'thumbtack',
			link: 'noticeboard'
		}
	]
];

const doctorPagesFlat: page[] = flatten(doctorPagesSectioned);
const practicePagesFlat: page[] = flatten(practicePagesSectioned);

export function getPagesSectioned(userType: UserType): page[][] {
	if (userType === UserType.Doctor) {
		return doctorPagesSectioned;
	} else if (userType === UserType.Practice) {
		return practicePagesSectioned;
	}
	return [];
}

export function getPagesFlat(userType: UserType): page[] {
	if (userType === UserType.Doctor) {
		return doctorPagesFlat;
	} else if (userType === UserType.Practice) {
		return practicePagesFlat;
	}
	return [];
}
