import Loading from '../Loading';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { fetchAPI } from '../../utils';
import { AppState } from '../../App';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './ComplianceDocs.scss';
import { format } from 'date-fns';
import { LoadingBtn } from '../Buttons';

interface DocType {
    name: string;
    id: string;
    multiple?: boolean;
}

export const docs: DocType[] = [
    { name: 'Curriculum Vitae', id: 'cv' },
    { name: 'Indemnity certificate', id: 'indem' },
    { name: 'NHS Smart Card', id: 'nhs' },
    { name: 'DBS certificate', id: 'dbs' },
    { name: 'Immunisation record', id: 'immunisation', multiple: true },
    { name: 'Basic Life Support certificate', id: 'bls' },
    { name: 'Safeguarding Children Level 3 Certificate', id: 'child-safe' },
    { name: 'Safeguarding Adults Certificate', id: 'adult-safe' },
    { name: 'Information Governance certificate', id: 'info-gov' },
    { name: 'Equality & Diversity certificate', id: 'dei' },
    { name: 'Infection Control certificate', id: 'infection' },
    { name: 'Fire Training certificate', id: 'fire' },
    { name: 'Reference', id: 'ref', multiple: true },
    { name: 'Passport', id: 'passport' },
    { name: 'Driving licence', id: 'driving' },
    { name: 'Evidence of GMC registration', id: 'gmc-registration' },
    { name: 'Evidence of inclusion on a medical performers list', id: 'medical-performers' },
    { name: 'Evidence of most recent appraisal', id: 'recent-appraisal' },
    { name: 'Safeguarding against radicalisation – Prevent – Level 1', id: 'safeguarding-radicalisation' },
    { name: 'Other', id: 'other', multiple: true },
];

export interface DoctorFile {
    id: number;
    category: string;
    name: string;
    description: string;
    expiry?: string;
    uploaded: string;
    link: string;
}

export const ComplianceDocs = (props: { appState: AppState }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState<DoctorFile[]>([]);

    const fetchFiles = useCallback(() => {
        fetchAPI({ url: '/profile/files' }, (r) => {
            if (r.data.ok) {
                // handle r.data.data
                setFiles(r.data.data);
            }
            setIsLoading(false);
        }, props.appState);
    }, [props.appState]);

    useEffect(() => {
        fetchFiles();
    }, [props.appState, fetchFiles]);

    return <Loading loading={isLoading}>
        <div id='compliance-docs'>
            {docs.map(d => <Doc appState={props.appState} fetchFiles={fetchFiles} docInfo={d} key={d.id}
                                files={files.filter(x => x.category === 'compliance' && x.name === d.id)} />)}
        </div>
    </Loading>;
};

const Doc = (props: { appState: AppState; fetchFiles(): void; docInfo: DocType, files: DoctorFile[] }) => {
    const { name, multiple } = props.docInfo;
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [file, setFile] = useState<File | null>(null);


    const selectUpload = () => {
        inputRef.current?.click();
    };

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) return;
        setFile(e.target.files[0]);
    };

    const closeModal = (refresh: boolean) => {
        setFile(null);
        inputRef.current!.value = '';
        if (refresh) props.fetchFiles();
    };

    return <div className='file-row'>
        <div><b>{name}</b></div>
        {props.files.map(file => <FileRow key={file.id} appState={props.appState} fetchFiles={props.fetchFiles} file={file} />)}
        <div className='row'>
            <div className='flex-1'></div>
            <div>
                {(multiple || props.files.length === 0) &&
                    <button className='btn btn-primary' onClick={selectUpload}>Upload</button>}
                <input type='file' hidden ref={inputRef}
                       onChange={handleFileChange} />
                {file && <ShowUploadModal appState={props.appState} docInfo={props.docInfo} file={file}
                                          closeModal={closeModal} />}
            </div>
        </div>
    </div>;
};

const FileRow = (props: { appState: AppState, file: DoctorFile, fetchFiles(): void }) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);


    const download = () => {
        setIsDownloading(true);
        fetchAPI({ url: '/profile/files/' + props.file.id, responseType: 'blob' }, (resp) => {
            // console.log(resp.data)
            setIsDownloading(false);
            const blob = resp.data;
            const splits = props.file.link.split('.');
            const ext = splits[splits.length - 1];
            const docName = docs.find(x => x.id === props.file.name)?.name;
            const fileName = docName + (props.file.description ? (' - ' + props.file.description) : '') + '.' + ext;

            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                const fileURL = URL.createObjectURL(blob);
                let tempLink = document.createElement('a');
                tempLink.href = fileURL;
                tempLink.setAttribute('download', fileName);
                tempLink.click();
            }
        }, props.appState);
    };

    const remove = () => {
        if (!window.confirm('Are you sure you want to delete this file?')) return;

        setIsRemoving(true);
        fetchAPI({ url: '/profile/files/delete/' + props.file.id, method: 'post' }, () => {
            setIsRemoving(false);
            props.fetchFiles();
        }, props.appState);
    };

    const uploaded = 'Uploaded on ' + format(new Date(props.file.uploaded), 'd MMM yyyy');
    const expiry = props.file.expiry ? ', expires on ' + format(new Date(props.file.expiry), 'd MMM yyyy') : '';
    return <div className='row'>
        <div className='flex-1'>{props.file.description} ({uploaded}{expiry})</div>
        <div>
            <LoadingBtn className='btn btn-outline-secondary' onClick={download}
                        isLoading={isDownloading}>View</LoadingBtn>
            <LoadingBtn className='btn btn-outline-secondary' onClick={remove}
                        isLoading={isRemoving}>Remove</LoadingBtn>
        </div>
    </div>;

};

const ShowUploadModal = (props: { appState: AppState; docInfo: DocType, file: File, closeModal(refresh: boolean): void }) => {
    const { name, id, multiple } = props.docInfo;
    const [description, setDescription] = useState('');
    const [expiry, setExpiry] = useState<Date | null>(null);
    const [isUploading, setIsUploading] = useState(false);

    const submitForm = () => {
        const formData = new FormData();
        formData.append('file', props.file);
        formData.append('category', 'compliance');
        formData.append('name', id);
        if (multiple && description) formData.append('description', description);
        if (expiry) formData.append('expiry', expiry.toISOString().split('T')[0]);

        setIsUploading(true);

        fetchAPI({
            url: '/profile/files/',
            method: 'post',
            payload: formData,
        }, (resp) => {
            setIsUploading(false);

            if (resp.data.ok) {
                props.closeModal(true);
                return;
            }

            alert(resp.data.error + ', please try again.');
        }, props.appState);
    };

    return <Modal show={true} onHide={() => props.closeModal(false)} className='compliance-doc-upload-model'>
        <Modal.Header>Uploading {name}</Modal.Header>
        <Modal.Body>
            <div>File: <b>{props.file.name}</b></div>
            {multiple && <div className='form-group'>
                <label>Description:</label>
                <input
                    className='form-control'
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                />
            </div>}
            <div className='form-group'>
                <label>Expiry (optional) {expiry !== null &&
                    <span className='link' onClick={() => setExpiry(null)}>(clear)</span>}:</label>
                <DatePicker
                    dateFormat='E, MMM dd, yyyy'
                    minDate={new Date()}
                    selected={expiry}
                    onChange={setExpiry}
                    className='form-control'
                />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className='btn btn-secondary' onClick={() => props.closeModal(false)}>Cancel</button>
            <LoadingBtn className='btn btn-primary' onClick={submitForm} isLoading={isUploading}>Upload</LoadingBtn>
        </Modal.Footer>
    </Modal>
        ;
};